import React from "react";

const Feedback = props => {
  return (
    <div className={"feeback " + (props.success ? "success" : "fail")}>
      <p>{props.message}</p>
    </div>
  );
};
export default Feedback;
