import React from "react";

const VehicleListItem = (props) => {
  const vehicles = props.vehicles;

  return (
    <table className="content-list border-all-main">
      <thead>
        <tr className="list-item-head flex-row flex-start w-100 bg-color-main">
          <td>Model</td>
          <td>Plate Number</td>
          <td>Status</td>
          <td>Current Mileage (Km)</td>
          <td>Next Service Mileage (Km)</td>
          <td>Distance to Next Service (Km)</td>
          <td>Location</td>
        </tr>
      </thead>
      <tbody>
        {vehicles.length > 0 ? (
          vehicles.map((vehicle) => {
            return (
              <tr
                key={vehicle.id}
                className="list-item flex-row flex-start"
                onClick={() => props.onClick(vehicle)}
              >
                <td className="text-left">{vehicle.model}</td>
                <td className="text-left">{vehicle.pnumber}</td>
                <td className="text-left">
                  {vehicle.in_use ? "In use" : "Available"}
                </td>

                <td className="text-left">{vehicle.current_mileage}</td>
                <td className="text-left">{vehicle.next_service_mileage}</td>
                <td className="text-left">
                  {vehicle.next_service_mileage - vehicle.current_mileage}
                </td>
                <td className="text-left">{vehicle.location}</td>
              </tr>
            );
          })
        ) : (
          <tr>
            <td>No vehicles</td>
          </tr>
        )}
      </tbody>
    </table>
  );
};
export default VehicleListItem;
