import React from "react";
import { getCityNames } from "postcodes-tz";
import Fetcher from "../utils/fetcher.js";
const config = require("../utils/config.json");

class VehicleForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isVisible: true,
      projects: this.props.projects,
      fcodes: this.props.fundcodes,
      filteredCodes: []
    };

    this.handleCancel = this.handleCancel.bind(this);
    this.handleProjectChange = this.handleProjectChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleNewForm = this.handleNewForm.bind(this);
    this.getCodes = this.getCodes.bind(this);
  }
  handleProjectChange(e) {
    e.preventDefault();
    let project = e.target.value;
    this.getCodes(project);
  }
  getCodes(project) {
    if (this.state.fcodes.length > 0) {
      let codes = this.state.fcodes.filter(fc => {
        return fc.project === project;
      });

      this.setState({ filteredCodes: codes });
    }
  }

  handleNewForm() {
    this.setState({ isVisible: true });
  }

  handleSubmit(e) {
    e.preventDefault();

    let inputs = Array.from(e.target.elements);
    let vehicle = {
      reg_no: inputs[0].value,
      chassis: inputs[3].value,
      make: inputs[1].value,
      model: inputs[2].value,
      year: parseInt(inputs[5].value),
      mileage: parseInt(inputs[4].value),
      current_mileage: inputs[4].value,
      next_service_mileage: parseInt(inputs[4].value) + 5000,
      donor: inputs[6].value,
      project: inputs[7].value,
      fundcode: inputs[8].value,
      cost: inputs[9].value,
      date: new Date(inputs[10].value).getTime() / 1000,
      location: inputs[11].value
    };
    this.save(vehicle);
  }
  save(vehicle) {
    fetch(config.base_url + "/vehicle", {
      method: "post",
      headers: { "Content-type": "application/json" },
      body: JSON.stringify(vehicle)
    })
      .then(res => res.json())
      .then(result => {
        if (result.error) {
          this.props.onFeedback(false, result.error);
          this.handleCancel();
        } else {
          Fetcher.fetchVehicles()
            .then(vehicles => {
              this.props.onRefresh(vehicles);
            })
            .catch(err => {
              this.props.onFeedback(false, "Could not refresh vehicle list");
            })
            .finally(() => {
              this.props.onFeedback(true, result.response);
              this.handleCancel();
            });
        }
      })
      .catch(error => {
        this.props.onFeedback(false, error.error);
      });
  }

  handleCancel() {
    this.props.cancelButtonClick(false);
  }
  render() {
    return (
      <div
        className={
          "flex-row flex-center center-self " +
          (!this.state.isVisible ? "hidden" : "")
        }
      >
        <form
          name="new-vehicle-form"
          id="new-vehicle-form"
          className="flex-center border-all-main"
          onSubmit={this.handleSubmit}
        >
          <h2 className="w-100 bg-color-main">New Vehicle</h2>

          <div className="w-100 flex-row flex-start">
            <div className="form-group">
              <label className="text-color-accent" htmlFor="reg_no">
                Registration Number
              </label>
              <input
                className="form-control "
                type="text"
                name="reg_no"
                id="reg_no"
                placeholder="Enter plate number"
              />
            </div>
            <div className="form-group">
              <label htmlFor="mileage" className="form-label text-color-accent">
                Make/Brand
              </label>
              <input
                className="form-control "
                type="text"
                name="make"
                id="make"
                placeholder="Toyota"
              />
            </div>
            <div className="form-group">
              <label htmlFor="model" className="form-label text-color-accent">
                Model
              </label>
              <input
                className="form-control "
                type="text"
                name="model"
                id="model"
                placeholder="Vehicle Model"
              />
            </div>
          </div>
          <div className="flex-row flex-start w-100">
            <div className="form-group">
              <label htmlFor="chassis" className="form-label text-color-accent">
                Chassis Number
              </label>
              <input
                className="form-control "
                type="text"
                name="chassis"
                id="chassis"
                placeholder="Enter chassis number"
              />
            </div>

            <div className="form-group">
              <label htmlFor="mileage" className="form-label text-color-accent">
                Mileage (Km)
              </label>
              <input
                className="form-control "
                type="number"
                name="mileage"
                id="mileage"
                placeholder="Enter mileage in Km"
              />
            </div>
            <div className="form-group">
              <label htmlFor="year" className="form-label text-color-accent">
                Year of Manufacture
              </label>
              <select
                defaultValue={new Date().getFullYear()}
                className="form-control "
                name="year"
                id="year"
              >
                {[...Array(30).keys()].map(y => {
                  return (
                    <option key={y}>{new Date().getFullYear() - y}</option>
                  );
                })}
              </select>
            </div>
          </div>
          <div className="flex-row flex-start w-100">
            <div className="form-group" id="donnor-group">
              <label htmlFor="donor" className="form-label text-color-accent">
                Donnor
              </label>
              <input
                className="form-control "
                type="text"
                name="donnor"
                id="donnor"
                placeholder="World Bank"
              />
            </div>
            <div className="form-group">
              <label htmlFor="project" className="form-label text-color-accent">
                Project
              </label>
              <select
                onChange={this.handleProjectChange}
                className="form-control"
                name="project"
                id="project"
              >
                {this.state.projects.map(project => {
                  return <option key={project.id}>{project.code}</option>;
                })}
              </select>
            </div>
            <div className="form-group">
              <label
                htmlFor="fundcode"
                className="form-label text-color-accent"
              >
                Fund Code
              </label>
              <select className="form-control " name="fundcode" id="fundcode">
                {this.state.filteredCodes.map(fcode => {
                  return <option key={fcode.id}>{fcode.code}</option>;
                })}
              </select>
            </div>
          </div>
          <div className="flex-row flex-start w-100">
            <div className="form-group" id="donnor-group">
              <label htmlFor="cost" className="form-label text-color-accent">
                Acquisition Cost
              </label>
              <input
                className="form-control "
                type="number"
                name="cost"
                id="cost"
                placeholder="Enter cost of acquisition"
              />
            </div>
            <div className="form-group">
              <label htmlFor="date" className="form-label text-color-accent">
                Acquisition Date
              </label>
              <input
                type="date"
                placeholder={"Enter Date"}
                className="form-control "
                name="date"
                id="date"
              />
            </div>
            <div className="form-group">
              <label htmlFor="station" className="form-label text-color-accent">
                Select Location
              </label>
              <select className="form-control " name="station" id="station">
                {getCityNames().map(c => {
                  return <option key={c}>{c}</option>;
                })}
              </select>
            </div>
          </div>

          <div className="form-group">
            <span className="flex-row flex-space">
              <input
                className="btn form-control bg-color-accent"
                type="submit"
                id="submitVehicle"
                value="Save"
              />
              <span
                id="cancel-vehicle"
                className="btn text-color-accent"
                onClick={this.handleCancel}
              >
                CANCEL
              </span>
            </span>
          </div>
        </form>
      </div>
    );
  }
}
export default VehicleForm;
