import React from "react";

class Checkbox extends React.Component {
  constructor(props) {
    super(props);
    this.state = { isChecked: false, text: "" };
    this.handleChange = this.handleChange.bind(this);
  }

  componentDidMount() {
    this.setState({ isChecked: true });
  }
  handleChange(e) {
    // e.preventDefault();
    let text = this.state.text;
    let state = false;
    let cb = e.target;
    if (cb.checked) {
      if (cb.id === "10") {
        this.props.extra(true);
      }
      text = this.props.text;
      state = true;
    } else {
      if (cb.id === "10") {
        this.props.extra(false);
      }
      text = this.props.text;
      state = false;
    }
    this.props.onChange(state, text);
  }
  render() {
    return (
      <div className="form-group">
        <div className="flex-row flex-start">
          <input
            id={this.props.id}
            className="form-control"
            type="checkbox"
            onClick={e => this.handleChange(e)}
          />
          {this.props.text}
        </div>
      </div>
    );
  }
}
export default Checkbox;
