class Commons {
  dateFormatWithTime = (seconds) => {
    let months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    let dateObject = new Date(seconds * 1000);
    let hrs =
      dateObject.getHours() < 10
        ? "0" + dateObject.getHours()
        : dateObject.getHours();
    let mins =
      dateObject.getMinutes() < 10
        ? "0" + dateObject.getMinutes()
        : dateObject.getMinutes();
    let secs =
      dateObject.getSeconds() < 10
        ? "0" + dateObject.getSeconds()
        : dateObject.getSeconds();
    let date =
      dateObject.getDate() +
      " " +
      months[dateObject.getMonth()] +
      " " +
      dateObject.getFullYear() +
      " " +
      hrs +
      ":" +
      mins +
      ":" +
      secs;

    return date;
  };
  validatePhoneNumber =(phone) => {
    phone = phone ? phone.replaceAll(" ","").replaceAll("-",""):null;
    let validPhoneNumber = false;
    if (phone && phone.length > 9) {
      switch (phone.length) {
        case 10:
          validPhoneNumber = `255${phone.substr(1)}`; //trim leading 0 and prepend tz country prefix;
          break;
        case 12:
          validPhoneNumber = phone;
          break;
        case 13:
          if (phone.substr(0, 1) == '+') validPhoneNumber = phone.substr(1); //remove + sign and reassure country code
          break;
        default:
          validPhoneNumber = false;
          break;
      }
    }
    return validPhoneNumber;
  };
  dateFormat = (seconds) => {
    let months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    let dateObject = new Date(seconds * 1000);
    let date =
      dateObject.getDate() +
      " " +
      months[dateObject.getMonth()] +
      " " +
      dateObject.getFullYear();

    return date;
  };

  findTotalDistance = (collection) => {
    let total = 0;
    collection.forEach((c) => {
      total += c.end_mileage - c.start_mileage;
    });
    return total;
  };

  findTotal = (collection) => {
    let total = 0;
    collection.forEach((item) => {
      total += item.a_price * item.a_amount;
      // return total;
    });
    return total;
  };
  formatNumber = (num) => {
    return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
  };
  //check if string is only white spaces
  isWhiteSpaces = (string) => {
    if (typeof string !== "string") return false;
    let stringArray = Array.from(string);
    let result = stringArray.splice(
      0,
      stringArray.length,
      ...new Set(stringArray)
    );
    if (result.length == 1) return result[0] === " ";
    return false;
  };

  getPositionValue = (positions, id) => {
    let position = positions.filter((p) => {
      return p.id === id;
    });
    return position.length > 0
      ? position[0]
      : { id: id, value: "Unknown", positions: "" };
  };

  //validate email address
  validateEmail = (email) => {
    if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
      return true;
    }

    return false;
  };

  //search username
  userExists = (username, users) => {
    let keyword = username.toLowerCase();
    users = users.filter((u) => {
      return u.username.toLowerCase().search(keyword) !== -1;
    });
    return users.length > 0;
  };
  //get activities
  getActivities(fcode, fundcodes) {
    if (fundcodes.length > 0) {
      let fc = fundcodes.filter((c) => {
        return c.code.toLowerCase() == fcode.toLowerCase();
      })[0];
      let activities = fc.activities.split(",");
      return activities;
    }
  }

  //get fundcodes from project code
  getCodes = (project, fundcodes) => {
    if (fundcodes.length > 0) {
      let codes = fundcodes.filter((fc) => {
        return fc.project === project;
      });
      return codes;
    } else return [];
  };

  getRequestTypeText = (type) => {
    switch (type) {
      case 0:
        return "Vehicle Request";
      case 1:
        return "Fuel Request";
      case 2:
        return "Normal Car Service";
      case 3:
        return "Full Car Service";
      case 4:
        return "Repair Service";
    }
  };
  groupByArray = (xs, key) => {
    let result = xs.reduce((rv, x) => {
      let v = key instanceof Function ? key(x) : x[key];
      let el = rv.find((r) => r && r.key === v);
      if (el) {
        el.values.push(x);
      } else {
        rv.push({ key: v, values: [x] });
      }
      return rv;
    }, []);

    return result;
  };

  calculateTime = (end, start) => {
    let date = new Date();
    let endtime = this.dateFormat(date.getTime() / 1000) + " " + end;
    let starttime = this.dateFormat(date.getTime() / 1000) + " " + start;
    let endDate = new Date(endtime);
    let startDate = new Date(starttime);
    let endMilliseconds = endDate.getTime();
    let startMilliseconds = startDate.getTime();

    let diff = 0;

    if (startMilliseconds > endMilliseconds) {
      diff = (24 * 3600 * 1000 - startMilliseconds + endMilliseconds) / 1000;
    } else {
      diff = (endMilliseconds - startMilliseconds) / 1000;
    }

    let hours = parseInt(diff / 3600);
    let mins = parseInt((diff % 3600) / 60);
    let secs = parseInt((diff % 3600) % 60);
    let time = { h: hours, m: mins, s: secs };

    return time;
  };
  isEmailAvailable = (users, email) => {
    let user = users.filter((u) => {
      return u.email === email;
    });
    if (user && user.length > 0) return false;
    else return true;
  };
}

export default new Commons();
