import React from "react";
import CustomDialog from "./custom_dialog";
import Commons from "../utils/commons.js";
import Fetcher from "../utils/fetcher";

const config = require("../utils/config.json");

class FundcodeEditForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      fundcode: null,
      fundcodeChanged: false,
      complete: false,
      response: "",
    };

    this.handleCancel = this.handleCancel.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleFundcodeChange = this.handleFundcodeChange.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleDateFocus = this.handleDateFocus.bind(this);
  }
  handleDateFocus = (e) => {
    e.target.type = "date";
  };
  // componentDidMount() {
  //   console.log("fundcode_edit: ", this.props.fundcodes);
  //   this.setState({ fundcode: this.props.fundcodes[0] });
  // }

  handleChange = (event) => {
    event.preventDefault();
    let fundcode = this.state.fundcode;
    let value = event.target.value;

    switch (event.target.id) {
      case "code":
        fundcode.code = value;
        break;
      case "project":
        fundcode.project = value;
        break;
      case "activities":
        fundcode.activities = value;
        break;

      case "expiration":
        let sdate = new Date(value);
        fundcode.expiration = sdate.getTime() / 1000;
        event.target.type = "text";
        break;
    }

    this.setState({ fundcode: fundcode, fundcodeChanged: true });
  };
  handleCancel = (e) => {
    // e.preventDefault();
    this.props.onCancel();
  };
  handleFundcodeChange = (e) => {
    let id = e.target.options[e.target.options.selectedIndex].value;
    let fundcode = this.props.fundcodes.filter((p) => {
      return p.id == id;
    });
    this.setState(
      { fundcode: fundcode.length > 0 ? fundcode[0] : null },
      () => {
        console.log("test: ", this.state.fundcode);
      }
    );
  };
  handleSubmit = (e) => {
    e.preventDefault();
    fetch(config.base_url + "/fundcode/" + this.state.fundcode.id, {
      method: "put",
      headers: { "Content-type": "application/json" },
      body: JSON.stringify(this.state.fundcode),
    })
      .then((res) => res.json())
      .then((result) => {
        console.log("result; ", result);
        let error = result.error;
        if (error) this.setState({ complete: true, response: error });
        else {
          this.setState({ complete: true, response: result.response }, () => {
            Fetcher.fetchFundcodes("all")
              .then((fundcodes) => {
                this.props.onRefresh(fundcodes);
              })
              .catch((e) => {})
              .finally(() => {
                this.props.onFeedback(true, result.response);
              });
          });
        }
      })
      .catch((err) => {
        this.setState({ complete: true, response: err });
      });
  };

  render() {
    return (
      <>
        <div className="overlay w-100 flex-row flex-center flex-middle">
          <form
            className="bg-color-white-dark w-50"
            onSubmit={this.handleSubmit}
          >
            <h2>Edit Fundcode Details</h2>
            <div className="form-group">
              <label htmlFor="fundcode_id">Select Fund Code</label>

              <select
                onChange={this.handleFundcodeChange}
                className="form-control"
                id="fundcode_id"
                name="fundcode_id"
              >
                <option>--select code--</option>
                {this.props.fundcodes.map((p) => {
                  return (
                    <option key={p.id} value={p.id}>
                      {p.code}
                    </option>
                  );
                })}
              </select>
            </div>
            {this.state.fundcode !== null ? (
              <>
                <div className="form-group">
                  <label htmlFor="code">Fundcode</label>

                  <input
                    onChange={this.handleChange}
                    className="form-control"
                    type="text"
                    id="code"
                    name="code"
                    value={this.state.fundcode.code}
                    placeholder="Enter fund code"
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="project">Select Project</label>
                  <select
                    onChange={this.handleChange}
                    className="form-control"
                    id="project"
                    name="project"
                    value={this.state.fundcode.project}
                  >
                    <option>--select project--</option>
                    {this.props.projects.map((p) => {
                      return (
                        <option key={p.id} value={p.code}>
                          {p.code}
                        </option>
                      );
                    })}
                  </select>
                </div>
                <div className="flex-row flex-start flex-middle w-100">
                  <div className="form-group">
                    <label htmlFor="expiration">Expiration Date</label>

                    <input
                      onChange={this.handleChange}
                      onFocus={this.handleDateFocus}
                      className="form-control"
                      type="text"
                      id="expiration"
                      name="expiration"
                      value={Commons.dateFormat(this.state.fundcode.expiration)}
                      placeholder="Enter a date"
                    />
                  </div>
                </div>
                <div className="form-group">
                  <span className="flex-row flex-space">
                    {this.state.fundcodeChanged ? (
                      <input
                        className="btn form-control bg-color-accent"
                        type="submit"
                        id="btnUpdate"
                        value="Update"
                      />
                    ) : null}
                    <span
                      id="cancel-user"
                      className="btn text-color-accent"
                      onClick={this.handleCancel}
                    >
                      CANCEL
                    </span>
                  </span>
                </div>
              </>
            ) : (
              <div className="form-group">
                <span
                  id="cancel-user"
                  className="btn text-color-accent"
                  onClick={this.handleCancel}
                >
                  CANCEL
                </span>
              </div>
            )}
          </form>
        </div>{" "}
        {this.state.complete ? (
          <CustomDialog
            title={"Update Fundcode Details"}
            onCancel={this.handleCancel}
            msg={this.state.response}
          />
        ) : null}
      </>
    );
  }
}
export default FundcodeEditForm;
