import React from "react";
import { getCityNames } from "postcodes-tz";
const config = require("../utils/config.json");
class DepartmentForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = { visible: false };

    this.handleCancel = this.handleCancel.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }
  handleCancel = e => {
    // e.preventDefault();
    this.props.onCancel();
  };

  handleSubmit = e => {
    e.preventDefault();
    let inputs = Array.from(e.target.elements);
    let department = {
      code: inputs[0].value,
      description: inputs[1].value,
      region: inputs[2].value,
      admin: inputs[3].value
    };

    fetch(config.base_url + "/department", {
      method: "post",
      headers: { "Content-type": "application/json" },
      body: JSON.stringify(department)
    })
      .then(result => result.json())
      .then(res => {
        if (res.error) this.props.onFeedback(false, res.error);
        else this.props.onFeedback(true, res.response);
        this.props.onCancel();
      })
      .catch(err => {
        this.props.onCancel();
      });
  };

  render() {
    return (
      <div className="overlay w-100 flex-row flex-center flex-middle">
        <form className="bg-color-white-dark w-50" onSubmit={this.handleSubmit}>
          <h2>Add New Program Office</h2>
          <div className="form-group">
            <label htmlFor="code">Department ID {this.props.admins.length}</label>

            <input
              className="form-control"
              type="text"
              id="code"
              name="code"
              placeholder="Enter department ID in the form TZ000X"
            />
          </div>

          <div className="form-group">
            <label htmlFor="description">Description</label>

            <input
              className="form-control"
              type="text"
              id="description"
              name="description"
              placeholder="Enter a name"
            />
          </div>
          <div className="form-group">
            <label htmlFor="region">Select Region</label>

            <select className="form-control" id="region" name="region">
              {getCityNames("asc").map(city => {
                return <option key={city}>{city}</option>;
              })}
            </select>
          </div>
          <div className="form-group">
            <label htmlFor="admin">Choose Admin</label>

            <select className="form-control" id="admin" name="admin">
              {this.props.admins.length > 0 ? (
                this.props.admins.map(admin => {
                  return (
                    <option key={admin.id} value={admin.id}>
                      {admin.fname + " " + admin.lname}
                    </option>
                  );
                })
              ) : (
                <option>No admins</option>
              )}
            </select>
          </div>
          <div className="form-group">
            <span className="flex-row flex-space">
              <input
                className="btn form-control bg-color-accent"
                type="submit"
                id="submitStation"
                value="Save"
              />
              <span
                id="cancel-user"
                className="btn text-color-accent"
                onClick={this.handleCancel}
              >
                CANCEL
              </span>
            </span>
          </div>
        </form>
      </div>
    );
  }
}
export default DepartmentForm;
