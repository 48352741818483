import React from "react";
import Commons from "../utils/commons";
import {
  getCoreRowModel,
  ColumnDef,
  flexRender,
  useReactTable} from '@tanstack/react-table'
import PageControl from "./page_control";
const RequestListItem = (props) => {
  const handleClick = (e,r) => {
    props.onClick(r);
  };
  const getPreviousPage = ()=>{
    props.onPageChange(props.currentPage - 1);
  }
  const getNextPage = ()=>{
    props.onPageChange(props.currentPage +1);
  }

  const columns = 
  [
    {
      accessorFn: row => row.date_created,
      id: 'date_created',
      cell: info => Commons.dateFormat(info.getValue()),
      header: () => <span>Request Date</span>,
      footer: props => props.column.id,
    },
    {
      accessorFn: row => row.start_time,
      id: 'start_time',
      cell: info => Commons.dateFormat(info.getValue()),
      header: () => <span>Required Date</span>,
      footer: props => props.column.id,
    },
    {
      accessorFn: row => row.typeText,
      id: 'typeText',
      cell: info => info.getValue(),
      header: () => <span>Request Type</span>,
      footer: props => props.column.id,
    },
    {
      accessorFn: row => row.nameOfRequestor,
      id: 'nameOfRequestor',
      cell: info => info.getValue(),
      header: () => <span>Requestor</span>,
      footer: props => props.column.id,
    },
    {
      accessorFn: row => row.statusText,
      id: 'statusText',
      cell: info => info.getValue(),
      header: () => <span>Status</span>,
      footer: props => props.column.id,
    },
    {
      accessorFn: row => row.project,
      id: 'project',
      cell: info => info.getValue().includes(",") ? info.getValue().split(",")[0]:info.getValue(),
      header: () => <span>Project</span>,
      footer: props => props.column.id,
    },
    {
      accessorFn: row => row.fundcode,
      id: 'fundcode',
      cell: info => info.getValue().includes(",") ? info.getValue().split(",")[0]:info.getValue(),
      header: () => <span>Fund code</span>,
      footer: props => props.column.id,
    },
  ]

const data = props.requests ? props.requests : [];

const table = useReactTable ({
  data,
  columns,
  enableColumnResizing: true,
  columnResizeMode: 'onChange',
  getCoreRowModel: getCoreRowModel(),
  debugTable: true,
  debugHeaders: true,
  debugColumns: true,
})

return (
<div className="content-list flex-column justify-content-between">
  <table className="w-full text-left border-all-main p-2">
<thead>
{table.getHeaderGroups().map(headerGroup => (
  <tr key={headerGroup.id} className="bg-color-main px-2 py-1">
    {headerGroup.headers.map(header => {
      return (
        <th
          key={header.id}
          colSpan={header.colSpan}
          style={{ position: 'relative', width: header.getSize() }}
        >
          {header.isPlaceholder
            ? null
            : flexRender(
                header.column.columnDef.header,
                header.getContext()
              )}
          {header.column.getCanResize() && (
            <div
              onMouseDown={header.getResizeHandler()}
              onTouchStart={header.getResizeHandler()}
              className={`resizer ${
                header.column.getIsResizing() ? 'isResizing' : ''
              }`}
            ></div>
          )}
        </th>
      )
    })}
  </tr>
))}
</thead>
<tbody>
{table.getRowModel().rows.map(row => {
  return (
    <tr key={row.id} className="list-item" onClick={(e)=>handleClick(e,row.original)}>
      {row.getVisibleCells().map(cell => {
        return (
          <td key={cell.id} style={{ width: cell.column.getSize() }} className="border-bottom px-1">
            {flexRender(
              cell.column.columnDef.cell,
              cell.getContext()
            )}
          </td>
        )
      })}
    </tr>
  )
})}
</tbody>
</table>
<div className="w-full border-top mt-3 flex-row flex-start bg-main-color align-items-center ">
<PageControl onNext={getNextPage} onPrevious={getPreviousPage} currentPage={props.currentPage} totalPages={props.totalPages} onPageSizeChange={(pageSize)=>{props.onPageSizeChange(pageSize)}} hasNext={props.hasNext} hasPrevious={props.hasPrevious}/>
  
</div>

</div>)
  
};

export default RequestListItem;
