import React from "react";

const SearchBar = props => {
  return (
    <div className="flex-row flex-end border-all-main">
      <input
        onChange={props.onChange}
        className="form-control"
        type="search"
        placeholder="search"
        id="search"
        name="search"
      />

      <i id="searchButton" className="material-icons text-color-main-dark">
        search
      </i>
    </div>
  );
};
export default SearchBar;
