import React from "react";
import Fetcher from "../utils/fetcher";
import Commons from "../utils/commons";

const config = require("../utils/config.json");

class ProjectForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      coordinators: [],
      name: "",
      nameError: true,
      code: "",
      codeError: true,
      startDate: 0,
      sdateError: true,
      endDate: 0,
      edateError: true,
      donor: "",
      coordinator: 0,
      coordinatorError: true,
      projects: props.projects,
    };
    this.handleCancel = this.handleCancel.bind(this);
    this.getCoordinators = this.getCoordinators.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleFeedback = this.handleFeedback.bind(this);
    this.handleFocus = this.handleFocus.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleBlur = this.handleBlur.bind(this);
  }
  handleFocus = (e) => {
    let id = e.target.id;
    e.target.type = "date";
  };
  handleBlur = (e) => {
    e.target.type = "text";
  };
  handleChange = (e) => {
    let field = e.target;
    let value = field.value;
    switch (field.id) {
      case "name":
        if (value.length > 1) {
          this.setState({ name: value, nameError: false });
          field.classList.remove("fail");
        } else field.classList.add("fail");
        break;
      case "project":
        if (value.length > 1) {
          this.setState({ code: value, codeError: false });
          field.classList.remove("fail");
        } else field.classList.add("fail");
        break;
      case "start-date":
        if (value.length > 0) {
          this.setState({ startDate: value, sdateError: false });
          field.classList.remove("fail");
        } else field.classList.add("fail");
        break;
      case "finish-date":
        if (value.length > 0) {
          this.setState({ endDate: value, edateError: false });
          field.classList.remove("fail");
        } else field.classList.add("fail");
        break;
      case "donor":
        this.setState({ donor: value });
        break;
      case "department":
        value = field.options[field.options.selectedIndex].value;
        let coords = this.props.coordinators.filter((c) => {
          return c.department == value;
        });
        this.setState({ coordinators: coords });
        break;
      case "coordinator":
        if (value.length > 0) {
          this.setState({ coordinator: value, coordinatorError: false });
          field.classList.remove("fail");
        } else field.classList.add("fail");
        break;
    }
  };
  handleCancel = (e) => {
    // e.preventDefault();
    this.props.onCancel();
  };
  handleFeedback(status, msg) {
    this.props.onFeedback(status, msg);
  }
  getCoordinators() {
    Fetcher.fetchUsers()
      .then((users) => {
        let coordinators = users.filter((u) => {
          return u.position === 3 || u.position === 4;
        });
        this.setState({ coordinators: coordinators });
      })
      .catch((err) => {
        this.handleFeedback(false, "Could not load data.");
      });
  }
  handleSubmit(event) {
    console.log("handleSubmit(): ", event);
    event.preventDefault();
    let inputs = Array.from(event.target.elements);
    let project = {
      name: inputs[0].value,
      code: inputs[1].value,
      start: new Date(inputs[2].value).getTime() / 1000,
      end: new Date(inputs[3].value).getTime() / 1000,
      donor: inputs[4].value,
      department: parseInt(
        inputs[5].options[inputs[5].options.selectedIndex].value
      ),
      coordinator: parseInt(
        inputs[6].options[inputs[6].options.selectedIndex].value
      ),
    };
    console.log("handleSubmit(): ", project);
    fetch(config.base_url + "/project", {
      method: "post",
      headers: { "Content-type": "application/json" },
      body: JSON.stringify(project),
    })
      .then((result) => result.json())
      .then((response) => {
        Fetcher.fetchProjects()
          .then((projects) => {
            this.props.onRefresh(projects);
            if (response.error) this.handleFeedback(false, response.error);
            else this.handleFeedback(true, response.result);
          })
          .catch((e) => {});
      })
      .catch((error) => {
        this.handleFeedback(false, error.error);
      })
      .finally(() => {
        this.handleCancel();
      });
  }

  componentDidMount() {
    this.getCoordinators();
  }
  render() {
    return (
      <div className="overlay w-100 flex-row flex-center flex-middle">
        <form
          className="bg-color-white-dark w-50"
          onSubmit={(event) => this.handleSubmit(event)}
        >
          <h2>Add New Project</h2>
          <div className="w-100 flex-row flex-start">
            <div className="form-group">
              <label htmlFor="name">Project Name</label>

              <input
                onChange={this.handleChange}
                className="form-control"
                type="text"
                id="name"
                name="name"
                placeholder="Enter a name"
                defaultValue={this.state.name}
              />
            </div>
            <div className="form-group">
              <label htmlFor="project">Project Code/ID</label>
              <input
                onChange={this.handleChange}
                type="text"
                id="project"
                name="project"
                className="form-control"
                placeholder="Enter project code"
                defaultValue={this.state.code}
              />
            </div>
          </div>
          <div className="w-100 flex-row flex-start">
            <div className="form-group">
              <label htmlFor="start-date">Start Date</label>

              <input
                onChange={this.handleChange}
                onFocus={this.handleFocus}
                onBlur={this.handleBlur}
                type="date"
                className="form-control"
                id="start-date"
                name="start-date"
                placeholder="enter start date"
                defaultValue={Commons.dateFormat(this.state.startDate)}
              />
            </div>
            <div className="form-group">
              <label htmlFor="finish-date">Finish Date</label>

              <input
                onChange={this.handleChange}
                onFocus={this.handleFocus}
                onBlur={this.handleBlur}
                type="date"
                className="form-control"
                id="finish-date"
                name="finish-date"
                placeholder="enter finish date"
                defaultValue={Commons.dateFormat(this.state.endDate)}
              />
            </div>
          </div>
          <div className="form-group">
            <label htmlFor="donor">Donor</label>

            <input
              onChange={this.handleChange}
              className="form-control"
              type="text"
              id="donor"
              name="donor"
              placeholder="Donor name"
              defaultValue={this.state.donor}
            />
          </div>
          <div className="form-group">
            <label htmlFor="department">Department</label>

            <select
              className="form-control"
              id="department"
              name="department"
              onChange={this.handleChange}
            >
              <option>--select--</option>
              {this.props.departments.map((d) => {
                return (
                  <option key={d.id} value={d.id}>
                    {d.code}
                  </option>
                );
              })}
            </select>
          </div>
          <div className="form-group">
            <label htmlFor="coordinator">Project Coordinator</label>

            <select
              onChange={this.handleChange}
              className="form-control"
              id="coordinator"
              name="coordinator"
              defaultValue={this.state.coordinator}
            >
              <option>--select--</option>
              {this.state.coordinators.length > 0 ? (
                this.state.coordinators.map((c) => {
                  return (
                    <option key={c.id} value={c.id}>
                      {c.fname + " " + c.lname}
                    </option>
                  );
                })
              ) : (
                <option>No Coordinators</option>
              )}
            </select>
          </div>
          <div className="form-group">
            <span className="flex-row flex-space">
              {!this.state.nameError &&
              !this.state.codeError &&
              !this.state.coordinatorError &&
              !this.state.sdateError &&
              !this.state.edateError ? (
                <input
                  className="btn form-control bg-color-accent"
                  type="submit"
                  id="submitProject"
                  value="Save"
                />
              ) : null}
              <span
                id="cancel-user"
                className="btn text-color-accent"
                onClick={this.handleCancel}
              >
                CANCEL
              </span>
            </span>
          </div>
        </form>
      </div>
    );
  }
}
export default ProjectForm;
