import React from "react";
import { getCityNames } from "postcodes-tz";
import Fetcher from "../utils/fetcher";
const config = require("../utils/config.json");

class SupplierForm extends React.Component {
  constructor(props) {
    super(props);
    this.handleCancel = this.handleCancel.bind(this);
    this.save = this.save.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.state = {
      regions: getCityNames("asc"),
      nameError: true,
      serviceError: true,
      regionError: true,
      region: "",
      service: "",
      name: "",
      tin: "",
    };
  }
  handleChange(event) {
    let field = event.target;
    let value = field.value;
    switch (field.id) {
      case "service":
        if (!value.includes("--") || value !== "") {
          this.setState({ service: value, serviceError: false });
          field.classList.remove("fail");
        } else {
          field.classList.add("fail");
          this.setState({ serviceError: false });
        }
        break;
      case "name":
        if (value !== "" && value.length > 2) {
          this.setState({ name: value, nameError: false });
          field.classList.remove("fail");
        } else {
          field.classList.add("fail");
          this.setState({ nameError: false });
        }
        break;
      case "region":
        if (!value.includes("--") || value !== "") {
          this.setState({ region: value, regionError: false });
          field.classList.remove("fail");
        } else {
          field.classList.add("fail");
          this.setState({ regionError: false });
        }
        break;
      case "tin":
        if (value !== "" && value.length > 2) {
          this.setState({ tin: value });
          field.classList.remove("fail");
        }
        break;
    }
  }
  handleSubmit = (event) => {
    event.preventDefault();
    let service = document.getElementById("service");
    let name = document.getElementById("name");
    let tin = document.getElementById("tin");
    let region = document.getElementById("region");
    let supplier = {
      service: service.value,
      name: name.value,
      tin: tin.value,
      region: region.value,
    };
    this.save(supplier);
    this.props.onCancel();
  };
  save = (supplier) => {
    fetch(config.base_url + "/supplier", {
      method: "post",
      headers: { "Content-type": "application/json" },
      body: JSON.stringify(supplier),
    })
      .then((res) => res.json())
      .then((response) => {
        if (response.error) {
          this.props.onFeedback(false, response.error);
        } else {
          Fetcher.fetchSuppliers()
            .then((suppliers) => {
              this.props.onRefresh(suppliers);
            })
            .catch((e) => {
              this.props.onFeedback(true, response.response);
            });
        }
      })
      .catch((err) => {
        this.props.onFeedback(false, "Service unvailable");
      });
  };
  handleCancel = (e) => {
    // e.preventDefault();
    this.props.onCancel();
  };
  componentDidMount() {
    let user = this.props.user;
    if (user.position !== 6) {
      let dept = this.props.departments;
      console.log("dept", dept);
      if (dept.length > 0) {
        let regs = [];
        let k = 0;
        dept.forEach((d) => {
          if (getCityNames().includes(d.region)) {
            regs[k] = d.region;
            k++;
          }
        });
        this.setState({ regions: regs });
      }
    }
  }
  render() {
    return (
      <div className="overlay w-100 flex-row flex-center flex-middle">
        <form className="bg-color-white-dark w-50" onSubmit={this.handleSubmit}>
          <h2>Add New Supplier</h2>
          <div className="form-group">
            <label htmlFor="project">Select Product/Service</label>
            <select
              id="service"
              name="service"
              className="form-control"
              onChange={this.handleChange}
            >
              <option>--select--</option>
              <option>Fuel</option>
              <option>Maintenance</option>
            </select>
          </div>
          <div className="form-group">
            <label htmlFor="supplier-name">Name of Supplier</label>

            <input
              onChange={this.handleChange}
              className="form-control"
              type="text"
              id="name"
              name="name"
              placeholder="Provide supplier name"
            />
          </div>
          <div className="form-group">
            <label htmlFor="tin">Supplier's TIN</label>

            <input
              onChange={this.handleChange}
              className="form-control"
              type="text"
              id="tin"
              name="tin"
              placeholder="Provide supplier TIN"
            />
          </div>
          <div className="form-group">
            <label htmlFor="region">Select Region</label>

            <select
              className="form-control"
              id="region"
              name="region"
              onChange={this.handleChange}
            >
              <option>--select--</option>
              {this.state.regions.map((city) => {
                return <option key={city}>{city}</option>;
              })}
            </select>
          </div>

          <div className="form-group">
            <span className="flex-row flex-space">
              {!this.state.nameError &&
              !this.state.serviceError &&
              !this.state.regionError ? (
                <input
                  className="btn form-control bg-color-accent"
                  type="submit"
                  id="submitSupplier"
                  value="Save"
                />
              ) : null}
              <span
                id="cancel-user"
                className="btn text-color-accent"
                onClick={this.handleCancel}
              >
                CANCEL
              </span>
            </span>
          </div>
        </form>
      </div>
    );
  }
}
export default SupplierForm;
