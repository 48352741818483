import React from "react";
import SearchBar from "./search_bar";
import UserForm from "./user_form";
import UserListItem from "./user_list_item";
import UserEditForm from "./user_edit_form";
import Fetcher from "../utils/fetcher";
import * as XLSX from 'xlsx'
const config = require("../utils/config.json");

class UserContainer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loadComplete: false,
      showForm: false,
      showUpload: false,
      showRestore: false,
      positions: [],
      users: [],
      selectedUser: {},
      formAction: "",
      departments: [],
      filteredUsers: [], hasNext: false, hasPrevious: false, currentPage: 1, totalPages: 1, pageSize: 20
    };
    this.handleButtonClick = this.handleButtonClick.bind(this);
    this.cancelForm = this.cancelForm.bind(this);
    this.handleFeedback = this.handleFeedback.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
    this.refreshUsers = this.refreshUsers.bind(this);
    this.handleItemClick = this.handleItemClick.bind(this);
    this.handleFileInput = this.handleFileInput.bind(this);
    this.updatePhoneNumbers = this.updatePhoneNumbers.bind(this)
    this.handlePageSizeChange = this.handlePageSizeChange.bind(this)
    this.handlePageChange = this.handlePageChange.bind(this);
  }
  handleItemClick = (user) => {
    this.setState({ formAction: "edit", selectedUser: user, showForm: true, showUpload: false, showRestore: false });
  };
  refreshUsers = (users) => {
    this.props.onRefresh(users);
    this.setState({ users: users, filteredUsers: users });
  };
  handleSearch(e) {
    e.persist();
    let keyword = e.target.value.toLocaleLowerCase();
    let fUsers = this.state.users;
    fUsers = fUsers.filter((u) => {
      return (
        u.email.toLowerCase().search(keyword) !== -1 ||
        u.username.toLowerCase().search(keyword) !== -1 ||
        u.fname.toLowerCase().search(keyword) !== -1 ||
        u.lname.toLowerCase().search(keyword) !== -1 ||
        u.position_detail.value.toLowerCase().search(keyword) !== -1 ||
        u.department_detail.code.toLowerCase().search(keyword) !== -1 ||
        u.department_detail.description.toLowerCase().search(keyword) !== -1 ||
        u.location.toLowerCase().search(keyword) !== -1
      );
    });

    this.setState((ps) => {
      return { filteredUsers: fUsers };
    });
  }
  handleFeedback(status, msg) {
    this.props.onFeedback(status, msg);
  }
  cancelForm() {
    this.setState({ showForm: false, formAction: "" });
  }
  handleButtonClick(e) {
    e.preventDefault();
    switch (e.target.id) {
      case "restoreButton":
        this.setState({ filteredUsers: this.state.users.filter(u => u.busy === 3) });
        this.setState({ showForm: false, showRestore: true, showUpload: false });
        break;
      case "uploadPhoneNumbersButton":
        this.setState({ filteredUsers: this.state.users.filter(u => u.busy !== 3) });
        this.setState({ showForm: false, showRestore: false, showUpload: true });
        break;
      case "savePhonesButton":
        this.updatePhoneNumbers();
        break;
      case "newUserButton":
        this.setState({ filteredUsers: this.state.users.filter(u => u.busy !== 3) });
        this.setState({ showForm: true, showRestore: false, showUpload: false });
      default:
    }

  }
  handleFileInput = (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();

    reader.onload = (event) => {
      const workbook = XLSX.read(event.target.result, { type: 'binary' });
      const sheetName = workbook.SheetNames[0];
      const sheet = workbook.Sheets[sheetName];
      const sheetData = XLSX.utils.sheet_to_json(sheet);

      if (sheetData.length > 0) {
        let newUsers = this.state.users.filter(u => sheetData.map(s => s.email.trim()).includes(u.email));
        if (newUsers.length > 0) {
          this.setState({ uploadData: sheetData })
        }
        this.setState({ filteredUsers: newUsers })
      }
    };

    reader.readAsBinaryString(file);
  }
  updatePhoneNumbers = () => {

    fetch(config.base_url + "/import_phone_numbers", {
      method: "post",
      body: JSON.stringify({ data: this.state.uploadData }),
      headers: { "Content-Type": "application/json" }
    }).then(res => res.json())
      .then(result => {
        this.handleFeedback('success', result.result)
        this.setState({ showForm: false, showRestore: false, showUpload: false, filteredUsers: this.state.users.filter(u => u.busy !== 3) });
      }).catch(err => {
        this.handleFeedback('fail', err.result)

      })
  }
  handlePageSizeChange = (pageSize) => {
    this.setState({ pageSize: Number(pageSize), currentPage: 1 }, () => {
      console.log("pageSize: ",this.state.pageSize)
      this.loadUsers(pageSize)
    })
  }
  handlePageChange = (page) => {
    console.log("🚀 ~ UserContainer ~ page:", page)
    this.setState({ currentPage: page }, () => {
      console.log("currentPage: ",this.state.currentPage)
      this.loadUsers(this.state.pageSize);
    })
  }
  componentDidMount() {
    Fetcher.fetchUsers({ page: 1, take: this.state.pageSize }).then(users => {
      this.setState({
        departments: this.props.departments,
        positions: this.props.positions,
        users: users ? users.users : [],
        filteredUsers: users ? users.users : [],
        activeUsers: this.props.users,
        currentPage: users ? users.page : 1, 
        totalPages: users? users.totalPages: 1, 
        hasNext: users.hasNext, 
        hasPrevious: users.hasPrevious,
        pageSize: users.pageSize
      },()=>{
        console.log("state: ",this.state.hasPrevious)
      });
    })

  }
  loadUsers = async (take) => {
    const users = await Fetcher.fetchUsers({ page: this.state.currentPage, take: take });
    console.log("🚀 ~ UserContainer ~ loadUsers= ~ users:", users)
    this.setState({ pageSize: users.pageSize,users: users.users, filteredUsers: users.users, totalPages: users.totalPages,currentPage:users.page,hasNext:users.hasNext,hasPrevious:users.hasPrevious })
  }
  render() {
    return (
      <section id={this.props.id} className="content">
        {/* <h1>{this.props.title}</h1> */}
        <div className="actions flex-row flex-space border-bottom-main">
          {this.props.user.position === 1 || this.props.user.position === 6 ? (
            <>
              <button
                id={"newUserButton"}
                className="bg-color-main"
                onClick={this.handleButtonClick}
              >
                {"New User"}
              </button>
              <button
                id={"restoreButton"}
                className="bg-color-main"
                onClick={this.handleButtonClick}
              >
                {"Restore User"}
              </button>
              <button
                id={"uploadPhoneNumbersButton"}
                className="bg-color-main"
                onClick={this.handleButtonClick}
              >
                {"Upload Phone Numbers"}
              </button>
            </>
          ) : null}
          <SearchBar onChange={this.handleSearch} />
        </div>
        {
          this.state.showRestore ?
            <UserListItem
              user={this.state.selectedUser}
              users={this.state.filteredUsers}
              onClick={(user) => this.handleItemClick(user)}
              hasNext={this.state.hasNext}
              hasPrevious={this.state.hasPrevious}
              currentPage={this.state.currentPage}
              totalPages={this.state.totalPages}
              onPageChange={(page) => this.handlePageChange(page)}
              onPageSizeChange={(pageSize) => this.handlePageSizeChange(pageSize)}
              pageSize={this.state.pageSize}
            /> : null}
        {!this.state.showForm && !this.state.showRestore && !this.state.showUpload ?
          <UserListItem
            user={this.state.selectedUser}
            users={this.state.filteredUsers}
            onClick={(user) => this.handleItemClick(user)}
            hasNext={this.state.hasNext}
            hasPrevious={this.state.hasPrevious}
            currentPage={this.state.currentPage}
            totalPages={this.state.totalPages}
            onPageChange={(page) => this.handlePageChange(page)}
            onPageSizeChange={(pageSize) => this.handlePageSizeChange(pageSize)}
            pageSize={this.state.pageSize}
          /> : null}
        {
          this.state.showUpload ?
            <form className="content-list m-2">

              <div className="form-group mt-4 mx-auto">
                <label className="text-color-accent text-start pt-4" htmlFor="reg_no">
                  Upload an Excel (.xlxs) file with phone numbers and email addresses
                </label>
                <div className="flex-row flex-start">
                  <input onChange={this.handleFileInput}
                    className="form-control "
                    type="file"
                    name="phone_file"
                    id="phone_file"
                  />
                  {/* </div> */}
                  <span className="flex-row flex-start">
                    {this.state.uploadData ?
                      <input
                        className="btn bg-color-accent"
                        type="button"
                        id="savePhonesButton"
                        value="Save"
                        onClick={this.handleButtonClick}
                      /> : null}
                    <span
                      id="cancelButton"
                      className="btn text-color-accent"
                      onClick={this.handleButtonClick}
                    >
                      CANCEL
                    </span>
                  </span>
                </div>
              </div>
              <UserListItem
                user={this.state.selectedUser}
                users={this.state.filteredUsers}
                onClick={(user) => this.handleItemClick(user)}
                hasNext={this.state.hasNext}
                hasPrevious={this.state.hasPrevious}
                currentPage={this.state.currentPage}
                totalPages={this.state.totalPages}
                onPageChange={(page) => this.handlePageChange(page)}
                onPageSizeChange={(pageSize) => this.handlePageSizeChange(pageSize)}
                pageSize={this.state.pageSize}
              /></form> : null}
        {this.state.showForm ?
          <div className="content-list center-self">
            {this.state.formAction === "edit" ? (
              <UserEditForm
                loggedInUser={this.props.user}
                user={this.state.selectedUser}
                onRefresh={(users) => this.refreshUsers(users)}
                onFeedback={(status, msg) => this.handleFeedback(status, msg)}
                positions={this.state.positions}
                users={this.state.users}
                departments={this.state.departments}
                cancelButtonClick={this.cancelForm}
              />
            ) : (
              <UserForm
                user={this.state.selectedUser}
                onRefresh={(users) => this.refreshUsers(users)}
                onFeedback={(status, msg) => this.handleFeedback(status, msg)}
                positions={this.state.positions}
                users={this.state.users}
                departments={this.state.departments}
                cancelButtonClick={this.cancelForm}
              />
            )}
          </div> : null}

      </section>
    );
  }
}
export default UserContainer;
