import React from "react";
// import logo from "./logo.svg";
import "./boostrap/bootstrap.css";
import "./App.css";
import CustomRouter from "./components/router";

function App() {
  return (
    <div className="App">
      <CustomRouter />
      {/* <LoginForm /> */}
    </div>
  );
}

export default App;
