import React from "react";
import Fetcher from "../utils/fetcher";
import CustomDialog from "./custom_dialog";
import Commons from "../utils/commons.js";
import html2canvas from "html2canvas";
import logo from "../images/care-logo_white.png";
import jsPDF from "jspdf";
const config = require("../utils/config.json");

class FuelRequestClosing extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      comment: "",
      users: this.props.users,
      complete: false,
      hasError: false,
      selectedDate: "",
      price: props.request.price,
      amount: props.request.amount,
      mileage: props.request.mileage,
      start_time: props.request.start_time,
      lastRequest: null,
      distance: 0,
      cost: props.price * props.amount,
      needsConfirmation: false,
    };
    this.user = props.user;
    this.initialAmount = props.request.amount;
    this.handleBlur = this.handleBlur.bind(this);
    this.handleDateChange = this.handleDateChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleComplete = this.handleComplete.bind(this);
    this.handleFocus = this.handleFocus.bind(this);
    this.printOrder = this.printOrder.bind(this);
    this.handleDiscard = this.handleDiscard.bind(this);
  }
  handleDiscard = () => {
    let remarks = "Discarded requests";
    let req = this.props.request;
    let user = this.props.user;
    let data = {
      requestId: req.id,
      requestType: req.type,
      remark: remarks,
      closer: user.id,
      status: 5,
      requestor: req.requestor,
      approver: req.approver,
      vehicle: req.vehicle,
    };
    // console.log("Test: ", data);
    this.updateRequest(data);
  };
  handleFocus(e) {
    this.state.complete ? e.preventDefault() : (e.target.type = "date");
  }
  handleBlur = (event) => {
    event.preventDefault();
    event.target.type = "text";
    event.target.value = this.state.selectedDate;
  };
  handleChange = (event) => {
    let req = this.props.request;
    let distanceInput = document.getElementById("distance");

    event.preventDefault();
    let input = event.target;
    let value = input.value.replace(",", "");
    switch (input.id) {
      case "mileage":
        this.setState(
          {
            mileage: value,
            distance:
              value -
              (this.state.lastRequest
                ? this.state.lastRequest.a_mileage
                : this.props.request.mileage),
          },
          () => {
            distanceInput.value = this.state.distance;
            this.setState({
              hasError:
                this.state.distance < 0 || this.state.amount > req.amount,
            });
          }
        );
        break;
      case "amount":
        this.setState(
          {
            amount: value,
            cost: parseInt(value) * this.state.price,
          },
          () => {
            this.setState({
              hasError:
                this.state.distance < 0 || this.state.amount > req.amount,
            });
          }
        );
        break;
      case "price":
        this.setState(
          {
            price: value,
            cost: parseInt(value) * this.state.amount,
          },
          () => {
            this.setState({
              hasError:
                this.state.distance < 0 || this.state.amount > req.amount,
            });
          }
        );
        break;
      case "comment":
        this.setState({ comment: value });
    }
  };
  handleComplete = (event) => {
    let dateInput = document.getElementById("date_processed");
    let date = new Date(dateInput.value);
    let dateProcessed = date.getTime() / 1000;
    let req = this.props.request;

    let data = {
      requestId: req.id,
      requestType: req.type,
      comment: this.state.comment,
      status: 3,
      amount: this.state.amount,
      price: this.state.price,
      closer: this.props.user.id,
      mileage: this.state.mileage,
      date_processed: dateProcessed,
    };
    if (event.target.id === "cancelRequest") {
      data = {
        status: 5,
      };
    }
    console.log("complete: ", data);
    this.updateRequest(data);
  };

  updateRequest = (data) => {
    fetch(config.base_url + "/request/" + data.requestId, {
      method: "put",
      headers: { "Content-type": "application/json" },
      body: JSON.stringify(data),
    })
      .then((res) => res.json())
      .then((result) => {
        console.log("res update:", result);
        if (result.error) {
          this.props.onFeedback(false, result.error);
        } else {
          this.props.onFeedback(true, result.response);
          Fetcher.fetchRequests(this.props.user)
            .then((requests) => {
              this.props.onRefresh(requests);
            })
            .catch((error) => {})
            .finally(() => {
              this.handleCancel();
            });
        }
      })
      .catch((error) => {
        this.props.onFeedback(false, error.error);
        this.handleCancel();
      });
  };

  componentDidMount() {
    
    Fetcher.fetchLastRequest(this.props.request.vehicle, this.props.request.type)
      .then((request) => {
        console.log("last req: ", request);
        if (request.length > 0) {
          this.setState({
            lastRequest: request[0],
            distance: this.props.request.mileage - request[0].a_mileage,
          });
        }
      })
      .catch((err) => {
        this.props.onFeedback(false, err);
      });
    let req = this.props.request;
    if (req !== undefined) {
      this.setState({
        price: req.price,
        amount: req.amount,
        supplier_name: req.supplier_name,
        mileage: req.mileage,
        start_time: req.start_time,
        isEditable:
          req.requestor === this.user.id || this.user.username === "admin",
        complete:
          req.statusText === "Closed" ||
          req.statusText === "Cancelled" ||
          req.statusText === "Rejected"
            ? true
            : false,
      });
    }
  }

  handleCancel() {
    console.log("cancelling user form");
    this.props.cancelButtonClick();
  }

  handleSubmit(e) {
    e.preventDefault();

    // this.props.onFeedback(true, "testing...");
  }
  handleDateChange(event) {
    event.preventDefault();
    let dateInput = event.target;
    let date = new Date(dateInput.value);
    let formatedDate = Commons.dateFormat(date.getTime() / 1000);
    dateInput.type = "text";
    dateInput.value = formatedDate;
    this.setState({ selectedDate: formatedDate });
  }
  printOrder = () => {
    const buttonArea = document.getElementById("button-area");
    buttonArea.parentNode.removeChild(buttonArea);
    const form = document.querySelector("#fuel-order-form");
    const heading = document.querySelector("#heading");
    // heading.classList.remove("bg-color-main");
    const title = document.querySelector("#title");
    title.textContent = "Fuel Purchase Order";
    // title.style.color = "black";
    let logo = document.querySelector("#logo");
    logo.style.display = "block";
    let now = new Date().getTime();
    let filename = "Fuel_Order_" + now;

    var windowFeatures =
      "menubar=no,location=no,resizable=yes,scrollbars=yes,status=no,width=1122.5,height=793.7";
    var printPreview = window.open(
      "about:blank",
      "print_preview",
      windowFeatures
    );
    var printDocument = printPreview.document;
    printDocument.open();
    printDocument.write(
      "<!DOCTYPE html>" +
        "<html><head>" +
        document.head.innerHTML +
        "</head><body align='center' margin='20px'>" +
        form.parentNode.innerHTML +
        "</body></html>"
    );
    printDocument.title = filename;
    printDocument.close();
    html2canvas(printDocument.body)
      .then((canvas) => {
        let pdfDoc = new jsPDF("l", "mm", "a4");
        let img = canvas.toDataURL("image/jpg");
        pdfDoc.addImage(img, 0, 0);
        pdfDoc.save(filename + ".pdf");
      })
      .catch((e) => {
        console.log("error printing: ", e);
      });
    form.appendChild(buttonArea);
    logo.style.display = "none";
    title.textContent = "Fuel Request Completion";
  };
  render() {
    return (
      <>
        <div className={"flex-row flex-center center-self"}>
          <form
            name="fuel-order-form"
            id="fuel-order-form"
            className="center-self border-all-main"
            onSubmit={this.handleSubmit}
          >
            <div id="heading" className="w-100 bg-color-main">
              <h2 id="title">Fuel Request Completion</h2>
              <img id="logo" src={logo} className="logo" alt="Care logo" />
            </div>

            <div className="w-100 flex-row flex-start">
              <div className="form-group text-color-accent">
                <label htmlFor="date_requested">Date Requested</label>
                <input
                  readOnly={true}
                  className="form-control "
                  type="text"
                  name="date_requested"
                  id="date_requested"
                  value={Commons.dateFormat(this.props.request.date_created)}
                />
              </div>
              <div className="form-group">
                <label htmlFor="from" className="form-label text-color-accent">
                  Date Refueled
                </label>

                <input
                  readOnly={this.state.complete}
                  onBlur={this.handleBlur}
                  onFocus={this.handleFocus}
                  onChange={this.handleDateChange}
                  className="form-control "
                  type="text"
                  name="date_processed"
                  id="date_processed"
                  defaultValue={Commons.dateFormat(this.state.start_time)}
                />
              </div>
              <div className="form-group">
                <label htmlFor="name" className="form-label text-color-accent">
                  Requestor's Name
                </label>

                <input
                  readOnly={true}
                  type="text"
                  className="form-control "
                  name="name"
                  id="name"
                  defaultValue={this.props.request.nameOfRequestor}
                />
              </div>
            </div>
            <div className="w-100 flex-row flex-start">
              <div className="form-group">
                <label
                  htmlFor="vehicle"
                  className="form-label text-color-accent"
                >
                  Vehicle
                </label>
                <input
                  readOnly={true}
                  className="form-control "
                  type="text"
                  name="vehicle"
                  id="vehicle"
                  placeholder="No vehicle info"
                  defaultValue={this.props.request.vehicle}
                />
              </div>
              <div className="form-group">
                <label
                  htmlFor="mileage"
                  className="form-label text-color-accent"
                >
                  Current Mileage
                </label>
                <input
                  readOnly={this.state.complete}
                  onChange={this.handleChange}
                  className="form-control "
                  type="text"
                  name="mileage"
                  id="mileage"
                  placeholder="No mileage info"
                  defaultValue={this.state.mileage}
                />
              </div>

              <div className="form-group">
                <label
                  htmlFor="lmileage"
                  className="form-label text-color-accent"
                >
                  Last Refuel Mileage
                </label>
                <input
                  readOnly={true}
                  className="form-control "
                  type="text"
                  name="lmileage"
                  id="lmileage"
                  placeholder="No mileage info"
                  defaultValue={
                    this.state.lastRequest
                      ? this.state.lastRequest.a_mileage
                      : ""
                  }
                />
              </div>
              <div className="form-group">
                <label
                  htmlFor="distance"
                  className="form-label text-color-accent"
                >
                  Distance Covered
                </label>
                <input
                  readOnly={true}
                  className={
                    "form-control " + (this.state.distance < 0 ? "fail" : "")
                  }
                  type="text"
                  name="distance"
                  id="distance"
                  placeholder="No mileage info"
                  defaultValue={
                    this.state.lastRequest
                      ? this.props.request.mileage -
                        this.state.lastRequest.a_mileage
                      : ""
                  }
                />
              </div>
            </div>
            <div className="flex-row flex-start w-100">
              <div className="form-group">
                <label
                  htmlFor="supplier"
                  className="form-label text-color-accent"
                >
                  Supplier
                </label>
                <input
                  readOnly={this.state.complete}
                  onChange={this.handleChange}
                  className="form-control "
                  type="text"
                  name="supplier"
                  id="supplier"
                  defaultValue={this.state.supplier_name}
                />
              </div>
              <div className="form-group">
                <label
                  htmlFor="amount"
                  className="form-label text-color-accent"
                >
                  Fuel Quantity (Litres)
                </label>

                <input
                  readOnly={!this.state.isEditable}
                  onChange={this.handleChange}
                  className={
                    "form-control " +
                    (this.state.amount > this.initialAmount ? "fail" : "")
                  }
                  type="text"
                  name="amount"
                  id="amount"
                  value={Commons.formatNumber(this.state.amount)}
                />
              </div>
              <div className="form-group">
                <label htmlFor="price" className="form-label text-color-accent">
                  Fuel Price (Tsh)
                </label>
                <input
                  readOnly={this.state.complete}
                  onChange={this.handleChange}
                  className="form-control "
                  type="text"
                  name="price"
                  id="price"
                  value={Commons.formatNumber(this.state.price)}
                />
              </div>
              <div className="form-group">
                <label htmlFor="cost" className="form-label text-color-accent">
                  Cost of Fuel (Tsh)
                </label>
                <input
                  readOnly={true}
                  className="form-control "
                  type="text"
                  name="cost"
                  id="cost"
                  value={Commons.formatNumber(
                    this.state.price * this.state.amount
                  )}
                />
              </div>
            </div>
            <div className="flex-row flex-start w-100">
              <div className="form-group">
                <label
                  htmlFor="project"
                  className="form-label text-color-accent"
                >
                  Project
                </label>
                <input
                  readOnly={true}
                  type="text"
                  className="form-control"
                  name="project"
                  id="project"
                  value={this.props.request.project}
                />
              </div>
              <div className="form-group">
                <label
                  htmlFor="fund_code"
                  className="form-label text-color-accent"
                >
                  Fund Code
                </label>
                <input
                  readOnly={true}
                  type="text"
                  id="fund_code"
                  className="form-control"
                  defaultValue={this.props.request.fundcode}
                />
              </div>
              <div className="form-group">
                <label
                  htmlFor="account"
                  className="form-label text-color-accent"
                >
                  Account
                </label>
                <input
                  readOnly={true}
                  className="form-control "
                  type="text"
                  name="account"
                  id="account"
                  defaultValue={this.props.request.account}
                />
              </div>
            </div>
            <div className="flex-row flex-start w-100">
              <div className="form-group">
                <label
                  htmlFor="source"
                  className="form-label text-color-accent"
                >
                  Source
                </label>
                <input
                  readOnly={true}
                  id="source"
                  name="source"
                  className="form-control"
                  value={this.props.request.source}
                />
              </div>
              <div className="form-group">
                <label
                  htmlFor="category"
                  className="form-label text-color-accent"
                >
                  Category
                </label>
                <input
                  readOnly={true}
                  id="category"
                  className="form-control"
                  name="category"
                  value={this.props.request.category}
                />
              </div>
              <div className="form-group">
                <label
                  htmlFor="activity"
                  className="form-label text-color-accent"
                >
                  Activity
                </label>
                <input
                  readOnly={true}
                  id="activity"
                  name="activity"
                  className="form-control"
                  value={this.props.request.activity}
                />
              </div>
            </div>
            <div className="form-group">
              <label htmlFor="comment" className="form-label text-color-accent">
                Requestor's Comment
              </label>
              <input
                readOnly={this.state.complete}
                onChange={this.handleChange}
                id="comment"
                name="comment"
                className="form-control"
                defaultValue={this.props.request.comment}
              />
            </div>
            <div className="form-group">
              <label htmlFor="remark" className="form-label text-color-accent">
                Approver's Remarks
              </label>
              <input
                readOnly={true}
                id="remark"
                name="remark"
                className="form-control"
                defaultValue={this.props.request.remark}
              />
            </div>
            <div className="form-group">
              <label htmlFor="remark1" className="form-label text-color-accent">
                Admin's Remarks
              </label>
              <input
                readOnly={this.state.complete}
                onChange={this.handleChange}
                id="remark1"
                name="remark1"
                className="form-control"
                defaultValue={this.props.request.remark1}
              />
            </div>
            <div className="flex-row flex-space flex-middle">
              <div className="form-group">
                <label
                  htmlFor="approver"
                  className="form-label text-color-accent"
                >
                  {this.props.request.statusText +
                    " by " +
                    (this.props.request.status === 3 ||
                    this.props.request.status === 5
                      ? this.props.request.nameOfCloser
                      : this.props.request.nameOfAttender)}
                </label>
              </div>
              {this.state.hasError ? (
                <div className="form-group">
                  <span className="form-label error-text">
                    {this.state.amount > this.props.request.amount
                      ? "You cannot refill more fuel than the amount approved"
                      : this.state.distance < 0
                      ? "Distance covered cannot be negative"
                      : null}
                  </span>
                </div>
              ) : null}
            </div>

            <div className="form-group" id="button-area">
              <span className="flex-row flex-space">
                {!this.state.complete && this.state.isEditable ? (
                  this.state.hasError ? null : (
                    <span
                      className="btn btn-success"
                      type="button"
                      id="complete"
                      onClick={this.handleComplete}
                    >
                      COMPLETE
                    </span>
                  )
                ) : null}
                {this.props.user.id == this.props.request.requestor &&
                this.props.request.status < 3 ? (
                  <span
                    id="cancel-request"
                    className="btn-reject btn "
                    onClick={() => {
                      this.setState({ needsConfirmation: true });
                    }}
                  >
                    DISCARD
                  </span>
                ) : null}
                {this.props.request.status == 2 ? (
                  <span className="btn btn-normal" onClick={this.printOrder}>
                    FUEL ORDER
                  </span>
                ) : null}
                <span
                  id="cancel"
                  className="btn text-color-accent"
                  onClick={this.handleCancel}
                >
                  CLOSE
                </span>
              </span>
            </div>
          </form>
        </div>
        {this.state.needsConfirmation ? (
          <CustomDialog
            title={"Confirm Request Discard"}
            onCancel={this.handleCancel}
            msg={"Are you sure you want to discard this request?"}
            onAction={this.handleDiscard}
            buttonText={"DISCARD"}
          />
        ) : null}
      </>
    );
  }
}
export default FuelRequestClosing;
