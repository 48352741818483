import React from "react";
import { getCityNames } from "postcodes-tz";
import Fetcher from "../utils/fetcher";
import Commons from "../utils/commons";
const config = require("../utils/config.json");

class TripDetailForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isVisible: true,
      users: [],
      drivers: [],
      filteredUsers: [],
      processed: undefined,
      isEditable: undefined,
      trip: this.props.trip,
      distance: null,
      invalid: true,
      request: null,
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
    this.getUserName = this.getUserName.bind(this);
    this.calculateDistance = this.calculateDistance.bind(this);
    this.secondsToTimeString = this.secondsToTimeString.bind(this);
    this.saveTrip = this.saveTrip.bind(this);
    this.handleMileageChange = this.handleMileageChange.bind(this);
    this.handleTimeChange = this.handleTimeChange.bind(this);
  }
  handleTimeChange(end, start) {
    let time = Commons.calculateTime(end, start);
    this.setState({
      duration:
        time.h +
        ":" +
        (time.m < 10 ? "0" : "") +
        time.m +
        ":" +
        (time.s < 10 ? "0" : "") +
        time.s,
    });
  }
  calculateDistance = (end, start) => {
    let dist = end - start;

    this.setState({ distance: dist });
  };
  handleMileageChange(event) {
    let endMileage = event.target;
    let startMileage = document.getElementById("start_mileage");
    this.calculateDistance(endMileage.value, startMileage.value);
  }
  saveTrip() {
    let trip = this.props.trip;
    let startLocation = document.getElementById("location").value;
    let destination = document.getElementById("destination").value;
    let mileage = document.getElementById("end_mileage").value;
    let stime = document.getElementById("start_time").value;
    let etime = document.getElementById("end_time").value;
    let start_city = document.getElementById("start_city").value;
    let destination_city = document.getElementById("destination_city").value;
    let remark = document.getElementById("remark").value;
    let date = new Date();
    let endDate = new Date(
      date.getMonth() +
        1 +
        "/" +
        date.getDate() +
        "/" +
        date.getFullYear() +
        " " +
        etime
    );
    let endTime = endDate.getTime() / 1000;
    let startDate = new Date(
      date.getMonth() +
        1 +
        "/" +
        date.getDate() +
        "/" +
        date.getFullYear() +
        " " +
        stime
    );
    let startTime = startDate.getTime() / 1000;

    let data = {
      tripId: trip.id,
      requestId: trip.request_id,
      requestor: trip.requestor,
      driver: trip.driver,
      vehicle: trip.vehicle,
      vehicleId: trip.vehicle_id,
      startLocation: startLocation,
      destination: destination,
      endMileage: mileage,
      startTime: startTime,
      endTime: endTime,
      remark: remark,
      start_city: start_city,
      destination_city: destination_city,
      closer: this.props.user.id,
    };
    fetch(config.base_url + "/trip/" + data.tripId, {
      method: "post",
      headers: { "Content-type": "application/json" },
      body: JSON.stringify(data),
    })
      .then((res) => res.json())
      .then((result) => {
        if (result.error) {
          this.props.onFeedback(false, result.error);
        } else {
         let data = {};
          Fetcher.fetchTrips(this.props.user)
            .then((trips) => {
              data.trips = trips;
               Fetcher.fetchRequests(this.props.user).then(requests=>{
                  Fetcher.fetchUsers().then(users=>{
                    data.users = users;
                    Fetcher.fetchVehicles().then(vehicles=>{
                      data.vehicles = vehicles;
                      this.props.onRefresh(data);
                    })
                    .catch(e=>{
                      console.log(e);
                    })
                  }).catch(e=>{
                    console.log(e);
                  })
               }).catch(e=>{
                console.log(e);
               })
            })
            .catch((error) => {
              this.props.onFeedback(true, result);
            }).finally(()=>{
              // this.props.onRefresh(data);
              this.props.onFeedback(true, result);
            });
        }
        this.props.cancelButtonClick();
      })
      .catch((error) => {
        this.props.onFeedback(false, error.error);
        this.props.cancelButtonClick();
      });
  }

  componentDidMount() {
    let trip = this.props.trip;
    let user = this.props.user;
    this.setState({
      processed: trip.status === "Complete",
      isEditable:
        (user.id === trip.driver || user.id === 1) &&
        trip.status === "Incomplete",
    });

    let startLocation = document.getElementById("start_mileage").value;
    let endLocation = document.getElementById("end_mileage").value;
    let start = document.getElementById("start_time").value;
    let end = document.getElementById("end_time").value;
    this.calculateDistance(endLocation, startLocation);

    Fetcher.fetchRequest(trip.request_id, 0).then((request) => {
      this.setState({ request: request });
    });
  }
  getUserName(id) {
    if (id === 1) return "System Administrator";
    let users = this.props.users.filter((u) => {
      return u.id === id;
    });
    if (users.length > 0) return users[0].fname + " " + users[0].lname;
    return "Unknown";
  }
  handleCancel() {
    this.props.cancelButtonClick();
  }
  handleSubmit(e) {
    e.preventDefault();

    // this.props.onFeedback(true, "testing...");
  }
  secondsToTimeString(seconds) {
    let date = new Date(seconds * 1000);
    let time = date.toString().split(" ")[4];
    console.log("time: ", time);
    // date.getHours() + ":" + date.getMinutes() + 1 + ":" + date.getSeconds();
    return time;
  }

  render() {
    return (
      <div
        className={
          "flex-row flex-center center-self" +
          (!this.state.isVisible ? "hidden" : "")
        }
      >
        <form
          name="vehicle-request-form"
          id="vehicle-request-form"
          className="center-self border-all-main"
          onSubmit={this.handleSubmit}
        >
          <h2 className="w-100 bg-color-main">Trip Log Form</h2>
          <p>
            Please complete this form as soon as the trip is over in order to
            release the vehicle for other use
          </p>
          <div className="w-100 flex-row flex-start">
            <div className="form-group">
              <label className="text-color-accent" htmlFor="date_requested">
                Date Requested
              </label>
              <input
                readOnly={true}
                className="form-control "
                type="text"
                name="date_requested"
                id="date_requested"
                defaultValue={Commons.dateFormat(this.props.trip.date_created)}
              />
            </div>
            <div className="form-group">
              <label
                htmlFor="first_name"
                className="form-label text-color-accent"
              >
                Requestor's Name
              </label>

              <input
                readOnly={true}
                type="text"
                className="form-control "
                name="name"
                id="name"
                defaultValue={this.props.trip.nameOfRequestor}
              />
            </div>
            <div className="form-group">
              <label htmlFor="driver_name" className="text-color-accent">
                Driver's Name
              </label>

              <input
                readOnly={true}
                type="text"
                className="form-control "
                name="driver_name"
                id="driver_name"
                defaultValue={this.props.trip.nameOfDriver}
              />
            </div>
            <div className="form-group">
              <label htmlFor="vehicle" className="form-label text-color-accent">
                Vehicle used
              </label>
              <input
                readOnly={true}
                className="form-control "
                type="text"
                name="vehicle"
                id="vehicle"
                placeholder="No vehicle info"
                defaultValue={this.props.trip.vehicle}
              />
            </div>
          </div>
          <div className="w-100 flex-row flex-start">
            <div className="form-group">
              <label
                htmlFor="start_mileage"
                className="form-label text-color-accent"
              >
                Start Mileage
              </label>
              <input
                readOnly={true}
                className="form-control "
                type="number"
                name="start_mileage"
                id="start_mileage"
                placeholder="No mileage info"
                defaultValue={this.props.trip.start_mileage}
              />
            </div>

            <div className="form-group">
              <label
                htmlFor="end_mileage"
                className="form-label text-color-accent"
              >
                End Mileage
              </label>
              <input
                readOnly={!this.state.isEditable}
                className="form-control "
                type="number"
                name="end_mileage"
                id="end_mileage"
                placeholder="No mileage info"
                defaultValue={this.props.trip.end_mileage}
                onChange={this.handleMileageChange}
              />
            </div>
            <div className="form-group">
              <label
                htmlFor="distance"
                className="form-label text-color-accent"
              >
                Distance Covered (Km)
              </label>
              <input
                readOnly={true}
                disabled={true}
                className={
                  "form-control " + (this.state.distance < 0 ? "fail" : "")
                }
                type="number"
                name="distance"
                id="distance"
                placeholder="No mileage info"
                defaultValue={this.state.distance}
              />
            </div>
          </div>
          <div className="w-100 flex-row flex-start">
            <div className="form-group">
              <label
                htmlFor="location"
                className="form-label text-color-accent"
              >
                Start Location
              </label>

              <input
                readOnly={!this.state.isEditable}
                className="form-control "
                type="text"
                name="location"
                id="location"
                placeholder="Enter location"
                defaultValue={this.props.trip.start_location}
              />
            </div>
            <div className="form-group">
              <label
                htmlFor="start_city"
                className="form-label text-color-accent"
              >
                Start City/Region
              </label>
              <select
                disabled={!this.state.isEditable}
                className="form-control "
                defaultValue={this.props.trip.start_city}
                name="start_city"
                id="start_city"
              >
                {getCityNames("asc").map((c) => {
                  return <option key={c}>{c}</option>;
                })}
              </select>
            </div>
          </div>
          <div className="flex-row flex-space w-100">
            <div className="form-group">
              <label
                htmlFor="destination"
                className="form-label text-color-accent"
              >
                Final Destination
              </label>
              <input
                readOnly={!this.state.isEditable}
                className="form-control "
                type="text"
                name="destination"
                id="destination"
                placeholder="Enter final destination"
                defaultValue={this.props.trip.destination}
              />
            </div>
            <div className="form-group">
              <label
                htmlFor="destination_city"
                className="form-label text-color-accent"
              >
                Destination City/Region
              </label>
              <select
                disabled={!this.state.isEditable}
                className="form-control "
                defaultValue={this.props.trip.destination_city}
                name="destination_city"
                id="destination_city"
              >
                {getCityNames("asc").map((c) => {
                  return <option key={c}>{c}</option>;
                })}
              </select>
            </div>
          </div>
          <div className="w-100 flex-row flex-start">
            <div className="form-group">
              <label
                htmlFor="start_time"
                className="form-label text-color-accent"
              >
                Start Time
              </label>
              {!this.state.isEditable ? (
                <input
                  readOnly={!this.state.isEditable}
                  className="form-control "
                  type="text"
                  name="start_time"
                  id="start_time"
                  defaultValue={this.secondsToTimeString(
                    this.props.trip.start_time
                  )}
                />
              ) : (
                <input
                  onChange={() =>
                    this.handleTimeChange(
                      document.getElementById("end_time").value,
                      document.getElementById("start_time").value
                    )
                  }
                  className="form-control "
                  type="time"
                  name="start_time"
                  id="start_time"
                />
              )}
            </div>

            <div className="form-group">
              <label
                htmlFor="end_time"
                className="form-label text-color-accent"
              >
                End Time
              </label>
              {!this.state.isEditable ? (
                <input
                  readOnly={!this.state.isEditable}
                  className="form-control "
                  type="text"
                  name="end_time"
                  id="end_time"
                  defaultValue={this.secondsToTimeString(
                    this.props.trip.end_time
                  )}
                />
              ) : (
                <input
                  onChange={() =>
                    this.handleTimeChange(
                      document.getElementById("end_time").value,
                      document.getElementById("start_time").value
                    )
                  }
                  className="form-control "
                  type="time"
                  name="end_time"
                  id="end_time"
                />
              )}
            </div>
            <div className="form-group">
              <label
                htmlFor="duration"
                className="form-label text-color-accent"
              >
                Duration (Hours)
              </label>
              <input
                readOnly={true}
                disabled={true}
                className="form-control "
                type="text"
                name="duration"
                id="duration"
                placeholder="End time not set"
                defaultValue={this.state.duration}
              />
            </div>
          </div>

          <div className="form-group">
            <label htmlFor="remark" className="form-label text-color-accent">
              Remarks
            </label>
            <input
              readOnly={!this.state.isEditable}
              id="remark"
              name="remark"
              className="form-control"
              defaultValue={this.props.trip.remark}
            />
          </div>
          <div className="form-group">
            <span className="flex-row flex-space">
              {this.state.isEditable ? (
                this.state.distance <= 0 ? (
                  <input
                    disabled={true}
                    className="btn form-control"
                    type="button"
                    id="saveTrip"
                    value="Save"
                  />
                ) : (
                  <input
                    className="btn form-control btn-success"
                    type="button"
                    id="saveTrip"
                    value="Save"
                    onClick={() => this.saveTrip()}
                  />
                )
              ) : null}

              <span
                id="cancel-user"
                className="btn text-color-accent"
                onClick={this.handleCancel}
              >
                {this.state.isEditable ? "CANCEL" : "CLOSE"}
              </span>
            </span>
          </div>
        </form>
      </div>
    );
  }
}
export default TripDetailForm;
