import React from "react";
import Fetcher from "../utils/fetcher";
// import { getCityNames } from "postcodes-tz";
const config = require("../utils/config.json");
const FundcodeForm = (props) => {
  const handleCancel = (e) => {
    // e.preventDefault();
    props.onCancel();
  };
  const handleSubmit = (event) => {
    event.preventDefault();
    let project = document.getElementById("project");
    let code = document.getElementById("code");
    let department = document.getElementById("department");
    let expire = document.getElementById("expire");
    let activities = document.getElementById("activities");
    let fc = {
      project: project.value,
      code: code.value,

      expiration: new Date(expire.value).getTime() / 1000,
      activities: activities.value,
    };

    fetch(config.base_url + "/fundcode", {
      method: "post",
      headers: { "Content-type": "application/json" },
      body: JSON.stringify(fc),
    })
      .then((res) => res.json())
      .then((response) => {
        Fetcher.fetchFundcodes()
          .then((fundcodes) => {
            props.onRefresh(fundcodes);
            props.onFeedback(true, response.result);
          })
          .catch((e) => {
            props.onFeedback(
              true,
              "Successfully saved fund code but cannot refresh"
            );
          });
      })
      .catch((e) => {
        props.onFeedback(false, e);
      })
      .finally(() => {
        props.onCancel();
      });
  };
  return (
    <div className="overlay w-100 flex-row flex-center flex-middle">
      <form className="bg-color-white-dark w-50" onSubmit={handleSubmit}>
        <h2>Add New Fund Code</h2>
        <div className="form-group">
          <label htmlFor="project">Select Project</label>
          <select id="project" name="project" className="form-control">
            <option>--select--</option>
            {props.projects.length > 0 ? (
              props.projects.map((p) => {
                return <option key={p.id}>{p.code}</option>;
              })
            ) : (
              <option>No projects</option>
            )}
          </select>
        </div>
        <div className="form-group">
          <label htmlFor="code">Code</label>

          <input
            className="form-control"
            type="text"
            id="code"
            name="code"
            placeholder="Enter a code"
          />
        </div>

        <div className="form-group">
          <label htmlFor="expire">Expiration Date</label>

          <input
            type="date"
            className="form-control"
            id="expire"
            name="expire"
            placeholder="enter experation date"
          />
        </div>

        <div className="form-group">
          <label htmlFor="activities">Activities (separate with commas)</label>

          <input
            className="form-control"
            type="text"
            id="activities"
            name="activities"
            placeholder="Enter activities"
          />
        </div>
        <div className="form-group">
          <span className="flex-row flex-space">
            <input
              className="btn form-control bg-color-accent"
              type="submit"
              id="submitStation"
              value="Save"
            />
            <span
              id="cancel-user"
              className="btn text-color-accent"
              onClick={handleCancel}
            >
              CANCEL
            </span>
          </span>
        </div>
      </form>
    </div>
  );
};
export default FundcodeForm;
