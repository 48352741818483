import React from "react";
import Fetcher from "../utils/fetcher";
import Commons from "../utils/commons";
const config = require("../utils/config.json");
class ReportGenerator extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      fcodes: [],
      reportFormat: "list",
      report: [],
      report2: [],
      dateRange: null,
      title: "",
      groupBy: "",
      reportType: "fuel",
    };

    this.handleProjectChange = this.handleProjectChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.generateReport = this.generateReport.bind(this);
    this.handleReportFormatChange = this.handleReportFormatChange.bind(this);
    this.handleComplete = this.handleComplete.bind(this);
    this.handleGroupByChange = this.handleGroupByChange.bind(this);
  }
  handleComplete() {
    this.props.onComplete(
      this.state.reportFormat,
      this.state.dateRange,
      this.state.report,
      this.state.title,
      this.state.groupBy,
      this.state.reportType
    );

    this.props.onCancel("report");
  }
  handleGroupByChange(e) {
    let group = e.target.value;
    let title = "";
    if (this.props.type === "performance") {
      switch (group) {
        case "distance":
          title += "Distance Covered";
          break;
        case "fuel":
          title += "Fuel Consumption";
          break;
        case "fuel_cost":
          title += "Cost of Fuel";
          break;
        case "distance_fuel":
          title += "Average Fuel Consumption";
          break;
        case "total_cost":
          title += "Total Cost (Fuel and Maintenance)";
          break;
        case "maintenance":
          title += "Cost of Maintenance";
          break;
        case "distance_cost":
          title += "Average Cost per Kilometer";
          break;
      }
      this.setState({ groupBy: group, title: title });
    } else {
      this.setState({
        groupBy: group,
        title: this.state.title + " by " + group,
      });
    }
  }
  handleReportFormatChange(e) {
    let format = e.target.value;
    this.setState({ reportFormat: format });
  }

  generateReport = () => {
    let startDate = new Date(
      document.getElementById("sdate").value + " 00:00:00"
    );
    let endDate = new Date(
      document.getElementById("edate").value + " 23:59:59"
    );
    let dateRange = {
      start: startDate.getTime() / 1000,
      end: endDate.getTime() / 1000,
    };
    let reportFormat = document.getElementById("report_format").value;
    this.setState({ dateRange: dateRange, reportFormat: reportFormat });
    let options = {};
    options.startDate = startDate.getTime() / 1000;
    options.endDate = endDate.getTime() / 1000;
    if (reportFormat === "list") {
      let vehicle = document.getElementById("vehicle").value;
      let project = document.getElementById("project").value;
      let fund_code = document.getElementById("fund_code").value;

      let title = this.state.title;
      if (vehicle !== "all") {
        options.vehicle = vehicle;
        title += " for Vehicle " + vehicle;
      }
      if (this.props.type !== "mileage") {
        let supplier = document.getElementById("supplier").value;
        if (supplier !== "all") {
          options.supplier = supplier;
          title += " at " + supplier;
        }
      }

      if (project !== "all") {
        options.project = project;
        title += " for Project " + project;
      }
      if (fund_code !== "all") {
        options.fundcode = fund_code;
        title += " for Fundcode " + fund_code;
      }

      this.setState({ title: this.state.title + title });
    }

    if (this.props.type === "performance") {
      var finalResult;
      Fetcher.fetchPerformanceReport(options)
        .then((result) => {
          let groupM = Commons.groupByArray(result.mileage, "vehicle");
          console.log("groupM: ", groupM);
          let groupM2 = groupM.map((gm) => {
            let g = gm;
            let distance = gm.values.reduce((a, b) => a + b.distance, 0);
            g.cum_distance = distance;
            return g;
          });
          console.log("groupM2: ", groupM2);
          let groupF = Commons.groupByArray(result.fuel, "vehicle");
          console.log("groupF: ", groupF);
          let groupF2 = groupF.map((gf) => {
            let g = gf;
            let cost = gf.values.reduce(
              (a, b) => a + parseFloat(b.a_price) * parseInt(b.a_amount),
              0
            );
            g.cum_fuel_cost = cost;
            let amount = gf.values.reduce(
              (a, b) => a + parseInt(b.a_amount),
              0
            );
            g.cum_fuel_amount = amount;
            return g;
          });
          console.log("groupF2: ", groupF2);
          let groupMT = Commons.groupByArray(result.maintenance, "vehicle");
          console.log("groupMT: ", groupMT);
          let groupMT2 = groupMT.map((gmt) => {
            let g = gmt;
            let cost = gmt.values.reduce((a, b) => a + parseFloat(b.a_cost), 0);
            g.cum_maintenance_cost = cost;
            return g;
          });
          console.log("groupMT2: ", groupMT2);
          let finalReport = groupM2.map((gm) => {
            let item = {};
            item.key = gm.key;
            item.distance = gm.cum_distance;
            let fuel = groupF2.filter((gf) => {
              return gf.key === gm.key;
            });
            let maintenance = groupMT2.filter((gmt) => {
              return gmt.key === gm.key;
            });
            item.fuel_amount = fuel.length > 0 ? fuel[0].cum_fuel_amount : 0;
            item.fuel_cost = fuel.length > 0 ? fuel[0].cum_fuel_cost : 0;
            item.maintenance =
              maintenance.length > 0 ? maintenance[0].cum_maintenance_cost : 0;
            return item;
          });
          console.log("finalReport: ", finalReport);
          this.setState({ report: finalReport }, () => {
            this.handleComplete();
          });
        })
        .catch((error) => {
          console.error("big errror: ", error);
        });
    } else {
      let rept = this.props.type + "_report";
      fetch(config.base_url + "/" + rept, {
        method: "post",
        headers: { "Content-type": "application/json" },
        body: JSON.stringify(options),
      })
        .then((res) => res.json())
        .then((result) => {
          console.log("report: ", result);
          if (this.props.type === "mileage") {
            Fetcher.fetchProjects()
              .then((projects) => {
                console.log("report: ", projects);
                if (result.report) {
                  let report = result.report.map((r) => {
                    let rep = r;
                    let fc = projects.filter((f) => {
                      return f.code === r.project;
                    })[0];
                    rep.dept_code = fc.department_detail.code;
                    rep.region = fc.department_detail.region;
                    return rep;
                  });
                  this.setState({ report: report }, () => {
                    console.log("report2: ", this.state.report);
                  });
                } else {
                  this.setState({ report: [] });
                  this.props.onFeedback(false, "Something went wrong");
                }
              })
              .catch((error) => {
                console.log("generator: ", error);
                this.props.onFeedback(false, "Something went wrong");
              })
              .finally(() => {
                this.handleComplete();
              });
          } else {
            this.setState({ report: result.report });
            this.handleComplete();
          }
        })
        .catch((error) => {
          console.log("error: ", error);
          // this.props.onFeedback(false, error.error);
        });
    }
  };
  handleProjectChange(e) {
    e.preventDefault();
    let project = e.target.value;
    let codes = this.props.fundcodes.filter((fc) => {
      return fc.project === project;
    });
    this.setState({ fcodes: codes });
  }
  handleSubmit(e) {
    e.preventDefault();
  }
  componentDidMount() {
    this.setState({
      fcodes: this.props.fundcodes,
      reportType: this.props.type,
      groupBy: this.props.type === "performance" ? "distance" : "vehicle",
    });
  }
  render() {
    return (
      <div className="flex-row flex-center center-self">
        <form
          name="fuel-report-form"
          id="fuel-report-form"
          className="center-self border-all-main"
          onSubmit={this.handleSubmit}
        >
          <h2 className="w-100 bg-color-main">
            {this.props.type.substr(0, 1).toUpperCase() +
              this.props.type.substr(1) +
              " Report"}
          </h2>
          <div className="flex-row flex-start">
            <div className="form-group">
              <label htmlFor="sdate" className="form-label text-color-accent">
                Start Date
              </label>
              <input
                className="form-control "
                type="date"
                name="sdate"
                id="sdate"
              />
            </div>
            <div className="form-group">
              <label htmlFor="edate" className="form-label text-color-accent">
                End Date
              </label>
              <input
                className="form-control "
                type="date"
                name="edate"
                id="edate"
              />
            </div>
            <div className="form-group">
              <label
                htmlFor="report_format"
                className="form-label text-color-accent"
              >
                Select Report Type
              </label>
              <select
                defaultValue="list"
                id="report_format"
                name="report_format"
                className="form-control"
                onChange={this.handleReportFormatChange}
              >
                <option value="list">List</option>
                <option value="pie">Pie Chart</option>
                <option value="column">Histogram</option>
                <option value="line">Line Graph</option>
              </select>
            </div>
            {this.state.reportFormat !== "list" ? (
              this.state.reportType === "performance" ? (
                <div className="form-group">
                  <label
                    htmlFor="group_by"
                    className="form-label text-color-accent"
                  >
                    Select Factor
                  </label>
                  <select
                    defaultValue="distance"
                    id="group_by"
                    name="group_by"
                    className="form-control"
                    onChange={this.handleGroupByChange}
                  >
                    <option value="distance">Distance Travelled</option>
                    <option value="fuel">Fuel Consumed</option>
                    <option value="distance_fuel">
                      Average Distance Per Litre
                    </option>
                    <option value="fuel_cost">Cost of Fuel</option>
                    <option value="maintenance">Maintenance Cost</option>
                    <option value="total_cost">Total Cost</option>
                    <option value="distance_cost">
                      Average Cost per Kilometer
                    </option>
                  </select>
                </div>
              ) : (
                <div className="form-group">
                  <label
                    htmlFor="group_by"
                    className="form-label text-color-accent"
                  >
                    Group By
                  </label>
                  <select
                    defaultValue="list"
                    id="group_by"
                    name="group_by"
                    className="form-control"
                    onChange={this.handleGroupByChange}
                  >
                    <option value="vehicle">Vehicle</option>
                    <option value="supplier">Supplier</option>
                    {this.props.type.toLowerCase() === "maintenance" ? (
                      <option value="type">Service Type</option>
                    ) : this.props.type.toLowerCase() === "combine" ? (
                      <option value="serviceType">Service Type</option>
                    ) : null}
                    <option value="project">Project</option>
                    <option value="fundcode">Fund Code</option>
                    {this.props.type.toLowerCase() === "mileage" ? (
                      <>
                        <option value="dept_code">Department</option>
                        <option value="region">Region</option>
                      </>
                    ) : null}
                  </select>
                </div>
              )
            ) : null}
          </div>
          {this.state.reportFormat === "list" ? (
            <div className="w-100 flex-row flex-start">
              <div className="form-group">
                <label
                  htmlFor="vehicle"
                  className="form-label text-color-accent"
                >
                  Select Vehicle
                </label>
                <select
                  id="vehicle"
                  className="form-control"
                  onChange={this.handleVehicleChange}
                >
                  <option value="all">All</option>
                  {this.props.vehicles.map((v) => {
                    return <option key={v.id}>{v.pnumber}</option>;
                  })}
                </select>
              </div>
              {this.props.type !== "mileage" ? (
                <div className="form-group">
                  <label
                    htmlFor="supplier"
                    className="form-label text-color-accent"
                  >
                    Select Supplier
                  </label>
                  <select id="supplier" className="form-control">
                    <option value="all">All</option>
                    {this.props.suppliers.map((sup) => {
                      return (
                        <option key={sup.id} value={sup.id}>
                          {sup.name}
                        </option>
                      );
                    })}
                  </select>
                </div>
              ) : null}
              <div className="form-group">
                <label
                  htmlFor="project"
                  className="form-label text-color-accent"
                >
                  Project
                </label>
                <select
                  id="project"
                  className="form-control"
                  onChange={this.handleProjectChange}
                >
                  <option value="all">All</option>
                  {this.props.projects.map((p) => {
                    return <option key={p.id}>{p.code}</option>;
                  })}
                </select>
              </div>
              <div className="form-group">
                <label
                  htmlFor="fund_code"
                  className="form-label text-color-accent"
                >
                  Fund Code
                </label>
                <select id="fund_code" className="form-control">
                  <option value="all">All</option>
                  {this.state.fcodes.map((fcode) => {
                    return <option key={fcode.id}>{fcode.code}</option>;
                  })}
                </select>
              </div>
            </div>
          ) : null}
          <div className="form-group">
            <span className="flex-row flex-space">
              <input
                className="btn form-control bg-color-accent"
                type="button"
                id="btnGenerate"
                value="Generate Report"
                onClick={this.generateReport}
              />
              <span
                id="cancel"
                className="btn text-color-accent"
                onClick={() => this.props.onCancel("buttons")}
              >
                CANCEL
              </span>
            </span>
          </div>
        </form>
      </div>
    );
  }
}
export default ReportGenerator;
