import React from "react";
import Fetcher from "../utils/fetcher";
import Commons from "../utils/commons.js";

const config = require("../utils/config.json");
class FuelForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isVisible: true,
      positions: props.positions,
      vehicles: props.vehicles,
      filteredVehicles: props.vehicles,
      users: props.users,
      projects: props.projects,
      drivers: [],
      fcodes: props.fundcodes,
      filteredCodes: [],
      suppliers: props.suppliers,
      filteredSuppliers: [],
      location: this.props.user.location.trim(),
      selectedVehicle: {},
      filteredActivities: [],
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
    this.handleTimeChange = this.handleTimeChange.bind(this);
    this.handleVehicleChange = this.handleVehicleChange.bind(this);
    this.handleProjectChange = this.handleProjectChange.bind(this);
    this.handleCheckbox = this.handleCheckbox.bind(this);
    this.sendRequest = this.sendRequest.bind(this);
    this.handleDriverChange = this.handleDriverChange.bind(this);
    this.handleFundcodeChange = this.handleFundcodeChange.bind(this);
  }
  handleDriverChange = (event) => {
    let driverId = event.target.value;
    let driver = this.state.drivers.filter((d) => {
      return d.id == driverId;
    })[0];

    let vehicles = this.state.vehicles.filter((v) => {
      return v.location.trim() === driver.location.trim();
    });
    let suppliers = this.state.suppliers.filter((s) => {
      return s.region.trim() === driver.location.trim();
    });
    this.setState({
      filteredVehicles: vehicles,
      selectedVehicle: vehicles[0],
      filteredSuppliers: suppliers,
      location: driver.location.trim(),
    });
  };

  handleCheckbox = (event) => {
    let dateField = document.getElementById("date");
    if (event.target.checked) {
      const date = new Date();
      let today = Commons.dateFormat(date.getTime() / 1000);
      dateField.type = "text";
      dateField.value = today;
    } else {
      dateField.type = "date";
    }
  };

  handleVehicleChange(e) {
    e.preventDefault();
    let pnumber = e.target.value;
    let vehicles = this.state.filteredVehicles.filter((v) => {
      return v.pnumber === pnumber;
    });
    console.log("selected vehicles: ", vehicles);
    this.setState(
      {
        selectedVehicle: vehicles[0],
      },
      () => {
        console.log("selected vehicle: ", this.state.selectedVehicle);
      }
    );
  }
  handleProjectChange(e) {
    e.preventDefault();
    let project = e.target.value;
    let codes = Commons.getCodes(project, this.props.fundcodes);
    this.setState({ filteredCodes: codes });
  }

  handleFundcodeChange(event) {
    let fc = event.target.value;
    let activities = Commons.getActivities(fc, this.state.fcodes);
    this.setState({ filteredActivities: activities });
  }
  handleTimeChange(e) {
    let value = e.target.value;
    const from = document.getElementById("from");
    const to = document.getElementById("to");
    if (value === "Hours") {
      from.type = "time";
      to.type = "time";
    } else {
      from.type = "date";
      to.type = "date";
    }
  }
  sendRequest = (data) => {
    fetch(config.base_url + "/request", {
      method: "post",
      headers: { "Content-type": "application/json" },
      body: JSON.stringify(data),
    })
      .then((res) => res.json())
      .then((result) => {
        if (result.error) {
          this.props.onFeedback(false, result.error);
        } else {
          Fetcher.fetchRequests(this.props.user)
            .then((requests) => {
              this.props.onRefresh(requests);
              this.props.onFeedback(true, result.response);
            })
            .catch((error) => {
              this.props.onFeedback(true, result.response);
            });
        }
      })
      .catch((error) => {
        this.props.onFeedback(false, error.error);
      });
    this.handleCancel();
  };
  componentDidMount() {
    let user = this.props.user;
    if (this.props.drivers.length > 0) {
      let drivers = this.props.drivers.filter((u) => {
        return this.props.isAdmin
          ? u.position === 2
          : u.position === 2 && u.id === user.id;
      });

      this.setState({ drivers: drivers }, () => {
        let fv = this.state.vehicles.filter((v) => {
          return this.state.drivers[0].location.trim() === v.location.trim();
        });

        this.setState({
          filteredVehicles: fv,
          selectedVehicle: fv.length > 0 ? fv[0] : undefined,
          location:
            this.state.drivers.length > 0
              ? this.state.drivers[0].location.trim()
              : null,
        });
        if (this.props.suppliers.length > 0) {
          let suppliers = this.props.suppliers.filter((s) => {
            return this.props.isAdmin
              ? this.state.drivers[0].location.trim() === s.region.trim() &&
                  s.service === "Fuel"
              : s.region.trim() === user.location.trim() &&
                  s.service === "Fuel";
          });
          this.setState({ filteredSuppliers: suppliers });
        }
      });
    }

    if (this.state.fcodes.length > 0) {
      this.setState({
        filteredCodes: Commons.getCodes(
          this.state.projects[0].code,
          this.state.fcodes
        ),
      });
    }
  }
  handleCancel() {
    this.props.cancelButtonClick();
  }

  handleSubmit(e) {
    e.preventDefault();
    let selects = Array.from(document.getElementsByClassName("cb-activities"));
    let activities = [];
    selects.forEach((opt) => {
      if (opt.checked) activities.push(opt.value);
    });
    let project = document.getElementById("project").value;
    let myP = this.state.projects.filter((pj) => {
      return pj.code == project;
    });
    let supplier = document.getElementById("supplier");
    let price = document.getElementById("price");
    let amount = document.getElementById("quantity");
    let date = new Date(document.getElementById("date").value);
    let code = document.getElementById("fund_code").value;
    let dept = this.props.departments.filter((d) => {
      return d.region.trim() == this.state.location.trim();
    });

    let data = {
      requestType: 1,
      approver: myP[0].coordinator,
      admin: dept.length > 0 ? dept[0].admin : myP[0].department_detail.admin,
      requestor: document.getElementById("driver").value,
      vehicle: document.getElementById("vehicle").value,
      mileage: document.getElementById("mileage").value,
      supplier: supplier.value,
      price: price.value,
      amount: amount.value,
      date: date.getTime() / 1000,
      project: project,
      fundcode: code,
      activity: activities.join(),
      account: document.getElementById("account").value,
      source: document.getElementById("source").value,
      category: document.getElementById("category").value,
      location: this.state.location.trim(),
      status: 0,
    };
    if (supplier.type === "text") {
      if (supplier.value == "") {
        supplier.classList.add("fail");
        supplier.focus = true;
        return;
      }
      data.isNewSupplier = true;
    }
    if (data.price === "") {
      price.classList.add("fail");
      price.focus = true;
      return;
    } else {
      if (data.amount === "") {
        amount.classList.add("fail");
        amount.focus = true;
        return;
      } else {
        console.log("tracking from handleSubmit(): ", data);
        this.sendRequest(data);
      }
    }
  }

  render() {
    return (
      <div
        className={
          "flex-row flex-center center-self" +
          (!this.state.isVisible ? "hidden" : "")
        }
      >
        <form
          name="fuel-request-form"
          id="fuel-request-form"
          className="center-self border-all-main"
          onSubmit={this.handleSubmit}
        >
          <h2 className="w-100 bg-color-main">Refueling Request</h2>

          <div className="w-100 flex-row flex-start">
            <div className="form-group">
              <label htmlFor="driver" className="form-label text-color-accent">
                Select Driver
              </label>
              <select
                id="driver"
                className="form-control"
                onChange={this.handleDriverChange}
              >
                {this.state.drivers.length > 0 ? (
                  this.state.drivers.map((user) => {
                    return (
                      <option key={user.id} value={user.id}>
                        {user.fname + " " + user.lname}
                      </option>
                    );
                  })
                ) : (
                  <option>No drivers available</option>
                )}
              </select>
            </div>
            <div className="form-group">
              <label htmlFor="vehicle" className="form-label text-color-accent">
                Select Vehicle
              </label>
              <select
                id="vehicle"
                className="form-control"
                onChange={this.handleVehicleChange}
              >
                {this.state.filteredVehicles.length > 0 ? (
                  this.state.filteredVehicles.map((v) => {
                    return (
                      <option key={v.id} value={v.pnumber}>
                        {v.pnumber}
                      </option>
                    );
                  })
                ) : (
                  <option>No Vehicles Available</option>
                )}
              </select>
            </div>
            <div className="form-group">
              <label htmlFor="mileage" className="form-label text-color-accent">
                Mileage (Km)
              </label>
              <input
                className="form-control "
                type="number"
                name="mileage"
                id="mileage"
                placeholder="Enter Mileage in Km"
                defaultValue={
                  this.state.selectedVehicle !== undefined
                    ? this.state.selectedVehicle.current_mileage
                    : 0
                }
              />
            </div>
          </div>

          <div className="flex-row flex-start w-100">
            <div className="form-group">
              <label
                htmlFor="supplier"
                className="form-label text-color-accent"
              >
                Select Supplier
              </label>
              {this.state.filteredSuppliers.length > 0 ? (
                <select id="supplier" className="form-control">
                  {this.state.filteredSuppliers.map((sup) => {
                    return (
                      <option key={sup.id} value={sup.id}>
                        {sup.name}
                      </option>
                    );
                  })}
                </select>
              ) : (
                <input
                  type="text"
                  className="form-control"
                  id="supplier"
                  name="supplier"
                  placeholder="Enter supplier name"
                />
              )}
            </div>
            <div className="form-group">
              <label htmlFor="from" className="form-label text-color-accent">
                Unit Price (TZS)
              </label>
              <input
                className="form-control "
                type="number"
                name="price"
                id="price"
                placeholder="Enter price of 1 litre"
              />
            </div>
            <div className="form-group">
              <label
                htmlFor="quantity"
                className="form-label text-color-accent"
              >
                Quantity (Litres)
              </label>
              <input
                className="form-control "
                type="number"
                name="quantity"
                id="quantity"
                placeholder="Enter amount of fuel"
              />
            </div>
          </div>
          <div className="flex-row flex-start">
            <div className="form-group">
              <label htmlFor="date" className="form-label text-color-accent">
                Refuel Date
              </label>
              <input
                className="form-control "
                type="date"
                name="date"
                id="date"
              />
            </div>
            <div className="form-group">
              <label htmlFor="today" className="form-label text-color-accent">
                Today
              </label>
              <input
                onChange={this.handleCheckbox}
                className="form-control"
                type="checkbox"
                name="today"
                id="today"
              />
            </div>
          </div>

          <div className="flex-row flex-start w-100">
            <div className="form-group">
              <label htmlFor="project" className="form-label text-color-accent">
                Project
              </label>
              <select
                id="project"
                className="form-control"
                onChange={this.handleProjectChange}
              >
                <option>--select--</option>
                {this.state.projects.map((p) => {
                  return <option key={p.id}>{p.code}</option>;
                })}
              </select>
            </div>
            <div className="form-group">
              <label
                htmlFor="fund_code"
                className="form-label text-color-accent"
              >
                Fund Code
              </label>
              <select
                id="fund_code"
                className="form-control"
                onChange={this.handleFundcodeChange}
              >
                <option>--select--</option>
                {this.state.filteredCodes.map((fcode) => {
                  return <option key={fcode.id}>{fcode.code}</option>;
                })}
              </select>
            </div>
          </div>
          <div className="form-group">
            <label htmlFor="activity" className="text-color-accent">
              Activities
            </label>
            <div className="form-group flex-row flex-start">
              {this.state.filteredActivities.map((a) => {
                return (
                  <span key={"lb" + a} className=" flex-row flex-start">
                    <input
                      className="form-control cb-activities"
                      type="checkbox"
                      id={"cb" + a}
                      value={a}
                    />
                    <label htmlFor={"cb" + a} className="text-color-accent">
                      {a}
                    </label>
                  </span>
                );
              })}
            </div>
          </div>

          <div className="flex-row flex-start w-100">
            <div className="form-group">
              <label htmlFor="account" className="form-label text-color-accent">
                Account
              </label>
              <input
                className="form-control "
                type="text"
                name="account"
                id="account"
              />
            </div>
            <div className="form-group">
              <label htmlFor="source" className="form-label text-color-accent">
                Source
              </label>
              <input type="text" id="source" className="form-control" />
            </div>
            <div className="form-group">
              <label
                htmlFor="category"
                className="form-label text-color-accent"
              >
                Category
              </label>
              <input type="text" id="category" className="form-control" />
            </div>
          </div>

          <div className="form-group">
            <span className="flex-row flex-space">
              <input
                className={
                  "btn form-control " +
                  (this.state.filteredVehicles.length > 0
                    ? "bg-color-accent"
                    : "hidden")
                }
                type="submit"
                id="submitRequest"
                value="Submit"
              />
              <span
                id="cancel-user"
                className="btn text-color-accent"
                onClick={this.handleCancel}
              >
                CANCEL
              </span>
            </span>
          </div>
        </form>
      </div>
    );
  }
}
export default FuelForm;
