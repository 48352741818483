import React from "react";

class CustomDialog extends React.Component {
  constructor(props) {
    super(props);
    this.state = { msg: "" };
    this.handleAction = this.handleAction.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
  }
  handleCancel() {
    this.props.onCancel();
  }
  handleAction = (action) => {
    if (action) {
      this.props.onAction(action);
    } else {
      this.handleCancel();
    }
  };
  componentDidMount() {
    console.log("action: ", typeof this.props.onAction);
  }
  render() {
    return (
      <div className="overlay w-100 flex-row flex-center flex-middle">
        <div className="setting-section bg-color-white-dark w-40 flex-center flex-top">
          <h4 className="bg-color-main flex-row flex-center">
            {this.props.title}
          </h4>
          <p className="flex-row flex-center flex-middle">{this.props.msg}</p>

          <span className="flex-row flex-space flex-middle mx-5 my-5">
            {typeof this.props.onAction !== "undefined" ? (
              <button
                onClick={(action) => this.handleAction(action)}
                className="btn bg-color-accent"
                id="action"
              >
                {this.props.buttonText}
              </button>
            ) : null}
            <span
              id="cancel"
              className="btn text-color-accent"
              onClick={this.handleCancel}
            >
              CLOSE
            </span>
          </span>
        </div>
      </div>
    );
  }
}
export default CustomDialog;
