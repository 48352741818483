import React, { Component } from "react";
import FuelListReport from "./fuel_list_report";
import ReportGenerator from "./report_generator";
import MaintenanceListReport from "./maintenance_list_report";
import CombinedListReport from "./combined_list_report";
import MileageListReport from "./mileage_list_report";
import PerformanceListReport from "./performance_list_report";
import Fetcher from "../utils/fetcher";
import SMSReportGenerator from "./sms_report_generator";
import SMSListReport from "./SMS_list_report";

class ReportContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      reportFormat: "list",
      dateRange: null,
      title: "",
      report: [],
      showFuel: false,
      showMaintenance: false,
      showCombine: false,
      showButtons: true,
      showReport: false,
      showPerformance: false,
      showMileage: false,
      projects: [],
      fundcodes: [],
      suppliers: [],
      reportType: ""
    };
    this.cancelForm = this.cancelForm.bind(this);
    this.handleFeedback = this.handleFeedback.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.loadData = this.loadData.bind(this);
    this.handleReportComplete = this.handleReportComplete.bind(this);
  }
  handleReportComplete(
    reportFormat,
    dateRange,
    report,
    title,
    group,
    reportType
  ) {
    console.log("range: ", group);
    this.setState(
      {
        dateRange: dateRange,
        reportFormat: reportFormat,
        report: report,
        title: title,
        showReport: true,
        showButtons: false,
        showFuel: false,
        showMaintenance: false,
        showPerformance: false,
        showMileage: false,
        showSms: false,
        groupBy: group,
        reportType: reportType
      },
      () => {
        console.log("showreport; ", this.state.dateRange);
      }
    );
  }
  handleClick(tag) {
    this.setState({
      showSms: tag === "SMS",
      showFuel: tag === "fuel",
      showMaintenance: tag === "maintenance",
      showButtons: tag === "buttons",
      showCombine: tag === "combine",
      showPerformance: tag === "performance",
      showMileage: tag === "mileage",
      reportType: tag !== "buttons" ? tag : "fuel"
    });
  }
  handleSearch(e) {
    e.persist();
    let keyword = e.target.value.toLocaleLowerCase();
  }
  handleFeedback(status, message) {
    this.props.onFeedback(status, message);
  }
  cancelForm(target) {
    this.setState({
      showSms: target === "SMS",
      showFuel: target === "fuel",
      showMaintenance: target === "maintenance",
      showButtons: target === "buttons",
      showReport: target === "report",
      showCombine: target === "combine"
    });
  }
  loadData() {
    //load suppliers
    Fetcher.fetchSuppliers()
      .then(suppliers => {
        this.setState({ suppliers: suppliers });
      })
      .catch(error => {
        this.handleFeedback(false, error.error);
      });
    //load status
    Fetcher.fetchRequestStata().then(stata => {
      this.setState({ stata: stata }, () => {});
    });
    Fetcher.fetchFundcodes()
      .then(fundcodes => {
        this.setState({ fundcodes: fundcodes });
      })
      .catch(err => {
        this.handleFeedback(false, err);
      });

    Fetcher.fetchProjects()
      .then(projects => {
        this.setState({ projects: projects });
      })
      .catch(err => {
        this.handleFeedback(false, err);
      });
    Fetcher.fetchVehicles()
      .then(vehicles => {
        this.setState({
          vehicles: vehicles
        });
      })
      .catch(error => {
        this.handleFeedback(false, error);
      });
    Fetcher.getActiveUsers()
      .then(users => {
        this.setState({ users: users, filteredUsers: users });
      })
      .catch(error => {
        this.props.onFeedback(false, error);
      });
  }
  componentDidMount() {
    this.loadData();
  }
  componentWillUnmount() {
    this.setState({
      showButtons: true,
      showCombine: false,
      showFuel: false,
      showMaintenance: false,
      showMileage: false,
      showPerformance: false,
      showReport: false
    });
  }
  render() {
    return (
      <section id={this.props.id} className="content">
        {this.state.showButtons ? (
          <div className="center-self flex-row flex-start wrap">
            <div
              className="tags flex-row flex-space flex-middle bg-color-main-dark btn"
              onClick={() => this.handleClick("fuel")}
            >
              <i className="material-icons">local_gas_station</i>
              <span>Fuel Consumption</span>
            </div>
            <div
              className="tags flex-row flex-space flex-middle  bg-color-main-dark btn"
              onClick={() => this.handleClick("maintenance")}
            >
              <i className="material-icons">build</i>
              <span>Maintenance/Repair</span>
            </div>
            <div
              className="tags flex-row flex-space flex-middle  bg-color-main-dark btn"
              onClick={() => this.handleClick("combine")}
            >
              <i className="material-icons">description</i>
              <span>Combined Report</span>
            </div>

            <div
              className="tags flex-row flex-space flex-middle bg-color-main-dark btn"
              onClick={() => this.handleClick("mileage")}
            >
              <i className="material-icons">network_check</i>
              <span>Vehicle Mileage</span>
            </div>
            <div
              className="tags flex-row flex-space flex-middle bg-color-main-dark btn"
              onClick={() => this.handleClick("performance")}
            >
              <i className="material-icons">star</i>
              <span>Performance Report</span>
            </div>
            <div
              className="tags flex-row flex-space flex-middle bg-color-main-dark btn"
              onClick={() => this.handleClick("SMS")}
            >
              <i className="material-icons">sms</i>
              <span>SMS Report</span>
            </div>
          </div>
        ) : 
        this.state.showSms ?  <SMSReportGenerator
          type={this.state.reportType}
          onCancel={t => this.cancelForm(t)}
          onComplete={this.handleReportComplete}
          projects={this.state.projects}
          fundcodes={this.state.fundcodes}
          suppliers={this.state.suppliers}
          vehicles={this.state.vehicles}
          onFeedback={(s, m) => this.handleFeedback(s, m)}
        />:
        this.state.showMaintenance ||
          this.state.showFuel ||
          this.state.showCombine ||
          this.state.showMileage ||
          this.state.showPerformance ? (
          <ReportGenerator
            type={this.state.reportType}
            onCancel={t => this.cancelForm(t)}
            onComplete={this.handleReportComplete}
            projects={this.state.projects}
            fundcodes={this.state.fundcodes}
            suppliers={this.state.suppliers}
            vehicles={this.state.vehicles}
            onFeedback={(s, m) => this.handleFeedback(s, m)}
          />
        ) : this.state.showReport ? (
          this.state.reportType === "SMS" ? (
            <SMSListReport
              dateRange={this.state.dateRange}
              reportFormat={this.state.reportFormat}
              report={this.state.report}
              title={this.state.title}
              users={this.state.users}
              groupBy={this.state.groupBy}
              cancelButtonClick={() => this.cancelForm("buttons")}
            />
          ) : 
          this.state.reportType === "fuel" ? (
            <FuelListReport
              dateRange={this.state.dateRange}
              reportFormat={this.state.reportFormat}
              report={this.state.report}
              title={this.state.title}
              users={this.state.users}
              groupBy={this.state.groupBy}
              cancelButtonClick={() => this.cancelForm("buttons")}
            />
          ) : this.state.reportType === "maintenance" ? (
            <MaintenanceListReport
              dateRange={this.state.dateRange}
              reportFormat={this.state.reportFormat}
              report={this.state.report}
              title={this.state.title}
              users={this.state.users}
              groupBy={this.state.groupBy}
              cancelButtonClick={() => this.cancelForm("buttons")}
            />
          ) : this.state.reportType === "combine" ? (
            <CombinedListReport
              dateRange={this.state.dateRange}
              reportFormat={this.state.reportFormat}
              report={this.state.report}
              title={this.state.title}
              users={this.state.users}
              groupBy={this.state.groupBy}
              cancelButtonClick={() => this.cancelForm("buttons")}
            />
          ) : this.state.reportType === "mileage" ? (
            <MileageListReport
              dateRange={this.state.dateRange}
              reportFormat={this.state.reportFormat}
              report={this.state.report}
              title={this.state.title}
              users={this.state.users}
              groupBy={this.state.groupBy}
              cancelButtonClick={() => this.cancelForm("buttons")}
            />
          ) : (
            <PerformanceListReport
              dateRange={this.state.dateRange}
              reportFormat={this.state.reportFormat}
              report={this.state.report}
              title={this.state.title}
              users={this.state.users}
              dependent={this.state.groupBy}
              cancelButtonClick={() => this.cancelForm("buttons")}
            />
          )
        ) : null}
      </section>
    );
  }
}
export default ReportContainer;
