import React from "react";
import commons from "../utils/commons";
import Fetcher from "../utils/fetcher";

class NotificationList extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      notifications: props.notifications,
      target: null,
    };
    this.setStyle = this.setStyle.bind(this);
    this.handleClick = this.handleClick.bind(this);
  }
  handleClick = (item) => {
    console.log("from notification list item: ", item);
    Fetcher.clearNotification(this.props.user.id).then((success) => {
      Fetcher.fetchNotifications(this.props.user.id).then((notifications) => {
        this.setState({ notifications: notifications });
      });
    });
    this.props.onItemClick(item);
  };
  setStyle(state) {
    if (state == 0) return { fontWeight: "bold" };
    else return { fontWeight: "normal" };
  }
  render() {
    return (
      <table className="content-list border-all-main">
        <thead>
          <tr className="list-item-head flex-row flex-start w-100 bg-color-main">
            <td>Date</td>
            <td>Title</td>
            <td>Content</td>
            <td>Status</td>
          </tr>
        </thead>
        <tbody>
          {this.state.notifications.length > 0 ? (
            this.state.notifications.map((notification) => {
              return (
                <tr
                  style={this.setStyle(notification.status)}
                  key={notification.id}
                  className="list-item flex-row flex-start"
                  onClick={() => this.handleClick(notification)}
                >
                  <td className="text-left">
                    {commons.dateFormatWithTime(notification.time_created)}
                  </td>
                  <td className="text-left">{notification.title}</td>
                  <td className="text-left">{notification.message}</td>

                  <td className="text-left">
                    {notification.status == 0 ? "Unread" : "Read"}
                  </td>
                </tr>
              );
            })
          ) : (
            <tr>
              <td>No notifications</td>
            </tr>
          )}
        </tbody>
      </table>
    );
  }
}
export default NotificationList;
