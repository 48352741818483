import React from "react";
import Commons from "../utils/commons.js";
import Fetcher from "../utils/fetcher";
import FuelRequestClosing from "./fuel_request_closing";
import CustomDialog from "./custom_dialog";

const config = require("../utils/config.json");

class FuelRequestApproval extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isVisible: true,
      projects: props.projects,
      positions: props.positions,
      users: this.props.users,
      users: this.props.drivers,
      fcodes: props.fundcodes,
      filteredCodes: [],
      filteredUsers: this.props.users,
      approvable: undefined,
      processed: undefined,
      amount: props.request.amount,
      price: props.request.price,
      isEditable: undefined,
      request: props.request,
      lastRequest: null,
      toPrint: false,
      needsConfirmation: false,
      action: "",
      can_approve:
        props.request.status === 0 &&
        (props.user.id === props.request.approver ||
          props.user.position === 6 ||
          props.user.position === 3),
      can_authorize:
        props.request.status === 1 &&
        (props.user.id === props.request.admin ||
          props.user.position === 6 ||
          props.user.position === 3),
    };
    this.user = props.user;
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
    this.handleApprove = this.handleApprove.bind(this);
    this.handleAuthorize = this.handleAuthorize.bind(this);
    this.handleReject = this.handleReject.bind(this);
    this.handleRemark = this.handleRemark.bind(this);
    this.handleDiscard = this.handleDiscard.bind(this);
    this.requestConfirmation = this.requestConfirmation.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }
  handleChange = (e) => {
    let id = e.target.id;
    let value = e.target.value;
    if (id === "amount") {
      this.setState({ amount: value }, () => {
        console.log("amount: ", this.state.amount);
      });
    } else if (id === "price") {
      this.setState({ price: value });
    }
  };
  requestConfirmation = (action) => {
    var my_action;
    if (action === "reject") my_action = this.handleReject;
    else my_action = this.handleDiscard;
    this.setState({ needsConfirmation: true, action: my_action });
  };
  handleRemark = (event) => {
    let value = event.target.value;
    this.setState({ remark: value });
  };
  handleApprove = () => {
    let remarks = document.getElementById("remark").value;

    let req = this.props.request;

    let data = {
      requestId: req.id,
      requestType: req.type,
      remark: remarks,
      attender: this.props.user.id,
      status: 1,
      currentStatus: this.props.request.status,
      admin: req.admin,
      attenderName: this.props.user.fname + " " + this.props.user.lname,
      requestor: req.requestor,
      approver: req.approver,
      vehicle: req.vehicle,
    };
    this.updateRequest(data);
  };
  handleAuthorize = () => {
    let remarks = document.getElementById("remark1").value;

    let req = this.props.request;

    let data = {
      requestId: req.id,
      requestType: req.type,
      remark: remarks,
      attender: this.props.user.id,
      status: 2,
      admin: req.admin,
      currentStatus: this.props.request.status,
      attenderName: this.props.user.fname + " " + this.props.user.lname,
      requestor: req.requestor,
      approver: req.approver,
      vehicle: req.vehicle,
      amount: this.state.amount,
      price: this.state.price,
    };
    this.updateRequest(data);
  };
  handleReject = () => {
    let remarks = document.getElementById("remark").value;
    let req = this.props.request;

    let data = {
      requestId: req.id,
      requestType: req.type,
      remark: remarks,
      attender: this.props.user.id,
      status: 4,
      currentStatus: this.props.request.status,
      attenderName: this.props.user.fname + " " + this.props.user.lname,
      requestor: req.requestor,
      approver: req.approver,
      vehicle: req.vehicle,
    };
    this.updateRequest(data);
  };
  updateRequest = (data) => {
    fetch(config.base_url + "/request/" + data.requestId, {
      method: "put",
      headers: { "Content-type": "application/json" },
      body: JSON.stringify(data),
    })
      .then((res) => res.json())
      .then((result) => {
        console.log("result update: ", result);
        if (result.error) {
          this.props.onFeedback(false, result.error);
        } else {
          Fetcher.fetchRequests(this.props.user)
            .then((requests) => {
              // this.printOrder();
              this.props.onRefresh(requests);
              this.props.onFeedback(true, result.response);
            })
            .catch((error) => {
              this.props.onFeedback(false, result.error);
            });
        }
      })
      .catch((error) => {
        this.props.onFeedback(false, error.error);
      })
      .finally(() => {
        this.handleCancel();
      });
  };

  handleSearch(e) {
    e.persist();
    let value = e.target.value;
    let keyword = value.toLocaleLowerCase();
    let fusers = this.state.users;
    console.log("fusers: ", fusers);
    fusers = fusers.filter((u) => {
      return (
        u.fname.toLocaleLowerCase().search(keyword) !== -1 ||
        u.lname.toLocaleLowerCase().search(keyword) !== -1
        // || u.position.name.toLocaleLowerCase().search(keyword) !== -1
      );
    });
    this.setState({ filteredUsers: fusers, searchValue: keyword });
  }

  handleFundCodeChange() {}
  handleTimeChange(e) {
    let value = e.target.value;
    const from = document.getElementById("from");
    const to = document.getElementById("to");
    if (value === "Hours") {
      from.type = "time";
      to.type = "time";
    } else {
      from.type = "date";
      to.type = "date";
    }
  }

  componentDidMount() {
    
    let reqType = this.props.request.type;
    //get last approved request
    Fetcher.fetchLastRequest(this.props.request.vehicle, reqType)
      .then((result) => {
        if (result !== null || result.length > 0) {
          this.setState({ lastRequest: result[0] }, () => {
            console.log("last reqeust: ", this.state.lastRequest);
          });
        }
      })
      .catch((error) => {});

    let request = this.props.request;
    if (request !== undefined) {
      if (
        request.approver === this.user.id ||
        this.user.position === 3 ||
        request.admin === this.user.id ||
        this.user.position === 6
      ) {
        this.setState(
          {
            request: request,
            approvable: true,
            processed: request.status !== 0 ? true : false,
          },
          () => {
            this.setState({
              isEditable: this.state.approvable && !this.state.processed,
            });
          }
        );
      } else {
        this.setState({
          approvable: false,
          processed: request.status !== 0 ? true : false,
          isEditable: false,
        });
      }
    }
  }

  handleCancel() {
    console.log("cancelling user form");
    this.props.cancelButtonClick();
  }

  handleSubmit(e) {
    e.preventDefault();
  }

  handleDiscard = () => {
    let remarks = "Discarded requests";
    let req = this.props.request;
    let user = this.props.user;
    let data = {
      requestId: req.id,
      requestType: req.type,
      remark: remarks,
      closer: user.id,
      status: 5,
      requestor: req.requestor,
      approver: req.approver,
      vehicle: req.vehicle,
    };
    // console.log("Test: ", data);
    this.updateRequest(data);
  };
  render() {
    return this.props.request.status > 1 ? (
      <FuelRequestClosing
        suppliers={this.props.suppliers}
        user={this.props.user}
        request={this.props.request}
        onFeedback={(status, msg) => this.props.onFeedback(status, msg)}
        onRefresh={(reqs) => this.props.onRefresh(reqs)}
        cancelButtonClick={this.handleCancel}
        users={this.props.users}
        lastRequest={this.state.lastRequest}
      />
    ) : (
      <>
        <div className={"flex-row flex-center center-self "} id="fuel_order">
          <form
            name="fuel-request-form"
            id="fuel-request-form"
            className="center-self border-all-main"
            onSubmit={this.handleSubmit}
          >
            <h2 className="w-100 bg-color-main">Fuel Request Approval</h2>
            <div className="w-100 flex-row flex-start">
              <div className="form-group">
                <label htmlFor="date_requested" className=" text-color-accent">
                  Date Requested
                </label>
                <input
                  readOnly={!this.state.processed}
                  className="form-control "
                  type="text"
                  name="date_requested"
                  id="date_requested"
                  defaultValue={Commons.dateFormat(
                    this.props.request.date_created
                  )}
                />
              </div>
              <div className="form-group">
                <label htmlFor="from" className="form-label text-color-accent">
                  Date Required
                </label>

                <input
                  readOnly={!this.state.processed}
                  className="form-control "
                  type="text"
                  name="date_required"
                  id="date_required"
                  defaultValue={Commons.dateFormat(
                    this.props.request.start_time
                  )}
                />
              </div>
              <div className="form-group">
                <label
                  htmlFor="first_name"
                  className="form-label text-color-accent"
                >
                  Requestor's Name
                </label>

                <input
                  readOnly={true}
                  type="text"
                  className="form-control "
                  name="name"
                  id="name"
                  defaultValue={this.props.request.nameOfRequestor}
                />
              </div>
            </div>
            <div className="w-100 flex-row flex-start">
              <div className="form-group">
                <label
                  htmlFor="vehicle"
                  className="form-label text-color-accent"
                >
                  Vehicle
                </label>
                <input
                  readOnly={true}
                  className="form-control "
                  type="text"
                  name="vehicle"
                  id="vehicle"
                  placeholder="No vehicle info"
                  defaultValue={this.props.request.vehicle}
                />
              </div>
              <div className="form-group">
                <label
                  htmlFor="mileage"
                  className="form-label text-color-accent"
                >
                  Current Mileage
                </label>
                <input
                  readOnly={!this.state.processed}
                  className="form-control "
                  type="text"
                  name="mileage"
                  id="mileage"
                  placeholder="No mileage info"
                  defaultValue={this.props.request.mileage}
                />
              </div>

              <>
                <div className="form-group">
                  <label
                    htmlFor="lmileage"
                    className="form-label text-color-accent"
                  >
                    Last Refuel Mileage
                  </label>
                  <input
                    readOnly={true}
                    className="form-control "
                    type="text"
                    name="lmileage"
                    id="lmileage"
                    placeholder="No mileage info"
                    defaultValue={
                      this.state.lastRequest != null
                        ? this.state.lastRequest.mileage
                        : ""
                    }
                  />
                </div>
                <div className="form-group">
                  <label
                    htmlFor="distance"
                    className="form-label text-color-accent"
                  >
                    Distance Covered
                  </label>
                  <input
                    readOnly={true}
                    className="form-control "
                    type="text"
                    name="distance"
                    id="distance"
                    placeholder="No mileage info"
                    defaultValue={
                      this.state.lastRequest != null
                        ? this.props.request.mileage -
                          this.state.lastRequest.mileage
                        : ""
                    }
                  />
                </div>
              </>
            </div>
            <div className="flex-row flex-start w-100">
              <div className="form-group">
                <label
                  htmlFor="supplier"
                  className="form-label text-color-accent"
                >
                  Supplier
                </label>
                <input
                  readOnly={true}
                  className="form-control "
                  type="text"
                  name="supplier"
                  id="supplier"
                  defaultValue={this.state.request.supplier_name}
                />
              </div>
              <div className="form-group">
                <label
                  htmlFor="amount"
                  className="form-label text-color-accent"
                >
                  Fuel Quantity (Litres)
                </label>

                <input
                  readOnly={!this.state.can_authorize}
                  className="form-control "
                  type="text"
                  name="amount"
                  id="amount"
                  onChange={this.handleChange}
                  defaultValue={Commons.formatNumber(this.state.amount)}
                />
              </div>
              <div className="form-group">
                <label htmlFor="price" className="form-label text-color-accent">
                  Fuel Price (Tsh)
                </label>
                <input
                  readOnly={!this.state.can_authorize}
                  className="form-control "
                  type="text"
                  name="price"
                  id="price"
                  onChange={this.handleChange}
                  defaultValue={Commons.formatNumber(this.state.request.price)}
                />
              </div>
              <div className="form-group">
                <label htmlFor="cost" className="form-label text-color-accent">
                  Cost of Fuel (Tsh)
                </label>
                <input
                  readOnly={!this.state.processed}
                  className="form-control "
                  type="text"
                  name="cost"
                  id="cost"
                  value={Commons.formatNumber(
                    this.state.price * this.state.amount
                  )}
                />
              </div>
            </div>
            <div className="flex-row flex-start w-100">
              <div className="form-group">
                <label
                  htmlFor="project"
                  className="form-label text-color-accent"
                >
                  Project
                </label>
                <input
                  readOnly={!this.state.processed}
                  type="text"
                  className="form-control"
                  name="project"
                  id="project"
                  defaultValue={this.props.request.project}
                />
              </div>
              <div className="form-group">
                <label
                  htmlFor="fund_code"
                  className="form-label text-color-accent"
                >
                  Fund Code
                </label>
                <input
                  readOnly={!this.state.processed}
                  type="text"
                  id="fund_code"
                  className="form-control"
                  defaultValue={this.props.request.fundcode}
                />
              </div>
              <div className="form-group">
                <label
                  htmlFor="account"
                  className="form-label text-color-accent"
                >
                  Account
                </label>
                <input
                  className="form-control "
                  type="text"
                  name="account"
                  id="account"
                  defaultValue={this.props.request.account}
                />
              </div>
            </div>
            <div className="flex-row flex-start w-100">
              <div className="form-group">
                <label
                  htmlFor="source"
                  className="form-label text-color-accent"
                >
                  Source
                </label>
                <input
                  id="source"
                  name="source"
                  className="form-control"
                  defaultValue={this.props.request.source}
                />
              </div>
              <div className="form-group">
                <label
                  htmlFor="category"
                  className="form-label text-color-accent"
                >
                  Category
                </label>
                <input
                  id="category"
                  className="form-control"
                  name="category"
                  defaultValue={this.props.request.category}
                />
              </div>
              <div className="form-group">
                <label htmlFor="activity" className="text-color-accent">
                  Activities
                </label>
                <input
                  className="form-control cb-activities"
                  type="text"
                  id="activity"
                  defaultValue={this.props.request.activity}
                />
              </div>
            </div>
            <div className="form-group">
              <label htmlFor="remark" className="form-label text-color-accent">
                Comments
              </label>
              <input
                readOnly={this.props.user.id !== this.props.request.requestor}
                id="comment"
                name="comment"
                className="form-control"
                defaultValue={this.state.comment}
              />
            </div>

            <div className="form-group">
              <label htmlFor="remark" className="form-label text-color-accent">
                Approver's Remarks
              </label>
              <input
                readOnly={!this.state.can_approve}
                onChange={this.handleRemark}
                id="remark"
                name="remark"
                className="form-control"
                defaultValue={this.props.request.remark}
              />
            </div>
            <div className="form-group">
              <label htmlFor="remark1" className="form-label text-color-accent">
                Admin's Remarks
              </label>
              <input
                readOnly={!this.state.can_authorize}
                onChange={this.handleRemark}
                id="remark1"
                name="remark1"
                className="form-control"
                defaultValue={this.props.request.remark1}
              />
            </div>
            <div className="form-group">
              <label
                htmlFor="approver"
                className="form-label text-color-accent"
              >
                {this.props.request.status === 1
                  ? " Waiting for admin " +
                    this.props.request.nameOfAdmin +
                    " to authorize"
                  : this.props.request.status === 0
                  ? this.props.request.statusText +
                    " approval by " +
                    this.props.request.nameOfDefaultApprover
                  : this.props.request.status === 2
                  ? this.props.request.statusText +
                    " by " +
                    this.props.request.nameOfAttender
                  : this.props.request.status === 3
                  ? this.props.request.statusText +
                    " by " +
                    this.props.request.nameOfCloser
                  : this.props.request.status +
                    " by " +
                    this.props.request.nameOfAttender}
              </label>
            </div>
            <div className="form-group" id="button-area">
              <span className="flex-row flex-space">
                {this.state.can_approve || this.state.can_authorize ? (
                  <>
                    {this.state.can_approve ? (
                      <input
                        className="btn form-control btn-success"
                        type="button"
                        id="approve"
                        value="Approve"
                        onClick={this.handleApprove}
                      />
                    ) : (
                      <input
                        className="btn form-control btn-success"
                        type="button"
                        id="authorize"
                        value="Authorize"
                        onClick={this.handleAuthorize}
                      />
                    )}
                    <input
                      className="btn form-control btn-reject"
                      type="button"
                      id="reject"
                      value="Reject"
                      onClick={this.handleReject}
                    />
                  </>
                ) : null}
                {this.props.user.id == this.props.request.requestor &&
                this.props.request.status != 3 &&
                this.props.request.status != 5 ? (
                  <span
                    id="cancel-request"
                    className="btn-reject btn "
                    onClick={() => {
                      this.requestConfirmation("reject");
                    }}
                  >
                    DISCARD
                  </span>
                ) : null}
                <span
                  id="cancel-user"
                  className="btn text-color-accent"
                  onClick={this.handleCancel}
                >
                  CLOSE
                </span>
              </span>
            </div>
          </form>
        </div>
        {this.state.needsConfirmation ? (
          <CustomDialog
            title={"Confirm Request Discard"}
            onCancel={this.handleCancel}
            msg={"Are you sure you want to discard this request?"}
            onAction={this.state.action}
            buttonText={"DISCARD"}
          />
        ) : null}
      </>
    );
  }
}
export default FuelRequestApproval;
