import React from "react";
import Fetcher from "../utils/fetcher";
import Commons from "../utils/commons.js";
import CustomDialog from "./custom_dialog";
import { getCityNames } from "postcodes-tz";

const config = require("../utils/config.json");

class UserEditForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isVisible: true,
      username: "",
      usernameExists: false,
      usernameMsg: "",
      emp_id: undefined,
      location: undefined,
      fname: undefined,
      lname: undefined,
      email: undefined,
      password: undefined,
      position: undefined,
      phone:"",
      user: this.props.user,
      hasError: true,
      department: undefined,
      confirmDelete: false,
      confirmRestore: false,
      userLocation: undefined
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleDeleteUser = this.handleDeleteUser.bind(this);
    this.handleRestoreUser = this.handleRestoreUser.bind(this);
    this.restoreUser = this.restoreUser.bind(this);
    this.deleteUser = this.deleteUser.bind(this);
    this.restoreUser = this.restoreUser.bind(this);
    this.refresh = this.refresh.bind(this);
  }

  restoreUser = ()=>{
    let user = this.state.user;
    console.log("restoring user...", user.email);
    fetch(config.base_url + "/user/restore/" + user.id, {
      method: "PATCH",
      headers: { "Content-type": "application/json" },
    })
      .then((res) => res.json())
      .then((response) => {
        this.refresh(response.response);
      })
      .finally(()=>{
        this.handleCancel();
      })
    
  }
  deleteUser = () => {
    let user = this.state.user;
    fetch(config.base_url + "/user/" + user.id, {
      method: "DELETE",
      headers: { "Content-type": "application/json" },
    })
      .then((res) => res.json())
      .then((response) => {
        this.refresh(response.response);
      });
    this.handleCancel();
  };

  restoreUser = () => {
    let user = this.state.user;
    fetch(config.base_url + "/user/restore/" + user.id, {
      method: "PATCH",
      headers: { "Content-type": "application/json" },
    })
      .then((res) => res.json())
      .then((response) => {
        this.refresh(response.response);
      });
    this.handleCancel();
  };
  handleDeleteUser = () => {
    console.log("showing dialog...");
    this.setState({ confirmDelete: true });
  };
  handleRestoreUser = () => {
    this.setState({ confirmRestore: true });
  };
  handleChange = (event) => {
    event.preventDefault();
    let empError = false;
    let fnameError = false;
    let lnameError = false;
    let emailError = false;
    let phoneError = false;
    let passwordError = false;
    let newUser = this.state.user;
    let change = event.target;
    let value = change.value;
    switch (change.id) {
      case "department":
        let dept = this.props.departments.filter((d) => {
          return d.id === parseInt(value);
        })[0];
        this.setState({ department: dept.id, location: dept.region });
        break;
      case "employee_id":
        if (Commons.isWhiteSpaces(value) || value.length < 1) {
          change.classList.add("fail");
          empError = true;
        } else {
          change.classList.remove("fail");
          this.setState({ emp_id: value });
          empError = false;
        }

        break;
      case "position":
        this.setState({ position: parseInt(value) });
        break;
      case "first_name":
        if (Commons.isWhiteSpaces(value) || value.length < 1) {
          change.classList.add("fail");
          fnameError = true;
        } else {
          change.classList.remove("fail");
          this.setState({ fname: value });
          fnameError = false;
        }

        break;
      case "last_name":
        if (Commons.isWhiteSpaces(value) || value.length < 1) {
          change.classList.add("fail");
          lnameError = true;
        } else {
          change.classList.remove("fail");
          this.setState({ lname: value });
          lnameError = false;
        }

        break;
        case "phone":
          if (
            Commons.validatePhoneNumber(value)
          ) {
            change.classList.remove("fail");
            change.classList.add("success");
            this.setState({ phone: value });
            phoneError = false;
          } else {
            change.classList.add("fail");
            change.classList.remove("success");
            change.focus = true;
            phoneError = true;
          }
          break;
      case "email":
        if (
          Commons.validateEmail(value) &&
          value.length > 5 &&
          Commons.isEmailAvailable(this.props.users, value)
        ) {
          change.classList.remove("fail");
          change.classList.add("success");
          this.setState({ email: value });
          emailError = false;
        } else {
          change.classList.add("fail");
          change.classList.remove("success");
          change.focus = true;
          emailError = true;
        }
        break;
      case "location":
        this.setState({ userLocation: value });
        break;
      case "password":
        if (value.length >= 8 && !Commons.isWhiteSpaces(value)) {
          change.classList.remove("fail");
          change.classList.add("success");
          passwordError = false;
          this.setState({ password: value });
        } else {
          change.classList.add("fail");
          change.classList.remove("success");
          change.focus = true;
          passwordError = true;
        }
        break;
    }
    this.setState({
      user: newUser,
      hasError:
        empError || emailError || fnameError || lnameError || passwordError,
    });
  };

  handleCancel() {
    this.setState({ confirmDelete: false,confirmRestore:false });
    this.props.cancelButtonClick();
  }
  componentDidMount() {
    let depts = this.props.departments.filter((d) => {
      return d.id === this.props.user.department;
    });

    this.setState({
      department: depts[0].id,
      location: depts[0].region,
      user: this.props.user,
      userLocation: this.props.user.location,
    });
  }

  handleSubmit(e) {
    e.preventDefault();
    let u = this.props.user;

    let newUser = { id: u.id, busy: 0 };

    newUser.location = this.state.userLocation;
    if (this.state.emp_id !== u.emp_id && this.state.emp_id !== undefined)
      newUser.emp_id = this.state.emp_id;
    if (this.state.fname !== u.fname && this.state.fname !== undefined)
      newUser.fname = this.state.fname;
    if (this.state.lname !== u.lname && this.state.lname !== undefined)
      newUser.lname = this.state.lname;
    if (this.state.email !== u.email && this.state.email !== undefined)
      newUser.email = this.state.email;
    if (
      this.state.department !== u.department &&
      this.state.department !== undefined
    )
      newUser.department = this.state.department;

    if (
      this.state.position !== undefined &&
      this.state.position !== u.position.id
    )
    
      newUser.position = this.state.position;
    newUser.password = this.state.password;
    if (
      this.state.phone !== "" &&
      this.state.phone !== u.phone
    )
    newUser.phone = this.state.phone
    if (Object.keys(newUser).length > 1) {
      fetch(config.base_url + "/user/" + u.id, {
        method: "PATCH",
        body: JSON.stringify(newUser),
        headers: { "Content-Type": "application/json" },
      })
        .then((res) => res.json())
        .then((response) => {
          this.refresh(response.response);
        })
        .catch((error) => {
          this.props.onFeedback(false, error.error);
        });
    } else {
      this.handleCancel();
    }
  }
  refresh = (message) => {
    Fetcher.fetchUsers()
      .then((users) => {
        this.props.onRefresh(users);
        this.props.onFeedback(true, message);
      })
      .catch((error) => {
        this.props.onFeedback(
          true,
          "User saved successfully but could not refresh list"
        );
      })
      .finally(() => {
        this.handleCancel();
      });
  };
  render() {
    return (
      <div className="flex-row flex-center center-self">
        <form
          name="new-user-form"
          id="new-user-form"
          className="center-self border-all-main"
          onSubmit={this.handleSubmit}
        >
          <h2 className="w-100 bg-color-main">Update User Details</h2>

          <div className="w-100 flex-row flex-start">
            <div className="form-group">
              <label className="text-color-accent" htmlFor="reg_no">
                Employee ID
              </label>
              <input
                onChange={this.handleChange}
                className="form-control "
                type="text"
                name="employee_id"
                id="employee_id"
                placeholder="Enter employee Id"
                defaultValue={this.state.user.emp_id}
              />
            </div>
            <div className="form-group">
              <label htmlFor="position" className="text-color-accent">
                Position
              </label>
              <select
                onChange={this.handleChange}
                className="form-control "
                name="position"
                id="position"
                defaultValue={this.state.user.position}
              >
                {this.props.positions.map((p) => {
                  return (
                    <option key={p.id} value={p.id}>
                      {p.value}
                    </option>
                  );
                })}
              </select>
            </div>
          </div>
          <div className="flex-row flex-start w-100">
            <div className="form-group">
              <label
                htmlFor="first_name"
                className="form-label text-color-accent"
              >
                First Name
              </label>
              <input
                onChange={this.handleChange}
                className="form-control "
                type="text"
                name="first_name"
                id="first_name"
                placeholder="First Name"
                defaultValue={this.state.user.fname}
              />
            </div>
            <div className="form-group">
              <label
                htmlFor="last_name"
                className="form-label text-color-accent"
              >
                Last Name
              </label>
              <input
                onChange={this.handleChange}
                className="form-control "
                type="text"
                name="last_name"
                id="last_name"
                placeholder="Last Name"
                defaultValue={this.state.user.lname}
              />
            </div>
          </div>
          <div className="w-100 flex-row flex-start">
            <div className="form-group">
              <label className="text-color-accent" htmlFor="email">
                E-mail
              </label>
              <input
                onChange={this.handleChange}
                className="form-control "
                type="text"
                name="email"
                id="email"
                placeholder="Enter email address"
                defaultValue={this.state.user.email}
              />
            </div>
            <div className="form-group">
              <label
                htmlFor="department"
                className="form-label text-color-accent"
              >
                Department
              </label>
              <select
                onChange={this.handleChange}
                className="form-control "
                name="department"
                id="department"
                defaultValue={this.state.user.department}
              >
                {this.props.departments.map((d) => {
                  return (
                    <option key={d.id} value={d.id}>
                      {d.description + " (" + d.code + ")"}
                    </option>
                  );
                })}
              </select>
            </div>
            <div className="form-group">
              <label
                htmlFor="location"
                className="form-label text-color-accent"
              >
                Current Location
              </label>
              <select
                onChange={this.handleChange}
                className="form-control "
                name="location"
                id="location"
                defaultValue={this.state.user.location.trim()}
              >
                {getCityNames().map((c) => {
                  return <option key={c}>{c}</option>;
                })}
              </select>
            </div>
          </div>

          <div className="flex-row flex-start w-100">
            <div className="form-group">
              <label
                htmlFor="username"
                className="form-label text-color-accent"
              >
                Phone
              </label>
              <input
              onChange={this.handleChange}
                className="form-control "
                type="text"
                name="phone"
                id="phone"
                placeholder="Enter a phone"
                defaultValue={this.state.user.phone}
              />
            </div>

            <div className="form-group">
              <label
                htmlFor="password"
                className="form-label text-color-accent"
              >
                Password
              </label>
              <input
                onChange={this.handleChange}
                className="form-control "
                type="password"
                name="password"
                id="password"
                placeholder="Enter a password"
              />
            </div>
          </div>
          <div className="flex-row flex-start error-text">
            {this.state.usernameExists ? this.state.usernameMsg : null}
          </div>

          <div className="form-group">
            <span className="flex-row flex-space">
              <input
                disabled={this.state.hasError}
                className={
                  "btn form-control " +
                  (this.state.hasError ? " hidden" : " bg-color-accent")
                }
                type="submit"
                id="submitUser"
                value="Update"
              />
              {this.props.loggedInUser.position === 6 && this.state.user.busy != 3 ? (
                <span
                  onClick={this.handleDeleteUser}
                  className={"btn btn-reject form-control "}
                  type="button"
                  id="deleteUser"
                >
                  Delete
                </span>
              ) : null}
              {this.props.loggedInUser.position === 6 && this.state.user.busy == 3 ? (
                <span
                  onClick={this.handleRestoreUser}
                  className={"btn btn-success form-control "}
                  type="button"
                  id="restoreUser"
                >
                  Restore
                </span>
              ) : null}
              <span
                id="cancel-user"
                className="btn text-color-accent"
                onClick={this.handleCancel}
              >
                CANCEL
              </span>
            </span>
          </div>
        </form>

        {this.state.confirmRestore ? (
          <CustomDialog
            title="Restore User"
            msg="Are you sure you want to restore this user?"
            buttonText="Restore"
            onCancel={this.handleCancel}
            onAction={this.restoreUser}
          />
        ) : null}
        {this.state.confirmDelete ? (
          <CustomDialog
            title="Delete User"
            msg="Are you sure you want to delete this user?"
            buttonText="Delete"
            onCancel={this.handleCancel}
            onAction={this.deleteUser}
          />
        ) : null}
      </div>
    );
  }
}
export default UserEditForm;
