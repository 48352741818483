import React from "react";
import DepartmentForm from "./department_form";
import DepartmentDeleteForm from "./department_delete_form";
import DepartmentEditForm from "./department_edit_form";
import FundcodeForm from "./fundcode_form";
import FundcodeEditForm from "./fundcode_edit_form";
import FundcodeDeleteForm from "./fundcode_delete_form";
import SupplierForm from "./supplier_form";
import ProjectForm from "./project_form";
import PasswordForm from "./password_form";
import ProjectEditForm from "./project_edit_form";
import ProjectDeleteForm from "./project_delete_form";
import SupplierEditForm from "./supplier_edit_form";
import SupplierDeleteForm from "./supplier_delete_form";

class Section extends React.Component {
  constructor(props) {
    super(props);
    this.handleLinkClick = this.handleLinkClick.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
    this.loadData = this.loadData.bind(this);
    this.updateProjects = this.updateProjects.bind(this);
    this.state = {
      linkClicked: false,
      projects: [],
      fundcodes: [],
      departments: [],
      suppliers: [],
      admins: this.props.admins,
      coordinators: this.props.coordinators,
      users: [],
      action: "Add",
    };
  }
  loadData() {
    console.log("adm: ",this.props.admins)
    let user = this.props.user;
    if (user.position === 6) {
      this.setState({
        suppliers: this.props.suppliers,
        projects: this.props.projects,
        departments: this.props.departments,
        fundcodes: this.props.fundcodes,
      });
    //  this.setState({admins:this.props.admins})
      let coordinators = this.props.coordinators

      this.setState({ coordinators: coordinators });
    } else {
      let dept = this.props.departments.filter((d) => {
        return d.admin == user.id;
      });
      let suppliers = [];
      // let projects = [];
      let fundcodes = [];
      dept.forEach((d) => {
        this.props.suppliers.forEach((s) => {
          if (s.region === d.region) {
            suppliers.push(s);
          }
        });
        this.props.fundcodes.forEach((fc) => {
          if (fc.department == d.id) {
            fundcodes.push(fc);
          }
        });
      });
      let coordinators = this.props.coordinators.filter((u) => {
        return u.department === user.department;
      });
      // let admins = this.props.admins.filter((u) => {
      //   return u.department === user.department;
      // });
      this.setState({
        departments: dept,
        // admins: admins,
        coordinators: coordinators,
        suppliers: suppliers,
        fundcodes: fundcodes,
        projects: [],
      });
      // if (dept.length > 0) {
      //   let projects = this.props.projects.filter(p => {
      //     return p.coordinator == user.id || user.department == dept[0].id;
      //   });
      //   this.setState({ projects: projects });
      // }
    }
  }
  componentDidMount() {
    this.loadData();
    this.setState({ linkClicked: false,admins:this.props.admins });
  }

  handleCancel = () => {
    this.setState({ linkClicked: false });
  };
  handleLinkClick = (a) => {
    this.setState({ linkClicked: true, action: a });
  };
  updateProjects = (data) => {
    this.setState({ projects: data }, () => {
      this.props.onRefresh(data);
    });
  };
  render() {
    return this.state.linkClicked &&
      this.props.title.toLowerCase() === "program offices" ? (
      this.state.action === "Add" ? (
        <DepartmentForm
          onCancel={this.handleCancel}
          onFeedback={(status, msg) => this.props.onFeedback(status, msg)}
          admins={this.props.admins}
          coordinators={this.state.coordinators}
        />
      ) : this.state.action === "Edit" ? (
        <DepartmentEditForm
          onCancel={this.handleCancel}
          onFeedback={(status, msg) => this.props.onFeedback(status, msg)}
          admins={this.state.admins}
          departments={this.state.departments}
          coordinators={this.state.coordinators}
        />
      ) : (
        <DepartmentDeleteForm
          onCancel={this.handleCancel}
          onFeedback={(status, msg) => this.props.onFeedback(status, msg)}
          admins={this.state.admins}
          departments={this.state.departments}
        />
      )
    ) : this.state.linkClicked &&
      this.props.title.toLowerCase() === "fund codes" ? (
      this.state.action === "Add" ? (
        <FundcodeForm
          projects={this.state.projects}
          onCancel={this.handleCancel}
          onFeedback={(status, msg) => this.props.onFeedback(status, msg)}
          onRefresh={(data) => this.props.onRefresh(data)}
        />
      ) : this.state.action === "Edit" ? (
        <FundcodeEditForm
          projects={this.state.projects}
          fundcodes={this.state.fundcodes}
          onCancel={this.handleCancel}
          onFeedback={(status, msg) => this.props.onFeedback(status, msg)}
          onRefresh={(data) => this.props.onRefresh(data)}
        />
      ) : (
        <FundcodeDeleteForm
          projects={this.state.projects}
          fundcodes={this.state.fundcodes}
          onCancel={this.handleCancel}
          onFeedback={(status, msg) => this.props.onFeedback(status, msg)}
          onRefresh={(data) => this.props.onRefresh(data)}
        />
      )
    ) : this.state.linkClicked &&
      this.props.title.toLowerCase() === "suppliers" ? (
      this.state.action === "Add" ? (
        <SupplierForm
          user={this.props.user}
          departments={this.state.departments}
          onCancel={this.handleCancel}
          onFeedback={(status, msg) => this.props.onFeedback(status, msg)}
          onRefresh={(data) => this.props.onRefresh(data)}
        />
      ) : this.state.action === "Edit" ? (
        <SupplierEditForm
          suppliers={this.state.suppliers}
          onCancel={this.handleCancel}
          onFeedback={(s, m) => this.props.onFeedback(s, m)}
          onRefresh={(data) => this.props.onRefresh(data)}
        />
      ) : (
        <SupplierDeleteForm
          suppliers={this.state.suppliers}
          onCancel={this.handleCancel}
          onFeedback={(s, m) => this.props.onFeedback(s, m)}
          onRefresh={(data) => this.props.onRefresh(data)}
        />
      )
    ) : this.state.linkClicked &&
      this.props.title.toLowerCase() === "projects" ? (
      this.state.action === "Add" ? (
        <ProjectForm
          onCancel={this.handleCancel}
          onFeedback={(status, msg) => this.props.onFeedback(status, msg)}
          coordinators={this.state.coordinators}
          onRefresh={(data) => {this.setState({projects:data},()=>{
            console.log("proj: ",this.state.projects);
            this.props.onRefresh(data);
          })}}
          departments={this.state.departments}
        />
      ) : this.state.action === "Edit" ? (
        <ProjectEditForm
          coordinators={this.state.coordinators}
          projects={this.state.projects}
          onCancel={this.handleCancel}
          onFeedback={(status, msg) => this.props.onFeedback(status, msg)}
          onRefresh={(data) => this.updateProjects(data)}
          departments={this.state.departments}
        />
      ) : (
        <ProjectDeleteForm
          projects={this.state.projects}
          onCancel={this.handleCancel}
          onFeedback={(status, msg) => this.props.onFeedback(status, msg)}
          onRefresh={(data) => this.props.onRefresh(data)}
        />
      )
    ) : this.state.linkClicked &&
      this.props.title.toLowerCase() === "account" ? (
      <PasswordForm
        user={this.props.user}
        onCancel={this.handleCancel}
        onRefresh={(u) => this.props.onRefreshUser(u)}
        onFeedback={(status, msg) => this.props.onFeedback(status, msg)}
      />
    ) : (
      <div className="setting-section w-25 border-all-main">
        <h4 className="bg-color-main">{this.props.title}</h4>
        {this.props.actions.map((a) => {
          return (
            <p key={a}>
              <span
                id={"ln-" + a}
                className="btn text-color-accent"
                onClick={() => this.handleLinkClick(a)}
              >
                {a}
              </span>
            </p>
          );
        })}
      </div>
    );
  }
}
export default Section;
