import React from "react";
import Fetcher from "../utils/fetcher";
const config = require("../utils/config.json");

class UserForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isVisible: true,
      username: "",
      usernameExists: false,
      usernameMsg: "",
      selectedDepartment: null
    };
    this.handleDepartmentChange = this.handleDepartmentChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
    this.getPositionValue = this.getPositionValue.bind(this);
    this.searchUsername = this.searchUsername.bind(this);
    this.getUserDetails = this.getUserDetail.bind(this);
  }
  searchUsername = e => {
    e.persist();
    let keyword = e.target.value.toLocaleLowerCase();
    let fUsers = this.props.users;
    fUsers = fUsers.filter(u => {
      return u.email.toLocaleLowerCase().search(keyword) !== -1;
    });
    if (fUsers.length > 0) {
      this.setState({
        usernameExists: true,
        usernameMsg: "User already exists"
      });
    } else {
      this.setState({ usernameExists: false, usernameMsg: "" });
    }
  };

  handleCancel() {
    console.log("cancelling user form");
    this.props.cancelButtonClick();
  }
  componentDidMount() {
    console.log("my users: ",this.props.users);
    let depts = this.props.departments;
    this.setState({ selectedDepartment: depts[0] });
  }
  handleDepartmentChange(event) {
    let id = parseInt(event.target.value);
    let dept = this.props.departments.filter(d => {
      return d.id === id;
    })[0];
    this.setState({ selectedDepartment: dept });
  }
  getPositionValue = id => {
    let position = this.props.positions.filter(p => {
      return p.id === id;
    });
    return position.length > 0
      ? position[0]
      : { id: id, value: "Unknown", positions: "" };
  };
  getUserDetail = (email)=>{
    console.log("email: ",email);
  }
  handleSubmit(e) {
    e.preventDefault();
    let inputs = Array.from(e.target.elements);
    let user = {
      location: this.state.selectedDepartment.region,
      empId: inputs[0].value,
      fname: inputs[2].value,
      lname: inputs[3].value,
      phone: inputs[6].value,
      password: inputs[7].value,
      email: inputs[4].value,
      department: inputs[5].value,
      position: inputs[1].value
    };

    fetch(config.base_url + "/user", {
      method: "post",
      body: JSON.stringify(user),
      headers: { "Content-Type": "application/json" }
    })
      .then(res => res.json())
      .then(response => {
        this.setState({ users: response }, () => {
          this.handleCancel();
          Fetcher.fetchUsers()
            .then(users => {
              users = users.map(u => {
                let user = u;
                user.position = this.getPositionValue(u.position);
                return user;
              });
              this.props.onRefresh(users);
              this.props.onFeedback(true, "User saved successfully");
            })
            .catch(error => {
              this.props.onFeedback(
                true,
                "User saved successfully but could not refresh list"
              );
            });
        });
      })
      .catch(error => {
        this.props.onFeedback(false, error.error);
      });
  }
  render() {
    return (
      <div
        className={
          "flex-row flex-center center-self" +
          (!this.state.isVisible ? "hidden" : "")
        }
      >
        <form
          name="new-user-form"
          id="new-user-form"
          className="center-self border-all-main"
          onSubmit={this.handleSubmit}
        >
          <h2 className="w-100 bg-color-main">New User</h2>

          <div className="w-100 flex-row flex-start">
            <div className="form-group">
              <label className="text-color-accent" htmlFor="reg_no">
                Employee ID
              </label>
              <input
                className="form-control "
                type="text"
                name="employee_id"
                id="employee_id"
                placeholder="Enter employee Id"
              />
            </div>
            <div className="form-group">
              <label htmlFor="position" className="text-color-accent">
                Position
              </label>
              <select
                onChange={this.handlePositionChange}
                className="form-control "
                name="position"
                id="position"
              >
                {this.props.positions.map(p => {
                  return (
                    <option key={p.id} value={p.id}>
                      {p.value}
                    </option>
                  );
                })}
              </select>
            </div>
          </div>
          <div className="flex-row flex-start w-100">
            <div className="form-group">
              <label
                htmlFor="first_name"
                className="form-label text-color-accent"
              >
                First Name
              </label>
              <input
                className="form-control "
                type="text"
                name="first_name"
                id="first_name"
                placeholder="First Name"
              />
            </div>
            <div className="form-group">
              <label
                htmlFor="last_name"
                className="form-label text-color-accent"
              >
                Last Name
              </label>
              <input
                className="form-control "
                type="text"
                name="last_name"
                id="last_name"
                placeholder="Last Name"
              />
            </div>
          </div>
          <div className="w-100 flex-row flex-start">
            <div className="form-group">
              <label className="text-color-accent" htmlFor="email">
                E-mail
              </label>
              <input
                className="form-control "
                type="text"
                name="email"
                id="email"
                onChange={this.searchUsername}
                placeholder="Enter email address"
              />
                <div className="flex-row flex-space error-text">
            {this.state.usernameExists ? this.state.usernameMsg: null}
          </div>
            </div>
            <div className="form-group">
              <label
                htmlFor="department"
                className="form-label text-color-accent"
              >
                Department
              </label>
              <select
                onChange={this.handleDepartmentChange}
                className="form-control "
                name="department"
                id="department"
              >
                {this.props.departments.map(d => {
                  return (
                    <option key={d.id} value={d.id}>
                      {d.description + "(" + d.code + ")"}
                    </option>
                  );
                })}
              </select>
            </div>
          </div>

          <div className="flex-row flex-start w-100">
            <div className="form-group">
              <label
                htmlFor="username"
                className="form-label text-color-accent"
              >
                Phone 
              </label>
              <input
                className="form-control "
                type="text"
                name="phone"
                id="phone"
                placeholder="Enter a phone number"
              />
            </div>

            <div className="form-group">
              <label
                htmlFor="password"
                className="form-label text-color-accent"
              >
                Password
              </label>
              <input
                className="form-control "
                type="password"
                name="password"
                id="password"
                placeholder="Enter a password"
              />
            </div>
          </div>
        

          <div className="form-group">
            <span className="flex-row flex-space">
              {this.state.usernameExists ? null :
              <input
                className="btn form-control bg-color-accent"
                type="submit"
                id="submitUser"
                value="Save"
              />}
              <span
                id="cancel-user"
                className="btn text-color-accent"
                onClick={this.handleCancel}
              >
                CANCEL
              </span>
            </span>
          </div>
        </form>
      </div>
    );
  }
}
export default UserForm;
