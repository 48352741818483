import React, { useState } from "react";
import axios from "axios";
import SMSListReport from "./SMS_list_report";
const config = require("../utils/config.json");
const SMSReportGenerator = (props)=> {
    const [reportFormat,setReportFormat] = useState("list");
    const [report,setReport] =useState([]);
    const [dateRange,setDateRange] = useState({start: Date.now()/1000,end:Date.now()/1000});
    const [hasNext,setHasNext]=useState(false);
    const [hasPrevious,setHasPrevious] = useState(false);
    const [total,setTotal]= useState(0);
    const [currentPage,setCurrentPage] = useState(0);
    const [totalPages,setTotalPages] = useState(0);
    const [reportType,setReportType] =useState("sms");
    const [pageSize,setPageSize] = useState(25);
    const [period,setPeriod]  = useState("")
    
  const handleComplete=()=>{
    props.onComplete(
      reportFormat,
      dateRange,
      report,
      "SMS Report",
      "",
      reportType
    );

    props.onCancel("report");
  }

  const handleReportFormatChange=(e)=>{
    let format = e.target.value;
    setReportFormat(format)
  }

  const generateReport = async (curPage,take) => {
    const startDateInput = document.getElementById("sdate")
    const endDateInput = document.getElementById("edate")
    const reportFormatInput = document.getElementById("report_format");
    let startDate = startDateInput ? new Date(startDateInput.value) : new Date();
    let endDate = endDateInput ? new Date(endDateInput.value) : new Date();
    
    let dateRange = {
      start: startDate.getTime() / 1000,
      end: endDate.getTime() / 1000,
    };
    let reportFormat = reportFormatInput ? reportFormatInput.value : "list";
    setDateRange(dateRange)
    setReportFormat(reportFormat);
    let options = {
      page:curPage,take:take,startDate:startDate.getTime() / 1000,endDate:endDate.getTime() / 1000
    };
    console.log("🚀 ~ SMSReportGenerator ~ generateReport= ~ options:", options)
   
  
      let rept = props.type + "_report";
      try{
      const response = await axios.get(`${config.base_url}/${rept}?page=${options.page}&take=${options.take}&startDate=${options.startDate}&endDate=${options.endDate}`, {
        headers: { "Content-type": "application/json" },
      })
      const result = response.data;
         console.log("🚀 ~ SMSReportGenerator ~ generateReport= ~ result:", result)
         setReport(result.details.data)
         setHasNext(result.details.hasNext);
         setHasPrevious(result.details.hasPrevious)
         setDateRange(result.period);
         setTotal(result.totalCost)
         setCurrentPage(result.details.currentPage);
         setTotalPages(result.details.totalPages);
         setPeriod(result.period)
    }
    catch(e){
      console.log("🚀 ~ SMSReportGenerator ~ generateReport= ~ e:", e)
      props.onFeedback(false, "Something went wrong");
    }
  };
  const showListView = ()=>{

  }
  const handleSubmit=(e) =>{
    e.preventDefault();
  }
  const handlePageChange =(page)=>{
    // console.log("🚀 ~ SMSReportGenerator ~ handlePageChange ~ page:", page)
    generateReport(page,pageSize)
  }

    return (
      <div className="center-self">
        <form
          name="fuel-report-form"
          id="fuel-report-form"
          className="center-self border-all-main"
          onSubmit={handleSubmit}
        >
          <h2 className="w-100 bg-color-main">
            {props.type.substr(0, 1).toUpperCase() +
              props.type.substr(1) +
              " Report"}
          </h2>
          <div className="flex-row flex-start">
            <div className="form-group">
              <label htmlFor="sdate" className="form-label text-color-accent">
                Start Date
              </label>
              <input
                className="form-control "
                type="date"
                name="sdate"
                id="sdate"
              />
            </div>
            <div className="form-group">
              <label htmlFor="edate" className="form-label text-color-accent">
                End Date
              </label>
              <input
                className="form-control "
                type="date"
                name="edate"
                id="edate"
              />
            </div>
            <div className="form-group">
              <label
                htmlFor="report_format"
                className="form-label text-color-accent"
              >
                Select Report Type
              </label>
              <select
                defaultValue="list"
                id="report_format"
                name="report_format"
                className="form-control"
                onChange={handleReportFormatChange}
              >
                <option value="list">List</option>
                <option value="pie">Pie Chart</option>
                <option value="column">Histogram</option>
                <option value="line">Line Graph</option>
              </select>
            </div>
          
          </div>
      
          <div className="form-group">
            <span className="flex-row flex-space">
              <input
                className="btn bg-color-accent"
                type="button"
                id="btnGenerate"
                value="Generate Report"
                onClick={()=>generateReport(1,pageSize)}
              />
              <span
                id="cancel"
                className="btn text-color-accent"
                onClick={() => props.onCancel("buttons")}
              >
                CANCEL
              </span>
            </span>
          </div>
        </form>
        {report ?<SMSListReport onPageChange={(page)=>handlePageChange(page)} hasNext={hasNext} hasPrevious={hasPrevious} currentPage={currentPage} totalPages={totalPages}
        type="SMS" report={report} totalCost={total} dateRange={period} cancelButtonClick={() => props.onCancel("buttons")} reportType="list"
        />:null}
      </div>
    );
  }
export default SMSReportGenerator;
