import React from "react";
import CustomDialog from "./custom_dialog";
const config = require("../utils/config.json");

class DepartmentDeleteForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      description: "",
      complete: false,
      response: ""
    };

    this.handleCancel = this.handleCancel.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  handleCancel = e => {
    // e.preventDefault();
    this.props.onCancel();
  };
  componentDidMount = () => {
    this.setState({ description: this.props.departments[0].description });
  };
  handleChange = e => {
    let id = parseInt(e.target.value);

    let depts = this.props.departments.filter(d => {
      return d.id === id;
    });

    if (depts.length > 0)
      this.setState({ description: depts[0].description }, () => {});
  };
  handleSubmit = e => {
    e.preventDefault();
    let deptId = document.getElementById("department_id").value;

    fetch(config.base_url + "/department/" + deptId, {
      method: "delete",
      headers: { "Content-type": "application/json" }
    })
      .then(res => res.json())
      .then(result => {
        let error = result.error;
        if (error) this.setState({ complete: true, response: error });
        else this.setState({ complete: true, response: result.response });
      })
      .catch(err => {
        this.setState({ complete: true, response: err });
      });
  };

  render() {
    return (
      <>
        <div className="overlay w-100 flex-row flex-center flex-middle">
          <form
            className="bg-color-white-dark w-50"
            onSubmit={this.handleSubmit}
          >
            <h2>Delete Program Office</h2>
            <div className="form-group">
              <label htmlFor="department_id">Select Department ID</label>

              <select
                onChange={this.handleChange}
                className="form-control"
                id="department_id"
                name="department_id"
              >
                {this.props.departments.map(d => {
                  return (
                    <option key={d.id} value={d.id}>
                      {d.code}
                    </option>
                  );
                })}
              </select>
            </div>
            <div className="form-group">
              <label htmlFor="description">{this.state.description}</label>
            </div>

            <div className="form-group">
              <span className="flex-row flex-space">
                <input
                  className="btn form-control btn-error"
                  type="submit"
                  id="btndelete"
                  value="Delete"
                />
                <span
                  id="cancel"
                  className="btn text-color-accent"
                  onClick={this.handleCancel}
                >
                  CANCEL
                </span>
              </span>
            </div>
          </form>
        </div>
        {this.state.complete ? (
          <CustomDialog
            title={"Delete Regional Office"}
            onCancel={this.handleCancel}
            msg={this.state.response}
          />
        ) : null}
      </>
    );
  }
}
export default DepartmentDeleteForm;
