const { default: axios } = require("axios");
const Config = require("./config.json");

class Fetcher {
  getActiveUsers = async ()=>{
    const response = await axios.get(`${Config.base_url}/active-users`);
    if(response.status == 200) return response.data;
    return []
  }
  getDrivers = async ()=>{
    const response = await axios.get(`${Config.base_url}/drivers`);
    if(response.status == 200) return response.data;
    return []
  }
  getCoordinators = async ()=>{
    const response = await axios.get(`${Config.base_url}/coordinators`);
    if(response.status == 200) return response.data;
    return []
  }
clearNotification  = (notification_id) => {
  return new Promise((resolve, reject) => {
    fetch(Config.base_url + "/notifications/" + notification_id, {
      method: "post",
      headers: { "Content-Type": "application/json" },
    })
      .then((result) => result.json())
      .then((res) => {
        resolve(res.success);
      })
      .catch((error) => {
        reject(error.error);
      });
  });
}
fetchDepartments = () => {
  return new Promise((resolve, reject) => {
    fetch(Config.base_url + "/departments", {
      method: "get",
      headers: { "Content-type": "application/json" },
    })
      .then((res) => res.json())
      .then((result) => {
        resolve(result.departments);
      })
      .catch((error) => {
        reject(error);
      });
  });
}
fetchSuppliers = () => {
  return new Promise((resolve, reject) => {
    fetch(Config.base_url + "/suppliers", {
      method: "get",
      headers: { "Content-type": "application/json" },
    })
      .then((res) => res.json())
      .then((result) => {
        resolve(result.suppliers);
      })
      .catch((error) => {
        reject(error);
      });
  });
}
fetchFundcodesWithDepartment = () => {
  return new Promise((resolve, reject) => {
    fetch(Config.base_url + "/fundcodesdept", {
      method: "get",
      headers: { "Content-type": "application/json" },
    })
      .then((res) => res.json())
      .then((result) => {
        resolve(result.fundcodes);
      })
      .catch((error) => {
        console.log("error: ", error);
        reject(error);
      });
  });
}
fetchFundcodes = (tag) => {
  return new Promise((resolve, reject) => {
    fetch(Config.base_url + "/fundcodes", {
      method: "get",
      headers: { "Content-type": "application/json" },
    })
      .then((res) => res.json())
      .then((response) => {
        if (tag == "active") {
          let today = new Date();
          let seconds = today.getTime() / 1000;
          let fundcodes = response.fundcodes.filter((f) => {
            return f.expiration > seconds;
          });
          resolve(fundcodes);
        }
        resolve(response.fundcodes);
      })
      .catch((err) => {
        reject(err);
      });
  });
}
fetchProjects  = () => {
  return new Promise((resolve, reject) => {
    fetch(Config.base_url + "/projects", {
      method: "get",
      headers: { "Content-type": "application/json" },
    })
      .then((res) => res.json())
      .then((response) => {
        resolve(response.projects);
      })
      .catch((err) => {
        reject(err);
      });
  });
}
fetchUsers = async (options) => {
  const page = options && options.page ? options.page: 1;
  const take = options && options.take ? options.take : 20;
try{
  const result = await axios.get(`${Config.base_url}/users?page=${page}&take=${take}`,{headers: { "Content-type": "application/json" }});
  return result.data;
}
catch(error){
  return error;
}
  // return new Promise((resolve, reject) => {
  //   fetch(Config.base_url + "/users", {
  //     method: "get",
  //     headers: { "Content-type": "application/json" },
  //   })
  //     .then((result) => result.json())
  //     .then((response) => {
  //       resolve(response.users);
  //     })
  //     .catch((error) => {
  //       reject(error);
  //     });
  // });
}
fetchPositions = () => {
  return new Promise((resolve, reject) => {
    fetch(Config.base_url + "/positions", {
      method: "get",
      headers: { "Content-type": "application/json" },
    })
      .then((result) => result.json())
      .then((positions) => {
        resolve(positions.result);
      })
      .catch((error) => {
        reject(error);
      });
  });
}
getActions = (user_position) => {
  return new Promise((resolve, reject) => {
    fetch(Config.base_url + "/actions/" + user_position, {
      method: "GET",
      headers: { "Content-type": "application/json" },
    })
      .then((result) => result.json())
      .then((res) => {
        let result = res.result;
        const buttonList = result.map((r) => {
          let btn = {
            id: r.text.toLowerCase(),
            icon: r.icon,
            text: r.text,
          };
          return btn;
        });
        resolve(buttonList);
      })
      .catch((err) => {
        reject(err);
      });
  });
}
fetchRequests = async (user,options) => {
  const page = options && options.page ? options.page: 1;
  const take = options && options.take ? options.take : 25;
  try{
    let result = await axios.get(`${Config.base_url}/requests?page=${page}&take=${take}`,{headers: { "Content-type": "application/json" }});
    let requests = user && user.position != 6 ? result.data.requests.filter(r=>r.requestor === user.id ||r.approver === user.id ||user.position === 3 ||r.admin === user.id):result.data.requests;
    result.data.requests = requests.map(r=>{
      let req = r;
                switch (r.type) {
                  case 0:
                    req.typeText = "Vehicle Request";
                    break;
                  case 1:
                    req.typeText = "Fuel Request";
                    break;
                  case 2:
                  case 3:
                  case 4:
                    req.typeText = "Maintenance Request";
                    break;
                }
                return req;
    });
    return result.data;
  }
  catch(error){
    return error;
  }
}
fetchVehicles = () => {
  return new Promise((resolve, reject) => {
    fetch(Config.base_url + "/vehicles", {
      method: "get",
      headers: { "Content-type": "application/json" },
    })
      .then((res) => res.json())
      .then((response) => {
        resolve(response.vehicles);
      })
      .catch((error) => {
        reject("Could not retrieve vehicles");
      });
  });
}

//mileage report
fetchMileageReport = (options) => {
  return new Promise((resolve, reject) => {
    fetch(Config.base_url + "/mileage_report", {
      method: "POST",
      headers: { "Content-type": "application/json" },
      body: JSON.stringify(options),
    })
      .then((res) => res.json())
      .then((result) => {
        resolve(result.report);
      })
      .catch((error) => {
        reject("Could not find information to generate this report");
      });
  });
}
fetchVehicleHistory = (vehicle, start_date) => {
  // console.log("FetchHistory: ", start_date);
  return new Promise((resolve, reject) => {
    fetch(Config.base_url + "/history/" + vehicle + "?date=" + start_date)
      .then((result) => result.json())
      .then((result) => {
        resolve(result.history);
      })
      .catch((error) => {
        reject(error.error);
      });
  });
}
fetchRequest = (id, type) => {
  return new Promise((resolve, reject) => {
    fetch(Config.base_url + "/request/" + id + "/" + type, {
      method: "get",
      headers: { "Content-type": "application/json" },
    })
      .then((res) => res.json())
      .then((result) => {
        resolve(result.request);
      })
      .catch((error) => {
        reject(error.error);
      });
  });
}
fetchRequestStata = () => {
  return new Promise((resolve, reject) => {
    fetch(Config.base_url + "/status", {
      method: "get",
      headers: { "Content-type": "application/json" },
    })
      .then((res) => res.json())
      .then((result) => {
        resolve(result.stata);
      })
      .catch((error) => {
        reject("Could not obtain project status");
      });
  });
}

fetchTrips = (user) => {
  return new Promise((resolve, reject) => {
    fetch(Config.base_url + "/trips", {
      method: "get",
      headers: { "Content-type": "application/json" },
    })
      .then((res) => res.json())
      .then((result) => {
        if (user.id !== 1) {
          let trips = result.trips.filter((t) => {
            return (
              t.driver === user.id ||
              t.requestor === user.id ||
              t.approver === user.id
            );
          });
          resolve(trips);
        } else resolve(result.trips);
      })
      .catch((error) => {
        reject("Could not get trips");
      });
  });
}

//fetch single user
fetchUserWithId = (userid) => {
  return new Promise((resolve, reject) => {
    fetch(Config.base_url + "/user/" + userid, {
      method: "get",
      headers: { "Content-type": "application/json" },
    })
      .then((res) => res.json())
      .then((result) => {
        resolve(result.user);
      })
      .catch((error) => {
        console.error("fetchUserWithId(): ", error);
        reject("Unable to retrieve user details");
      });
  });
}
//get performance
fetchPerformanceData = (options) => {
  return new Promise((resolve, reject) => {
    fetch(Config.base_url + "/combine_report", {
      method: "post",
      headers: { "Content-type": "application/json" },
      body: JSON.stringify(options),
    })
      .then((res) => res.json())
      .then((result) => {
        resolve(result.report);
      })
      .catch((error) => {
        reject("fetchCombine() failed");
      });
  });
}

//fetch specific report
fetchReport = (type, options) => {
  return new Promise((resolve, reject) => {
    let report_type = type.concat("_report");
    fetch(Config.base_url + "/" + report_type, {
      method: "POST",
      headers: { "Content-type": "application/json" },
      body: JSON.stringify(options),
    })
      .then((res) => res.json())
      .then((result) => {
        resolve(result.report);
      }).catch(e => {
        console.log("🚀 ~ .then ~ e:", e)
        reject(e)
      })
  });
}

// get performance data
fetchPerformanceReport = (options) => {
  return new Promise((resolve, reject) => {
    this.fetchReport("mileage", options)
      .then((mileage) => {
        this.fetchReport("fuel", options)
          .then((fuel) => {
            this.fetchReport("maintenance", options)
              .then((maintenance) => {
                let result = {
                  mileage: mileage,
                  fuel: fuel,
                  maintenance: maintenance,
                };
                resolve(result);
              })
              .catch((err) => {
                reject("failed");
              });
          })
          .catch((e) => {
            console.error("error1: ", e);
            reject("failed");
          });
      })
      .catch((error) => {
        console.error("error2: ", error);
        reject("failed");
      });
  });
}
//get last approved request
fetchLastRequest = (vehicle, request_type) => {
  return new Promise((resolve, reject) => {
    fetch(
      Config.base_url + "/last_request/" + vehicle + "?type=" + request_type,
      {
        method: "get",
        headers: { "Content-type": "application/json" },
      }
    )
      .then((res) => res.json())
      .then((result) => {
        resolve(result.request);
      })
      .catch((err) => {
        console.error("err: ", err);
        reject("error");
      });
  });
}

fetchNotifications = (userid) => {
  return new Promise((resolve, reject) => {
    fetch(Config.base_url + "/notifications/" + userid, {
      method: "get",
      headers: { "Content-type": "application/json" },
    })
      .then((res) => res.json())
      .then((result) => {
        resolve(result.notifications);
      })
      .catch((err) => {
        reject("Could not get notifications");
      });
  });
}
}
export default new Fetcher();
