import React from "react";
import SettingSection from "./setting_section";
import Fetcher from "../utils/fetcher";

class SettingsContainer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showForm: false,
      admins:[]
    };

    this.handleButtonClick = this.handleButtonClick.bind(this);
    this.cancelForm = this.cancelForm.bind(this);
    this.handleFeedback = this.handleFeedback.bind(this);
    this.handleRegionChange = this.handleRegionChange.bind(this);
    this.getAdmins = this.getAdmins.bind(this)
  }
  handleRegionChange(e) {
    e.preventDefault();
    // let value = e.target.value;
    // let admins = this.props.admins.filter(a=>{
    //   return a.department
    // })
  }
  handleFeedback(status, msg) {
    this.props.onFeedback(status, msg);
  }
  cancelForm() {
    console.log("canceled form");
    this.setState({ showForm: false });
  }
  handleButtonClick(e) {
    e.preventDefault();
    this.setState({ showForm: true });
  }
  async getAdmins(){
    const users = await Fetcher.getActiveUsers();
    this.setState({admins:users.filter(u=>u.position == 1)}, () => {
      console.log("admins: ",this.state.admins)})
  }
 async componentDidMount() {
  await this.getAdmins()
  }
  render() {
    return (
      <section id={this.props.id} className="content">
        <div className="content flex-row flex-top wrap">
          {this.props.user.position === 6 ? (
            <SettingSection
              departments={this.props.departments}
              admins ={this.state.admins}
              coordinators={this.props.coordinators}
              user={this.props.user}
              suppliers={this.props.suppliers}
              projects={this.props.projects}
              fundcodes={this.props.fundcodes}
              title="Program Offices"
              actions={["Add", "Edit", "Delete"]}
              onFeedback={this.handleFeedback}
            />
          ) : null}
          {this.props.user.position === 1 || this.props.user.position === 6 ? (
            <>
              <SettingSection
                projects={this.props.projects}
                fundcodes={this.props.fundcodes}
                departments={this.props.departments}
                admins ={this.state.admins}
                coordinators={this.props.coordinators}
                suppliers={this.props.suppliers}
                user={this.props.user}
                title="Projects"
                onFeedback={this.handleFeedback}
                onRefresh={data => this.props.onUpdateProject(data)}
                actions={["Add", "Edit", "Delete"]}
              />
              <SettingSection
                departments={this.props.departments}
                admins ={this.state.admins}
                coordinators={this.props.coordinators}
                user={this.props.user}
                projects={this.props.projects}
                fundcodes={this.props.fundcodes}
                suppliers={this.props.suppliers}
                title="Fund Codes"
                actions={["Add", "Edit", "Delete"]}
                onFeedback={this.handleFeedback}
                onRefresh={data => this.props.onUpdateFundcode(data)}
              />
              <SettingSection
                departments={this.props.departments}
                projects={this.props.projects}
                fundcodes={this.props.fundcodes}
                admins ={this.state.admins}
                coordinators={this.props.coordinators}
                user={this.props.user}
                suppliers={this.props.suppliers}
                title="Suppliers"
                actions={["Add", "Edit", "Delete"]}
                onFeedback={this.handleFeedback}
                onRefresh={data => this.props.onUpdateSuppliers(data)}
              />
            </>
          ) : null}
          <SettingSection
            departments={this.props.departments}
            projects={this.props.projects}
            fundcodes={this.props.fundcodes}
            admins ={this.state.admins}
            coordinators={this.props.coordinators}
            suppliers={this.props.suppliers}
            user={this.props.user}
            title="Account"
            actions={["Change Password"]}
            onFeedback={this.handleFeedback}
            onRefresh={u => this.props.onUpdateUser(u)}
          />
        </div>
      </section>
    );
  }
}
export default SettingsContainer;
