import React from "react";
import Fetcher from "../utils/fetcher";
const config = require("../utils/config.json");
// import { getCityNames } from "postcodes-tz";

const PasswordForm = props => {
  const handleCancel = e => {
    // e.preventDefault();
    props.onCancel();
  };

  const submitForm = e => {
    e.preventDefault();
    let inputs = Array.from(e.target.elements);
    inputs = inputs.filter(i => {
      return i.id === "password1" || i.id === "password2";
    });

    let data = {
      id: props.user.id,
      password: inputs[0].value
    };

    fetch(config.base_url + "/user/" + props.user.id, {
      method: "PATCH",
      headers: {
        "Content-type": "application/json"
      },
      body: JSON.stringify(data)
    })
      .then(res => res.json())
      .then(result => {
        if (result.error) props.onFeedback(false, result.error);
        else {
          Fetcher.fetchUserWithId(data.id)
            .then(user => {
              props.onRefresh(user);
              props.onFeedback(true, result.response);
              props.onCancel();
            })
            .catch(error => {
              props.onFeedback(true, result.response);
              props.onCancel();
            });
        }
      })
      .catch(err => {
        console.log("error: ", err);
        props.onFeedback(false, "Something went wrong. Please try again later");
        props.onCancel();
      });
  };
  const handleChange = e => {
    let field = e.target;
    let pword = document.getElementById("password1").value;
    let btnSubmit = document.getElementById("submitPassword");
    if (field.value !== pword) {
      field.classList.add("fail");
      btnSubmit.disabled = true;
    } else {
      field.classList.remove("fail");
      btnSubmit.disabled = false;
    }
  };
  return (
    <div className="overlay w-100 flex-row flex-center flex-middle">
      <form className="bg-color-white-dark w-50" onSubmit={submitForm}>
        <h2>Change Account Password</h2>
        <div className="form-group">
          <label htmlFor="password1">New Password</label>
          <input
            type="password"
            id="password1"
            name="password1"
            className="form-control"
            placeholder="Type a password"
          />
        </div>
        <div className="form-group">
          <label htmlFor="password2">Confirm Password</label>
          <input
            onChange={handleChange}
            type="password"
            id="password2"
            name="password2"
            className="form-control"
            placeholder="Confirm password"
          />
        </div>
        <div className="form-group">
          <span className="flex-row flex-space">
            <input
              className="btn form-control bg-color-accent"
              type="submit"
              id="submitPassword"
              value="Save"
            />
            <span
              id="cancel-user"
              className="btn text-color-accent"
              onClick={handleCancel}
            >
              CANCEL
            </span>
          </span>
        </div>
      </form>
    </div>
  );
};
export default PasswordForm;
