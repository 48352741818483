import React from "react";
import { getCityNames } from "postcodes-tz";
import Fetcher from "../utils/fetcher";
import Commons from "../utils/commons";
const config = require("../utils/config.json");

class RequestForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isVisible: true,
      projects: props.projects,
      positions: props.positions,
      users: [],
      fcodes: props.fundcodes,
      filteredCodes: props.fundcodes,
      filteredUsers: [],
      filteredActivities: [],
      searchValue: "",
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
    this.handleTimeChange = this.handleTimeChange.bind(this);
    this.handleProjectChange = this.handleProjectChange.bind(this);
    this.getUsers = this.getUsers.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
    this.sendRequest = this.sendRequest.bind(this);
    this.handleCheckbox = this.handleCheckbox.bind(this);
    this.handleUserChange = this.handleUserChange.bind(this);
    this.handleFundcodeChange = this.handleFundcodeChange.bind(this);
  }
  handleUserChange = (e) => {
    let userid = e.target.value;
  };
  handleFundcodeChange(event) {
    let fc = event.target.value;
    this.setState({
      filteredActivities: Commons.getActivities(fc, this.state.fcodes),
    });
  }
  handleCheckbox = (event) => {
    var dateField;
    if (event.target.id == "today") dateField = document.getElementById("date");
    else {
      dateField = document.getElementById("return_date");
      this.setState({ interCity: true });
    }
    if (event.target.checked) {
      const date = new Date();
      let todaySeconds = date.getTime() / 1000;
      dateField.type = "text";
      dateField.value = Commons.dateFormat(todaySeconds);
    } else {
      dateField.type = "date";
      if (event.target.id == "rtoday") this.setState({ interCity: false });
    }
  };
  handleProjectChange(e) {
    e.preventDefault();

    let project = e.target.value;
    let fcodes = Commons.getCodes(project, this.state.fcodes);
    this.setState({ filteredCodes: fcodes }, () => {
      console.log("fc:", this.state.filteredCodes);
    });
  }

  handleSearch(e) {
    e.persist();
    let value = e.target.value;
    let keyword = value.toLocaleLowerCase();
    let fusers = this.state.users;
    console.log("fusers: ", fusers);
    fusers = fusers.filter((u) => {
      return (
        u.fname.toLocaleLowerCase().search(keyword) !== -1 ||
        u.lname.toLocaleLowerCase().search(keyword) !== -1
        // || u.position.name.toLocaleLowerCase().search(keyword) !== -1
      );
    });
    this.setState({ filteredUsers: fusers, searchValue: keyword });
  }

  sendRequest(data) {
    fetch(config.base_url + "/request", {
      method: "post",
      headers: { "Content-type": "application/json" },
      body: JSON.stringify(data),
    })
      .then((res) => res.json())
      .then((result) => {
        if (result.error) {
          this.props.onFeedback(false, result.error);
          this.handleCancel();
        } else {
          Fetcher.fetchRequests(this.props.user)
            .then((requests) => {
              this.props.onRefresh(requests);
              this.props.onFeedback(true, "Request sent successfully");
            })
            .catch((err) => {
              this.props.onFeedback(
                true,
                "Request sent successfully but could not refresh list"
              );
            });
          this.props.onFeedback(true, result.response);
          this.handleCancel();
        }
      })
      .catch((err) => {
        this.props.onFeedback(false, "Could not send request");
        this.handleCancel();
      });
  }
  async getUsers() {
    let user = this.props.user;
    const users = await Fetcher.getActiveUsers();
    let activeUsers = []
    if (users.length > 0) {
      activeUsers = users.filter((u) => {
        return u.position != 2;
      });
      if (user.position > 5)
        this.setState({
          users: activeUsers,
          filteredUsers: activeUsers,
        });
      else {
        activeUsers = users.filter((u) => {
          return user.position === 1
            ? u.id === user.id || u.position !== 1 &&
                u.department === user.department
            : u.id === user.id;
        });
        this.setState({ users: activeUsers, filteredUsers: activeUsers });
      }
    }
  }

  handleTimeChange(e) {
    // let value = e.target.value;
    // const timeField = document.getElementById("time");
    // if (value === "Hours") {
    //   from.type = "time";
    //   to.type = "time";
    // } else {
    //   from.type = "date";
    //   to.type = "date";
    // }
  }
  componentDidMount() {
    this.getUsers();
    if (this.props.projects.length > 0) {
      let project = this.props.projects[0];
      let fcodes = Commons.getCodes(project.code, this.props.fundcodes);
      this.setState({ filteredCodes: fcodes });
    }
  }
  handleCancel() {
    this.props.cancelButtonClick();
  }

  handleSubmit(e) {
    e.preventDefault();
    let selects = document.getElementsByClassName("cb-activities");
    let activities = [];
    Array.from(selects).forEach((opt) => {
      if (opt.checked) activities.push(opt.value);
    });
    let project = document.getElementById("project").value;
    let myP = this.state.projects.filter((f) => {
      return f.code === project;
    });
    let start_time = document.getElementById("stime").value;
    let sdate = new Date(
      document.getElementById("date").value + " " + start_time
    );

    let startTime = sdate.getTime() / 1000;
    let endeDateField = document.getElementById("return_date");
    let edate = new Date(endeDateField.value);

    let endTime = edate.getTime() / 1000;
    if (this.state.interCity) {
      let time = document.getElementById("time").value;
      edate = new Date(endeDateField.value + " " + time);
      endTime = edate.getTime() / 1000;
    }
    let codeTarget = document.getElementById("fund_code");
    let code = codeTarget.options[codeTarget.selectedIndex].value;
    let reqTarget = document.getElementById("requestor");
    let requestor = reqTarget.options[reqTarget.selectedIndex].value;
    let start_city = document.getElementById("start_city").value;

    let dept = this.props.departments.filter((d) => {
      return d.region.trim() === start_city.trim();
    });
    console.log("dept: ", dept);
    let data = {
      requestType: 0,
      requestor: requestor,
      approver: myP[0].coordinator,
      admin: dept.length > 0 ? dept[0].admin : myP[0].department_detail.admin,
      destination: document.getElementById("destination").value,
      destination_city: document.getElementById("destination_city").value,
      start_location: document.getElementById("location").value,
      start_city: start_city.trim(),
      purpose: document.getElementById("purpose").value,
      startTime: startTime,
      endTime: endTime,
      project: project,
      fundcode: code,
      activity: activities.join(),
      account: document.getElementById("account").value,
      source: document.getElementById("source").value,
      category: document.getElementById("category").value,
      status: 0,
    };

    this.sendRequest(data);
    // this.props.onFeedback(true, "testing...");
  }
  render() {
    return (
      <div className="flex-row flex-center center-self">
        <form
          name="vehicle-request-form"
          id="vehicle-request-form"
          className="center-self border-all-main"
          onSubmit={this.handleSubmit}
        >
          <h2 className="w-100 bg-color-main">Vehicle Request</h2>

          <div className="w-100 flex-row flex-start">
            <div className="form-group">
              <label htmlFor="search_staff" className="text-color-accent">
                Search Staff
              </label>
              <input
                readOnly={!this.props.isAdmin}
                disabled={!this.props.isAdmin}
                onChange={(e) => this.handleSearch(e)}
                className="form-control "
                type="text"
                name="search_staff"
                id="search_staff"
                value={this.state.searchValue}
                placeholder="Enter keyword"
              />
            </div>
            <div className="form-group">
              <label
                htmlFor="first_name"
                className="form-label text-color-accent"
              >
                Requestor's Name
              </label>
              <select
                className="form-control "
                name="requestor"
                id="requestor"
                onChange={this.handleUserChange}
                
              >
                {this.state.filteredUsers.map((u) => {
                   return (
                    <option key={u.id} value={u.id} selected={this.props.user.id === u.id}>
                      {u.fname + " " + u.lname}
                    </option>
                  );
                })}
              </select>
            </div>
          </div>
          <div className="w-100 flex-row flex-start">
            <div className="form-group">
              <label
                htmlFor="destination"
                className="form-label text-color-accent"
              >
                Destination (Separate with commas (,))
              </label>
              <input
                className="form-control "
                type="text"
                name="destination"
                id="destination"
                placeholder="Enter list of destinations"
              />
            </div>
            <div className="form-group">
              <label
                htmlFor="destination_city"
                className="form-label text-color-accent"
              >
                City/Region
              </label>
              <select
                className="form-control "
                defaultValue={this.props.user.location.trim()}
                name="destination_city"
                id="destination_city"
              >
                {getCityNames("asc").map((c) => {
                  return <option key={c}>{c}</option>;
                })}
              </select>
            </div>
          </div>
          <div className="w-100 flex-row flex-start">
            <div className="form-group">
              <label htmlFor="location" className="text-color-accent">
                Start Location
              </label>
              <input
                className="form-control "
                type="text"
                name="location"
                id="location"
                placeholder="Enter your current location"
              />
            </div>
            <div className="form-group">
              <label
                htmlFor="start_city"
                className="form-label text-color-accent"
              >
                City/Region
              </label>
              <select
                // disabled={!this.props.isAdmin}
                className="form-control "
                defaultValue={this.props.user.location.trim()}
                name="start_city"
                id="start_city"
              >
                {getCityNames("asc").map((c) => {
                  return <option key={c}>{c}</option>;
                })}
              </select>
            </div>
          </div>

          <div className="form-group">
            <label htmlFor="purpose" className="form-label text-color-accent">
              Purpose
            </label>
            <input
              className="form-control "
              type="text"
              name="purpose"
              id="purpose"
              placeholder="Purpose of trip"
            />
          </div>
          <div className="flex-row flex-start">
            <div className="form-group">
              <label htmlFor="date" className="form-label text-color-accent">
                Trip Date
              </label>
              <input
                className="form-control "
                type="date"
                name="date"
                id="date"
              />
            </div>
            <div className="form-group">
              <label htmlFor="today" className="form-label text-color-accent">
                Today
              </label>
              <input
                onChange={this.handleCheckbox}
                className="form-control"
                type="checkbox"
                name="today"
                id="today"
              />
            </div>
            <div className="form-group">
              <label htmlFor="stime" className="form-label text-color-accent">
                Time
              </label>
              <input
                onChange={this.handleTimeChange}
                className="form-control"
                type="time"
                name="stime"
                id="stime"
              />
            </div>
          </div>
          <div className="flex-row flex-start">
            <div className="form-group">
              <label
                htmlFor="return_date"
                className="form-label text-color-accent"
              >
                Return Date
              </label>
              <input
                className="form-control "
                type="date"
                name="return_date"
                id="return_date"
              />
            </div>
            <div className="form-group">
              <label htmlFor="rtoday" className="form-label text-color-accent">
                Today
              </label>
              <input
                onChange={this.handleCheckbox}
                className="form-control"
                type="checkbox"
                name="rtoday"
                id="rtoday"
              />
            </div>
            {this.state.interCity ? (
              <div className="form-group">
                <label htmlFor="time" className="form-label text-color-accent">
                  Time
                </label>
                <input
                  onChange={this.handleTimeChange}
                  className="form-control"
                  type="time"
                  name="time"
                  id="time"
                />
              </div>
            ) : null}
          </div>
          <div className="flex-row flex-start w-100">
            <div className="form-group">
              <label htmlFor="project" className="form-label text-color-accent">
                Project
              </label>
              <select
                onChange={this.handleProjectChange}
                className="form-control"
                name="project"
                id="project"
              >
                <option value="select">--select--</option>
                {this.state.projects.map((project) => {
                  return <option key={project.id}>{project.code}</option>;
                })}
              </select>
            </div>
            <div className="form-group">
              <label
                htmlFor="fund_code"
                className="form-label text-color-accent"
              >
                Fund Code
              </label>
              <select
                id="fund_code"
                className="form-control"
                onChange={this.handleFundcodeChange}
              >
                <option value="select">--select--</option>
                {this.state.filteredCodes.map((fcode) => {
                  return <option key={fcode.id}>{fcode.code}</option>;
                })}
              </select>
            </div>
          </div>
          <div className="form-group">
            <label htmlFor="activity" className="text-color-accent">
              Activities
            </label>
            <div className="form-group flex-row flex-start">
              {this.state.filteredActivities.map((a) => {
                return (
                  <span key={"lb" + a} className=" flex-row flex-start">
                    <input
                      className="form-control cb-activities"
                      type="checkbox"
                      id={"cb" + a}
                      value={a}
                    />
                    <label htmlFor={"cb" + a} className="text-color-accent">
                      {a}
                    </label>
                  </span>
                );
              })}
            </div>
          </div>

          <div className="flex-row flex-start w-100">
            <div className="form-group">
              <label htmlFor="account" className="form-label text-color-accent">
                Account
              </label>
              <input
                className="form-control "
                type="text"
                name="account"
                id="account"
              />
            </div>
            <div className="form-group">
              <label htmlFor="source" className="form-label text-color-accent">
                Source
              </label>
              <input id="source" name="source" className="form-control" />
            </div>
            <div className="form-group">
              <label
                htmlFor="category"
                className="form-label text-color-accent"
              >
                Category
              </label>
              <input id="category" className="form-control" name="category" />
            </div>
          </div>

          <div className="form-group">
            <span className="flex-row flex-space">
              <input
                className="btn form-control bg-color-accent"
                type="submit"
                id="submitRequest"
                value="Submit"
              />
              <span
                id="cancel"
                className="btn text-color-accent"
                onClick={this.handleCancel}
              >
                CANCEL
              </span>
            </span>
          </div>
        </form>
      </div>
    );
  }
}
export default RequestForm;
