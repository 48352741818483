import React from "react";
import {
  getCoreRowModel,
  ColumnDef,
  flexRender,
  useReactTable} from '@tanstack/react-table'
import PageControl from "./page_control";
const UserListItem = props => {

  const handleClick = (e,r) => {
    props.onClick(r);
  };
  const getPreviousPage = ()=>{
    props.onPageChange(props.currentPage - 1);
  }
  const getNextPage = ()=>{
    props.onPageChange(props.currentPage +1);
  }

  const columns = 
  [
    {
      accessorFn: row => row.fname,
      id: 'fname',
      cell: info => info.getValue(),
      header: () => <span>First Name</span>,
      footer: props => props.column.id,
    },
    {
      accessorFn: row => row.lname,
      id: 'lname',
      cell: info => info.getValue(),
      header: () => <span>Last Name</span>,
      footer: props => props.column.id,
    },
    {
      accessorFn: row => row.email,
      id: 'email',
      cell: info => info.getValue(),
      header: () => <span>Email</span>,
      footer: props => props.column.id,
    },
    {
      accessorFn: row => row.phone,
      id: 'phone',
      cell: info => info.getValue(),
      header: () => <span>Phone</span>,
      footer: props => props.column.id,
    },
    {
      accessorFn: row => row.position_detail.value,
      id: 'position',
      cell: info => info.getValue(),
      header: () => <span>Position</span>,
      footer: props => props.column.id,
    },
    {
      accessorFn: row => row.busy,
      id: 'busy',
      cell: info => info.getValue() == 0 ? "Available": (info.getValue() == 1 ? "Busy": "Deleted"),
      header: () => <span>Status</span>,
      footer: props => props.column.id,
    },
    {
      accessorFn: row => row.dept_code,
      id: 'dept_code',
      cell: info => info.getValue(),
      header: () => <span>Department</span>,
      footer: props => props.column.id,
    },
    {
      accessorFn: row => row.location,
      id: 'location',
      cell: info => info.getValue(),
      header: () => <span>Location</span>,
      footer: props => props.column.id,
    },
  ]

  const data = props.users || [];
  console.log("🚀 ~ UserListItem ~ data:", data)

  const table = useReactTable ({
    data,
    columns,
    enableColumnResizing: true,
    columnResizeMode: 'onChange',
    getCoreRowModel: getCoreRowModel()
  })
  
return (
  <div className="content-list flex-column justify-content-between">
    <table className="w-full text-left border-all-main p-2">
  <thead>
  {table.getHeaderGroups().map(headerGroup => (
    <tr key={headerGroup.id} className="bg-color-main px-2 py-1">
      {headerGroup.headers.map(header => {
        return (
          <th
            key={header.id}
            colSpan={header.colSpan}
            style={{ position: 'relative', width: header.getSize() }}
          >
            {header.isPlaceholder
              ? null
              : flexRender(
                  header.column.columnDef.header,
                  header.getContext()
                )}
            {header.column.getCanResize() && (
              <div
                onMouseDown={header.getResizeHandler()}
                onTouchStart={header.getResizeHandler()}
                className={`resizer ${
                  header.column.getIsResizing() ? 'isResizing' : ''
                }`}
              ></div>
            )}
          </th>
        )
      })}
    </tr>
  ))}
  </thead>
  <tbody>
  {table.getRowModel().rows.map(row => {
    return (
      <tr key={row.id} className="list-item" onClick={(e)=>handleClick(e,row.original)}>
        {row.getVisibleCells().map(cell => {
          return (
            <td key={cell.id} style={{ width: cell.column.getSize() }} className="border-bottom px-1">
              {flexRender(
                cell.column.columnDef.cell,
                cell.getContext()
              )}
            </td>
          )
        })}
      </tr>
    )
  })}
  </tbody>
  </table>
  <div className="w-full border-top mt-3 flex-row flex-start bg-main-color align-items-center ">
  <PageControl onNext={getNextPage} onPrevious={getPreviousPage} currentPage={props.currentPage} totalPages={props.totalPages} onPageSizeChange={(pageSize)=>{props.onPageSizeChange(pageSize)}} hasNext={props.hasNext} hasPrevious={props.hasPrevious}/>
  
  </div>
  
  </div>)
  
};
export default UserListItem;
