import React, { useDebugValue } from "react";
import CustomButton from "./button";
import VehicleContainer from "./vehicle_container";
import UserContainer from "./user_container";
import Feedback from "./feedback";
import RequestContainer from "./request_container";
import TripContainer from "./trip_container";
import SettingsContainer from "./settings_container";
import Avatar from "./avatar";
import ReportContainer from "./report_container";
import SignoutDialog from "./signout_dialog";
import Notification from "./notification";
import Spinner from "./spinner";
import Fetcher from "../utils/fetcher"

import CustomDialog from "./custom_dialog";
import NotificationsContainer from "./notifications_container";

class Dashboard extends React.Component {
  constructor(props) {
    super(props);
    this.handleFeedback = this.handleFeedback.bind(this);
    this.isActiveButton = this.isActiveButton.bind(this);
    this.handleMenuButtonClick = this.handleMenuButtonClick.bind(this);
    this.handleCancelSignout = this.handleCancelSignout.bind(this);
    this.loadData = this.loadData.bind(this);
    this.showNotification = this.showNotification.bind(this);

    this.fetchNotifications = this.fetchNotifications.bind(this);
    this.updateUsers = this.updateUsers.bind(this);
    this.updateUser = this.updateUser.bind(this);
    this.updateVehicles = this.updateVehicles.bind(this);
    this.updateProjects = this.updateProjects.bind(this);
    this.updateFundcodes = this.updateFundcodes.bind(this);
    this.updateSuppliers = this.updateSuppliers.bind(this);
    this.updateDepartments = this.updateDepartments.bind(this);
    this.handleNotificationClick = this.handleNotificationClick.bind(this);
    this.handleViewNotification = this.handleViewNotification.bind(this);
    this.handleCancelNotification = this.handleCancelNotification.bind(this);
    const auth = window.sessionStorage.getItem('AUTH') ? JSON.parse(sessionStorage.getItem('AUTH')) : false
    this.state = {
      isAuth: auth !== false,
      hasFeedback: false,
      feedback: false,
      message: "",
      user: auth,
      users: [],
      buttons: [],
      activeButton: "requests",
      buttonNewText: "New Request",
      positions: [],
      departments: [],
      projects: [],
      fundcodes: [],
      suppliers: [],
      vehicles: [],
      stata: [],
      notifications: [],
      unreadNotifications: [],
      notificationVisible: false,
      passwordExpired: false,
      loadingComplete: false,
      showDialog: false,
      request: null,
      requests: [],
      drivers:[]
    };
  }
  handleCancelNotification = () => {
    this.setState({ showDialog: false });
  };
  handleViewNotification = (notification) => {
    Fetcher.clearNotification(notification.id).then((success) => {
      Fetcher.fetchNotifications(this.state.user.id).then((notifications) => {
        let unread = notifications.filter((n) => {
          return n.status === 0;
        });
        this.setState({
          notifications: notifications,
          unreadNotifications: unread,
        });
      });
    });
    if (notification.target != null && notification.type != null) {
      Fetcher.fetchRequest(notification.target, notification.type)
        .then((request) => {
          this.setState({ request: request, activeButton: "requests" });
        })
        .catch((e) => {});
    } else {
      this.setState({ showDialog: true });
    }
  };
  updateRequests = (list) => {
    this.setState({ requests: list });
  };
  updateProjects = (list) => {
    this.setState({ projects: list });
  };
  updateDepartments = (list) => {
    this.setState({ departments: list });
  };
  updateSuppliers = (list) => {
    this.setState({ suppliers: list });
  };
  updateFundcodes = (list) => {
    this.setState({ fundcodes: list });
  };
  updateVehicles = (list) => {
    this.setState({ vehicles: list });
  };
  updateUsers = (list) => {
    this.setState({ users: list });
  };
  handleCancelSignout = () => {
    this.handleMenuButtonClick("requests");
  };
  handleFeedback(status, msg) {
    this.setState({ hasFeedback: true, feedback: status, message: msg });
    setTimeout(() => {
      this.setState({ hasFeedback: false });
    }, 10000);
  }
  isActiveButton(id) {
    if (this.state.activeButton === id) {
      return true;
    } else return false;
  }
  updateUser = (user) => {
    this.setState({ user: user }, () => {
      let date = new Date();
      let today = date.getTime() / 1000;
      this.setState({
        passwordExpired: today > user.password_expire,
        hasFeedback: false,
      });
    });
  };
  handleNotificationClick = (item) => {
    this.setState({ notification: item });
  };
  handleMenuButtonClick(id) {
    this.setState(
      {
        activeButton: id,
        buttonNewText: "New ",
      },
      () => {
        let index = 0;
        this.state.buttons.map((b, i) => {
          if (b.clicked) {
            b.clicked = false;
          }
          if (b.id === id) {
            b.clicked = true;
            index = i;
          }
        });
        let btns = this.state.buttons;
        btns[index].clicked = true;
        this.setState({ buttons: btns });
      }
    );
  }

  fetchNotifications() {
    Fetcher.fetchNotifications(this.state.user.id)
      .then((notifications) => {
        let unread = notifications.filter((n) => {
          return n.status === 0;
        });
        this.setState({
          notifications: notifications,
          unreadNotifications: unread,
        });
      })
      .catch((err) => {
        this.handleFeedback(false, err);
      });
  }

  async loadData() {
    
    try{
      const [coordinators,drivers,positions,users,departments,projects,fundcodes,vehicles,stata,suppliers] = await Promise.all([Fetcher.getCoordinators(),Fetcher.getDrivers(),Fetcher.fetchPositions(),Fetcher.getActiveUsers(),Fetcher.fetchDepartments(),Fetcher.fetchProjects(),Fetcher.fetchFundcodes("active"),Fetcher.fetchVehicles(),Fetcher.fetchRequestStata(),Fetcher.fetchSuppliers()]);
    this.setState({ coordinators:coordinators,drivers:drivers,users:users.users,positions: positions,departments: departments,projects: projects,fundcodes: fundcodes,vehicles: vehicles,stata: stata,suppliers: suppliers,
      loadingComplete: true  })
    }
    catch(error){
        console.log("Fetcher.fetchPositions: ", error);
        this.handleFeedback(false, error);
      };

    this.fetchNotifications();
    // this.fetchPositions();
  }
  showNotification() {
    this.setState({ activeButton: "notification" }, () => {
      let btns = this.state.buttons.map((b, i) => {
        if (b.clicked) {
          b.clicked = false;
        }
        return b;
      });

      this.setState({ buttons: btns });
    });
  }

  componentDidMount() {
    let session = sessionStorage.getItem('AUTH');
    let user = session && session != undefined ? JSON.parse(session): null;

    if (user !== undefined && user !== null) {
      let today = new Date();
      let timeInSeconds = today.getTime() / 1000;

      this.setState({ user: user, isAuth: true,passwordExpired: timeInSeconds > user.password_expire  }, async () => {
        
          try{
            const actions = await  Fetcher.getActions(user.position);
            if (this.state.passwordExpired)
                  this.setState({ activeButton: "settings" });
                let buttons = actions.map((action) => {
                  let a = action;
                  a.clicked = this.isActiveButton(action.text.toLowerCase());
                  return a;
                });
                this.setState({ buttons: buttons }, () => {
                  this.loadData();
                });
          }catch(error){
                this.handleFeedback(false, error);
              }
          
      });
    } else {
      this.setState({ isAuth: false });
      sessionStorage.clear();
      window.location.pathname = '/'
    }

    // window.location.pathname = "/dashboard";
  }

  render() {
    
    return (
      <main>
        <div id="left-pane" className="side-pane bg-color-main">
          <div className="tray flex-row flex-middle flex-space">
            <Notification
              count={this.state.unreadNotifications.length}
              onNotificationClick={this.showNotification}
            />

            <Avatar username={this.state.user.username} />
          </div>

          <nav id="side-nav">
            {this.state.buttons.map((btn) => {
              return (
                <CustomButton
                  key={btn.id}
                  id={btn.id}
                  icon={btn.icon}
                  text={btn.text}
                  clicked={btn.clicked}
                  onButtonClick={(id) => {
                    this.handleMenuButtonClick(id);
                  }}
                />
              );
            })}
          </nav>
        </div>

        {this.state.loadingComplete ? (
          <div id="right-pane" className="content">
            {this.state.hasFeedback ? (
              <Feedback
                success={this.state.feedback}
                message={this.state.message}
              />
            ) : this.state.passwordExpired ? (
              <Feedback
                success={false}
                message={
                  "Your password has expired. Please change your password now"
                }
              />
            ) : null}
            {this.state.activeButton === "notification" ? (
              <NotificationsContainer
                user={this.state.user}
                notifications={this.state.notifications}
                onItemClick={(notification) =>
                  this.handleViewNotification(notification)
                }
              />
            ) : null}
            {this.state.activeButton === "settings" ? (
              <SettingsContainer
                user={this.state.user}
                departments={this.state.departments}
                projects={this.state.projects}
                fundcodes={this.state.fundcodes}
                coordinators={this.state.coordinators}
                // users={this.state.users.filter(u=> u.busy !== 3)}
                suppliers={this.state.suppliers}
                onFeedback={(status, msg) => this.handleFeedback(status, msg)}
                onUpdateUser={(u) => this.updateUser(u)}
                onUpdateProject={(p) => this.updateProjects(p)}
                onUpdateFundcode={(fc) => this.updateFundcodes(fc)}
                onUpdateDepartment={(d) => this.updateDepartments(d)}
                onUpdateSupplier={(s) => this.updateSuppliers(s)}
                title={this.state.activeButton.toUpperCase()}
              />
            ) : null}
            {this.state.activeButton === "trips" ? (
              <TripContainer
                user={this.state.user}
                // users={this.state.users.filter(u=>u.busy !== 3)}
                coordinators={this.state.coordinators}
                drivers = {this.state.drivers}
                onRefresh={(rqs) => {this.updateRequests(rqs)}}
                onFeedback={(st, msg) => this.handleFeedback(st, msg)}
                buttonNewText={this.state.buttonNewText}
                title={this.state.activeButton.toUpperCase()}
                buttonNew={"add_" + this.state.activeButton}
              />
            ) : null}
            {this.state.activeButton === "vehicles" ? (
              <VehicleContainer
                user={this.state.user}
                onFeedback={(st, msg) => this.handleFeedback(st, msg)}
                buttonNewText={this.state.buttonNewText}
                title={this.state.activeButton.toUpperCase()}
                buttonNew={"add_" + this.state.activeButton}
                onRefresh={(list) => this.updateVehicles(list)}
              />
            ) : null}
            {this.state.activeButton === "users" ? (
              <UserContainer
                user={this.state.user}
                users={this.state.users}
                drivers = {this.state.drivers}
                coordinators={this.state.coordinators}
                positions={this.state.positions}
                departments={this.state.departments}
                onFeedback={(s, m) => this.handleFeedback(s, m)}
                onRefresh={(list) => this.updateUsers(list)}
                buttonNewText={this.state.buttonNewText}
                title={this.state.activeButton.toUpperCase()}
                buttonNew={"add_" + this.state.activeButton}
              />
            ) : null}
            {this.state.activeButton === "requests" ? (
              <RequestContainer
                defaultRequest={this.state.request}
                user={this.state.user}
                departments={this.state.departments}
                positions={this.state.positions}
                projects={this.state.projects}
                suppliers={this.state.suppliers}
                fundcodes={this.state.fundcodes}
                vehicles={this.state.vehicles}
                users={this.state.users}
                coordinators={this.state.coordinators}
                drivers = {this.state.drivers}
                stata={this.state.stata}
                onUpdateUsers={(list) => this.updateUsers(list)}
                onFeedback={(s, m) => this.handleFeedback(s, m)}
                buttonNewText={this.state.buttonNewText}
                title={this.state.activeButton.toUpperCase()}
                buttonNew={"add_" + this.state.activeButton}
              ></RequestContainer>
            ) : null}
            {this.state.activeButton === "reports" ? (
              <ReportContainer
                user={this.state.user}
                onFeedback={(s, m) => this.handleFeedback(s, m)}
                buttonNew={"add_" + this.state.activeButton}
                title={this.state.activeButton.toUpperCase()}
                buttonNewText={this.state.buttonNewText}
              />
            ) : null}
            {this.state.activeButton === "sign out" ? (
              <SignoutDialog
                user={this.state.user}
                title={this.state.activeButton.toUpperCase()}
                onCancel={this.handleCancelSignout}
                msg={"Are you sure you want to sign out?"}
              />
            ) : null}
            {this.state.showDialog ? (
              <CustomDialog
                title={"Notifications"}
                onCancel={this.handleCancelNotification}
                msg={"Sorry! Request could not be found!"}
              />
            ) : null}
          </div>
        ) : (
          <div
            className="content flex-row flex-middle flex-center"
            id="right-pane"
          >
            <Spinner />
          </div>
        )}
      </main>
    );
  }
}
export default Dashboard;
