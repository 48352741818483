import React from "react";

const PageControl = (props)=>{
const handleChange = (e) => {
    props.onPageSizeChange(e.target.value)
}
    return (<div className="mx-1 my-2 w-100 row">
    <div className="col-md-6 col-lg-6 text-left">
        <span>
            Show
        <select id="pageSize" onChange={handleChange} className="mx-1" defaultValue={props.pageSize}>
            <option value={10}>10</option>
            <option  value={20}>20</option>
            <option value={30}>30</option>
            <option value={50}>50</option>
            <option value={100}>100</option>
        </select>
        per page
        </span>
    </div>
    <div  className="d-flex align-items-center justify-content-end col-md-6 col-lg-6 text-right">
        {props.hasPrevious ? <span className="material-icons" onClick={props.onPrevious}>chevron_left</span>:null}
        <span className="mx-3">Page {props.currentPage} of {props.totalPages}</span>
        {props.hasNext ? <span className="material-icons" onClick={props.onNext}>chevron_right</span>:null}
    </div>
    </div>)
}
export default PageControl;