import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

const SignoutDialog =(props)=>{
  
  const navigate = useNavigate()
  const handleCancel=() =>{
    props.onCancel();
  }
  const handleAction = () => {
    sessionStorage.clear();
    navigate('/signout',{replace:true})
    
  };
    
    return (
      <div className="overlay w-100 flex-row flex-center flex-middle">
        <div className="setting-section bg-color-white-dark w-40 flex-center flex-top">
          <h4 className="bg-color-main flex-row flex-center">
            {props.title}
          </h4>
          <p className="flex-row flex-center flex-middle">{props.msg}</p>
          <span className="flex-row flex-space flex-middle mx-5 my-5">
            <button
              onClick={handleAction}
              className="btn bg-color-accent"
              id="action-signout"
            >
              Sign out
            </button>

            <span
              id="cancel-user"
              className="btn text-color-accent"
              onClick={handleCancel}
            >
              CANCEL
            </span>
          </span>
        </div>
      </div>
    );
  
}
export default SignoutDialog;
