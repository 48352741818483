import React from "react";

const Notification = props => {
  const classname = "material-icons" + (props.count > 0 ? " " : " text-grey");
  const handleNotificationClick = () => {
    props.onNotificationClick();
  };
  return (
    <div className="notification" onClick={handleNotificationClick}>
      <span>
        <i className={classname}>notifications</i>
      </span>
      {props.count > 0 ? (
        <span className="badge">
          {props.count > 9 ? "9" + "+" : props.count}
        </span>
      ) : null}
    </div>
  );
};
export default Notification;
