import React from "react";
import xlsExport from "xlsexport";
import Commons from "../utils/commons";
import {
  VictoryBar,
  VictoryChart,
  VictoryAxis,
  VictoryTheme,
  VictoryPie,
  VictoryLine,
  VictoryLabel,
  VictoryLegend,
  Border,
} from "victory";
import DataTable from "react-data-table-component";
class MileageListReport extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      reportFormat: "",
      chartOptions: {},
      isMounted: false,
      data: [],
      labels: [],
      values: [],
      title: "",
    };

    this.getFullName = this.getFullName.bind(this);
    this.exportToXls = this.exportToXls.bind(this);
    this.generateChart = this.generateChart.bind(this);
  }
  componentDidMount() {
    this.setState({ title: this.props.title });
    let report = this.props.report.map((r) => {
      let rep = r;
      // rep.date = Commons.dateFormat(r.last_updated);
      rep.from = r.start_location + " (" + r.start_city + ")";
      rep.to = r.destination + " (" + r.destination_city + ")";
      rep.requestor_name = this.getFullName(r.requestor);
      rep.attended_by_name = this.getFullName(r.attended_by);
      rep.approver_name = this.getFullName(r.approver);
      rep.driver = this.getFullName(r.driver);
      return rep;
    });

    this.setState(
      { report: report, reportFormat: this.props.reportFormat },
      () => {
        this.generateChart();
      }
    );
  }
  componentWillUnmount() {
    this.setState({ isMounted: false });
  }
  generateChart = () => {
    let type = this.state.reportFormat.toLowerCase();
    if (type !== "" && type !== "list") {
      let myData = Commons.groupByArray(this.props.report, this.props.groupBy);
      console.log("grouped: ", myData);
      let labels = myData.map((d) => {
        return d.key;
      });
      let values = myData.map((d) => {
        let value = 0;
        d.values.forEach((v) => {
          value += v.distance;

          return value;
        });
      });

      let sourcedata = myData.map((d) => {
        let data = {};
        data.x = d.key;
        data.y = d.values.reduce((a, b) => {
          console.log("data.y: ", a, b.distance);
          return a + b.distance;
        }, 0);
        return data;
      });

      let options = {
        title: { text: this.props.title },

        data: [{ type: "column", dataPoints: sourcedata }],
      };
      console.log("datasource: ", sourcedata);
      this.setState({
        chartOptions: options,
        isMounted: true,
        data: sourcedata,
        labels: labels,
        values: values,
      });
    } else {
      let source = this.props.report;
      this.setState({ report: source });
    }
  };
  formatNumber = (num) => {
    if (typeof num === "string") num = parseFloat(num);
    return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
  };
  exportToXls = () => {
    let data = this.state.report.map((r) => {
      let rep = {
        date: Commons.dateFormat(r.last_updated),
        vehicle: r.vehicle,
        project: r.project,
        fundcode: r.fundcode,
        category: r.category,
        source: r.source,
        activity: r.activity,
        requestor_name: r.requestor_name,
        approved_by: r.attended_by_name,
        approver: r.approver_name,
        distance: r.distance,
      };

      return rep;
    });
    let xls = new xlsExport(data, this.props.title);
    xls.exportToXLS("mileage_report.xls");
  };
  groupByArray = (xs, key) => {
    let result = xs.reduce((rv, x) => {
      let v = key instanceof Function ? key(x) : x[key];
      let el = rv.find((r) => r && r.key === v);
      if (el) {
        el.values.push(x);
      } else {
        rv.push({ key: v, values: [x] });
      }
      return rv;
    }, []);

    return result;
  };

  getFullName = (userid) => {
    if (userid === 1) return "System Admin";
    let user = this.props.users.filter((u) => {
      return u.id === userid;
    });
    if (user.length > 0) return user[0].fname + " " + user[0].lname;

    return userid;
  };
  render() {
    return (
      <div className="center-self">
        <h2>{"Mileage Report " + this.state.title}</h2>
        <p>
          {Commons.dateFormat(this.props.dateRange.start) +
            " to " +
            Commons.dateFormat(this.props.dateRange.end)}
        </p>
        <h3>
          {"Total Distance Travelled: " +
            Commons.formatNumber(Commons.findTotalDistance(this.props.report)) +
            " Km"}
        </h3>
        <div className="content-list flex-center">
          {this.state.reportFormat.toLowerCase() === "list" &&
          !this.state.isMounted ? (
            <DataTable
              pagination={true}
              theme="default"
              columns={[
                {
                  name: "Date",
                  selector: "last_updated",
                  sortable: true,
                  format: (row) => Commons.dateFormat(row.last_updated),
                },
                { name: "Vehicle", selector: "vehicle", sortable: true },
                { name: "From", selector: "from", sortable: true, wrap: true },
                { name: "To", selector: "to", sortable: true, wrap: true },
                {
                  name: "Distance (Km)",
                  selector: "distance",
                  sortable: true,
                  style: { textAlign: "right" },
                },
                { name: "Fund Code", selector: "fundcode", sortable: true },
                { name: "Source", selector: "source", sortable: true },
                { name: "Activity", selector: "activity", sortable: true },
                {
                  name: "Requested by",
                  selector: "requestor_name",
                  sortable: true,
                  wrap: true,
                },
                {
                  name: "Driver",
                  selector: "driver",
                  sortable: true,
                  wrap: true,
                },
                {
                  name: "Approved by",
                  selector: "approver_name",
                  sortable: true,
                  wrap: true,
                },
              ]}
              data={this.state.report}
            />
          ) : (
            <div className="center-self flex-row flex-center w-100 border-all-main">
              {this.state.reportFormat.toLowerCase() === "column" ? (
                <div className="w-80">
                  <VictoryChart
                    domainPadding={20}
                    theme={VictoryTheme.material}
                  >
                    <VictoryAxis
                      label={
                        this.props.groupBy.substring(0, 1).toUpperCase() +
                        this.props.groupBy.substring(1)
                      }
                      style={{
                        axis: { stroke: "#756f6a" },
                        axisLabel: { fontSize: 10, padding: 30 },
                        grid: {
                          stroke: ({ tick }) => (tick > 0.5 ? "red" : "grey"),
                        },
                        ticks: { stroke: "grey", size: 5 },
                        tickLabels: { fontSize: 15, padding: 5 },
                      }}
                      tickValues={[]}
                      tickFormat={this.state.labels}
                      tickLabelComponent={
                        <VictoryLabel
                          angle={30}
                          style={{ fontSize: "6px", wordWrap: "break-word" }}
                        />
                      }
                    />
                    <VictoryAxis
                      label="Cost in Tsh"
                      dependentAxis
                      tickFormat={(x) => {
                        return x < 1000
                          ? x.toFixed(1)
                          : (x / 1000).toFixed(1) + "k";
                      }}
                      style={{
                        axis: { stroke: "#756f6a" },
                        axisLabel: { fontSize: 10, padding: 40 },

                        ticks: { stroke: "grey", size: 5 },
                        tickLabels: { fontSize: 10, padding: 5 },
                      }}
                      tickLabelComponent={
                        <VictoryLabel
                          angle={0}
                          style={{ fontSize: "6px", wordWrap: "break-word" }}
                        />
                      }
                    />
                    <VictoryBar
                      data={this.state.data}
                      style={{ labels: { fontSize: "6px" } }}
                      x="x"
                      y="y"
                      tickLabelComponent={
                        <VictoryLabel
                          angle={0}
                          style={{ fontSize: "6px", wordWrap: "break-word" }}
                        />
                      }
                    />
                  </VictoryChart>
                </div>
              ) : this.state.reportFormat.toLowerCase() === "pie" ? (
                <div className="w-80">
                  <svg className="center-self w-100">
                    <VictoryLegend
                      standalone={false}
                      x={20}
                      y={40}
                      orientation="horizontal"
                      gutter={20}
                      title="Legend"
                      borderComponent={<Border width="90%" />}
                      borderPadding={5}
                      colorScale="qualitative"
                      centerTitle
                      style={{
                        border: { stroke: "black" },
                        textAlign: "center",
                      }}
                      data={this.state.labels.map((d) => {
                        let r = {};
                        r.name = d;
                        return r;
                      })}
                    />
                  </svg>
                  <VictoryPie
                    padAngle={3}
                    padding={{ left: 80, right: 80 }}
                    data={this.state.data}
                    theme={VictoryTheme.material}
                    labels={({ datum }) => datum.y + "Km"}
                    labelPosition="centroid"
                    innerRadius={25}
                    // labelRadius={({ innerRadius }) => innerRadius + 35}
                    colorScale="qualitative"
                    labelComponent={
                      <VictoryLabel
                        angle={45}
                        style={{ fontSize: "6px", wordWrap: "break-word" }}
                      />
                    }
                    style={{
                      data: {
                        stroke: "#c43a31",
                        strokeWidth: 0,
                        strokeLinecap: "round",
                      },
                    }}
                  />
                </div>
              ) : this.state.reportFormat.toLowerCase() === "line" ? (
                <div className="w-80">
                  <VictoryChart theme={VictoryTheme.material}>
                    <VictoryAxis
                      label={"Distance in Km"}
                      dependentAxis
                      tickFormat={(x) => {
                        return x < 1000 ? x : (x / 1000).toFixed(1) + "k";
                      }}
                      style={{
                        axis: { stroke: "#756f6a" },
                        axisLabel: { fontSize: 10, padding: 40 },
                      }}
                      tickLabelComponent={
                        <VictoryLabel
                          angle={0}
                          style={{ fontSize: "6px", wordWrap: "break-word" }}
                        />
                      }
                    />
                    <VictoryAxis
                      label={this.props.groupBy
                        .substring(0, 1)
                        .toUpperCase()
                        .concat(this.props.groupBy.substring(1))}
                      style={{
                        axis: { stroke: "#756f6a" },
                        axisLabel: { fontSize: 10, padding: 30 },
                        grid: {
                          stroke: ({ tick }) => (tick > 0.5 ? "red" : "grey"),
                        },
                        ticks: { stroke: "grey", size: 5 },
                        tickLabels: { fontSize: 15, padding: 5 },
                      }}
                      tickValues={[]}
                      tickFormat={this.state.labels}
                      tickLabelComponent={
                        <VictoryLabel
                          angle={30}
                          style={{ fontSize: "6px", wordWrap: "break-word" }}
                        />
                      }
                    />
                    <VictoryLine
                      style={{
                        data: {
                          stroke: "#c43a31",
                          strokeWidth: 1,
                          strokeLinecap: "round",
                        },
                      }}
                      data={this.state.data}
                    />
                  </VictoryChart>
                </div>
              ) : null}
            </div>
          )}
        </div>
        <div className="form-group">
          <span className="flex-row flex-space">
            {this.state.reportFormat.toLowerCase() === "list" ? (
              <input
                className="btn form-control btn-success"
                type="button"
                id="exportXls"
                value="Export to Excel"
                onClick={this.exportToXls}
              />
            ) : null}
            {/* <input
            className="btn form-control btn-error"
            type="button"
            id="exportPdf"
            value="Export to PDF"
            onClick={exportToPdf}
          /> */}

            <span
              id="cancel-user"
              className="btn text-color-accent"
              onClick={() => this.props.cancelButtonClick()}
            >
              CLOSE
            </span>
          </span>
        </div>
      </div>
    );
  }
}
export default MileageListReport;
