import React from "react";
import { getCityNames } from "postcodes-tz";
import Fetcher from "../utils/fetcher";
import CustomDialog from "./custom_dialog";
const config = require("../utils/config.json");

class SupplierEditForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      supplier: null,
      supplierChanged: false,
      complete: false,
      response: "",
    };

    this.handleCancel = this.handleCancel.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleSupplierChange = this.handleSupplierChange.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange = (event) => {
    event.preventDefault();
    let supplier = this.state.supplier;
    let value = event.target.value;

    switch (event.target.id) {
      case "name":
        supplier.name = value;
        break;
      case "service":
        supplier.service = value;
        break;
      case "region":
        supplier.region = value;
        break;
      case "tin":
        supplier.tin = value;
        break;
    }

    this.setState({ supplier: supplier, supplierChanged: true });
  };
  handleCancel = (e) => {
    // e.preventDefault();
    this.props.onCancel();
  };
  handleSupplierChange = (e) => {
    let id = e.target.value;
    if (!id.includes("--")) {
      let supplier = this.props.suppliers.filter((d) => {
        return d.id == id;
      });
      if (supplier.length > 0) this.setState({ supplier: supplier[0] });
    } else {
      this.setState({ supplier: null });
    }
  };
  handleSubmit = (e) => {
    e.preventDefault();
    fetch(config.base_url + "/supplier/" + this.state.supplier.id, {
      method: "put",
      headers: { "Content-type": "application/json" },
      body: JSON.stringify(this.state.supplier),
    })
      .then((res) => res.json())
      .then((result) => {
        let error = result.error;
        if (error) this.setState({ complete: true, response: error });
        else
          this.setState({ complete: true, response: result.response }, () => {
            Fetcher.fetchSuppliers()
              .then((suppliers) => {
                this.props.onRefresh(suppliers);
                this.props.onFeedback(true, result.response);
              })
              .catch((e) => {
                this.props.onFeedback(true, result.response);
              });
          });
      })
      .catch((err) => {
        this.setState({ complete: true, response: err });
      });
  };

  render() {
    return (
      <>
        <div className="overlay w-100 flex-row flex-center flex-middle">
          <form
            className="bg-color-white-dark w-50"
            onSubmit={this.handleSubmit}
          >
            <h2>Edit Supplier Details</h2>
            <div className="form-group">
              <label htmlFor="supplier_id">Select supplier name</label>

              <select
                onChange={this.handleSupplierChange}
                className="form-control"
                id="supplier_id"
                name="supplier_id"
              >
                <option>--select--</option>
                {this.props.suppliers.map((d) => {
                  return (
                    <option key={d.id} value={d.id}>
                      {d.name}
                    </option>
                  );
                })}
              </select>
            </div>
            {this.state.supplier !== null ? (
              <>
                <div className="form-group">
                  <label htmlFor="name">supplier Name</label>

                  <input
                    onChange={this.handleChange}
                    className="form-control"
                    type="text"
                    id="name"
                    name="name"
                    value={this.state.supplier.name}
                    placeholder="Enter supplier name"
                  />
                </div>

                <div className="form-group">
                  <label htmlFor="tin">TIN Number</label>

                  <input
                    onChange={this.handleChange}
                    className="form-control"
                    type="text"
                    id="tin"
                    name="tin"
                    value={this.state.supplier.tin}
                    placeholder="Enter supplier's TIN"
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="region">Select Region</label>

                  <select
                    onChange={this.handleChange}
                    className="form-control"
                    id="region"
                    name="region"
                    value={this.state.supplier.region}
                  >
                    {getCityNames("asc").map((city) => {
                      return <option key={city}>{city}</option>;
                    })}
                  </select>
                </div>
                <div className="form-group">
                  <label htmlFor="service">Choose Service</label>

                  <select
                    onChange={this.handleChange}
                    className="form-control"
                    id="service"
                    name="service"
                    value={this.state.supplier.service}
                  >
                    <option>Fuel</option>
                    <option>Maintenance</option>
                  </select>
                </div>
                <div className="form-group">
                  <span className="flex-row flex-space">
                    {this.state.supplierChanged ? (
                      <input
                        className="btn form-control bg-color-accent"
                        type="submit"
                        id="btnUpdate"
                        value="Update"
                      />
                    ) : null}
                    <span
                      id="cancel-user"
                      className="btn text-color-accent"
                      onClick={this.handleCancel}
                    >
                      CANCEL
                    </span>
                  </span>
                </div>
              </>
            ) : null}
          </form>
        </div>{" "}
        {this.state.complete ? (
          <CustomDialog
            title={"Update Supplier Details"}
            onCancel={this.handleCancel}
            msg={this.state.response}
          />
        ) : null}
      </>
    );
  }
}
export default SupplierEditForm;
