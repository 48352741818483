import React from "react";
import Checkbox from "../checkbox";
import Fetcher from "../utils/fetcher";
import Commons from "../utils/commons";

const config = require("../utils/config.json");
class MaintenanceForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hasExtra: false,
      serviceType: 2,
      isVisible: true,
      positions: props.positions,
      vehicles: props.vehicles,
      filteredVehicles: props.vehicles,
      users: props.users,
      projects: props.projects,
      drivers: props.drivers,
      fcodes: props.fundcodes,
      filteredActivities: [],
      filteredCodes: [],
      suppliers: props.suppliers,
      filteredSuppliers: props.suppliers,
      selectedVehicle: undefined,
      region: "",
      total: 0,
      cost: 0,
      selectedParts: [],
      parts: [
        { id: 1, name: "Fuel Filter" },
        { id: 2, name: "Air Filter" },
        { id: 3, name: "Radiator Coolant" },
        { id: 4, name: "Engine Oil" },
        { id: 5, name: "Brake Fluid" },
        { id: 6, name: "Gearbox Oil" },
        { id: 7, name: "Power Steering Fluid" },
        { id: 8, name: "Differential Oil" },
        { id: 9, name: "Clutch Fluid" },
        { id: 10, name: "Other" },
      ],
      rows: [],
      history: [],
      lastRequest: null,
    };
    this.handleCostChange = this.handleCostChange.bind(this);
    this.sendRequest = this.sendRequest.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
    this.handleProjectChange = this.handleProjectChange.bind(this);
    this.handleServiceChange = this.handleServiceChange.bind(this);
    this.handleDriverChange = this.handleDriverChange.bind(this);
    this.handleExtra = this.handleExtra.bind(this);
    this.handleVehicleChange = this.handleVehicleChange.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.showHistory = this.showHistory.bind(this);
    this.handleFundcodeChange = this.handleFundcodeChange.bind(this);
    this.handleCheckbox = this.handleCheckbox.bind(this);
  }

  handleFundcodeChange(event) {
    let fc = event.target.value;
    let activities = Commons.getActivities(fc, this.state.fcodes);
    this.setState({ filteredActivities: activities });
  }

  handleCostChange = (e) => {
    e.preventDefault();
    this.setState({ cost: e.target.value });
  };
  handleChange = (state, text) => {
    let myparts = this.state.selectedParts;
    if (state) {
      if (myparts.includes(text)) {
        myparts[myparts.indexOf(text)] = text;
      } else {
        myparts.push(text);
      }
    } else {
      if (myparts.includes(text)) {
        myparts = myparts.filter((p) => {
          return p !== text;
        });
      }
    }
    this.setState({ selectedParts: myparts });
  };
  handleServiceChange(e) {
    e.preventDefault();
    let value = e.target.value.toLowerCase();
    let hasExtra = value == 4 ? false : true;
    this.setState({ serviceType: value, hasExtra: hasExtra });
    // if(value === "normal car service"){

    // }
  }
  handleExtra(state) {
    this.setState({ hasExtra: state }, () => {
      console.log("handleExtra: ", this.state.hasExtra);
    });
  }
  handleDriverChange = (event) => {
    let driverId = event.target.value;
    let driver = this.state.drivers.filter((d) => {
      return d.id == driverId;
    })[0];

    let vehicles = this.state.vehicles.filter((v) => {
      return v.location.trim() === driver.location.trim();
    });
    let suppliers = this.state.suppliers.filter((s) => {
      return s.region.trim() === driver.location.trim();
    });
    this.setState({
      filteredVehicles: vehicles,
      filteredSuppliers: suppliers,
      selectedVehicle: "",
      history: [],
    });
  };

  handleVehicleChange(e) {
    e.preventDefault();
    let mileage = document.getElementById("mileage");
    let pnumber = e.target.value;
    if (pnumber != "") {
      let vehicles = this.state.filteredVehicles.filter((v) => {
        return v.pnumber === pnumber;
      });
      if (vehicles.length > 0) {
        this.setState({ selectedVehicle: vehicles[0], history: [] }, () => {
          mileage.value = this.state.selectedVehicle.current_mileage;
          let location = vehicles[0].location.trim();
          let localSuppliers = this.state.suppliers.filter((s) => {
            return s.region.trim() === location.trim();
          });
          let suppliers =
            localSuppliers.length > 0 ? localSuppliers : this.props.suppliers;
          this.setState({ region: location.trim(), suppliers: suppliers });
          this.showHistory();
        });
      } else {
        mileage.value = "";
      }
    } else {
      this.setState({ selectedVehicle: undefined, history: [] }, () => {
        this.showHistory();
      });
    }
  }
  handleProjectChange(e) {
    e.preventDefault();
    let project = e.target.value;
    let codes = Commons.getCodes(project, this.props.fundcodes);
    this.setState({ filteredCodes: codes });
  }

  showHistory() {
    if (this.state.selectedVehicle) {
      Fetcher.fetchLastRequest(
        this.state.selectedVehicle.pnumber,
        this.state.serviceType
      )
        .then((request) => {
          let lastRequest = this.state.lastRequest;
          if (request.length > 0) lastRequest = request[0];

          if (this.state.serviceType == 2) {
            Fetcher.fetchVehicleHistory(
              this.state.selectedVehicle.pnumber,
              lastRequest !== null ? lastRequest.last_updated : false
            )
              .then((history) => {
                console.log("history: ", history);
                if (history.length > 0) {
                  let newHistory = Commons.groupByArray(history, "fundcode");
                  let mappedHistory = newHistory.map((h) => {
                    let hist = h;
                    hist.distance = h.values.reduce(
                      (a, b) => a + b.distance,
                      0
                    );
                    hist.project = h.values[0].project;
                    return hist;
                  });
                  let total = mappedHistory.reduce((a, b) => a + b.distance, 0);
                  console.log("total: ", total);
                  this.setState({
                    lastRequest: lastRequest,
                    history: mappedHistory,
                    total: total,
                  });
                } else this.setState({ history: history, total: 0 });
              })
              .catch((err) => {
                console.error("error history: ", err);
              });
          }
        })
        .catch((err) => {
          this.props.onFeedback(false, err);
        });
    }
  }
  componentDidMount() {
    this.setState({ hasExtra: this.props.hasExtra });
    let result = [];
    let tmpResult = [];
    let length = this.state.parts.length;
    let rows = Math.floor(length / 5);
    rows = rows + (length % 5 === 0 ? 0 : 1);
    for (let i = 0; i < rows; i++) {
      let limit = i === 0 ? 0 : i + 4;
      result =
        i + 1 === rows
          ? this.state.parts.splice(0, length - limit)
          : this.state.parts.splice(0, 5);
      tmpResult.push(result);
    }

    this.setState({ rows: tmpResult });
    let user = this.props.user;
    if (this.props.drivers.length > 0) {
      let drivers = this.props.drivers.filter((u) => {
        return this.props.isAdmin
          ? u.position === 2
          : u.position === 2 && u.id === user.id;
      });

      this.setState({ drivers: drivers });
    }

    let localSuppliers = this.props.isAdmin
      ? this.props.suppliers
      : this.props.suppliers.filter((s) => {
          return s.region.trim() === user.location.trim();
        });
    this.setState({
      suppliers: localSuppliers,
      filteredSuppliers: localSuppliers,
    });

    if (this.state.fcodes.length > 0) {
      this.setState({
        filteredCodes: this.state.fcodes,
      });
    }
    let vehicles = this.props.vehicles.filter((v) => {
      return this.props.isAdmin
        ? v.in_use === 0
        : v.in_use === 0 && v.location.trim() === user.location.trim();
    });

    this.setState(
      {
        vehicles: vehicles,
        filteredVehicles: vehicles,
      },
      () => {
        this.showHistory();
      }
    );
  }
  handleCancel() {
    this.props.cancelButtonClick();
  }

  handleSubmit(e) {
    e.preventDefault();
    let st = Number(this.state.serviceType);
    console.log("type: ", st, typeof st);
    let approver = 1;
    let project = "";
    let fundcode = "";
    var parts;
    var admin;
    let description = "Service mileage reached";
    if (st == 2) {
      parts = this.state.selectedParts;
      if (this.state.history.length > 0) {
        let pc = this.state.history[0].project;
        let pj = this.state.projects.filter((p) => {
          return p.code === pc;
        });
        approver = pj[0].coordinator;
        admin = pj[0].department_detail.admin;
      }
    } else {
      parts = document.getElementById("parts").value.split(",");
      description = document.getElementById("reason").value;
    }
    let date = new Date(document.getElementById("date").value).getTime() / 1000;

    if (st != 2) {
      project = document.getElementById("project").value;
      let pjs = this.state.projects.filter((p) => {
        return p.code === project;
      });
      console.log("🚀 ~ MaintenanceForm ~ pjs ~ pjs:", pjs)

      fundcode = document.getElementById("fund_code").value;

      approver = pjs[0].coordinator;
      admin = pjs[0].department_detail.admin;
    }
    let dept = this.props.departments.filter((d) => {
      return d.region.trim() == this.state.region.trim();
    });
    if (this.state.hasExtra) {
      let extra = document.getElementById("extra").value;
      if (parts.includes("Other")) {
        if (extra.includes(",")) {
          let extras = extra.split(",");
          parts = parts.filter((p) => {
            return p !== "Other";
          });
          parts = parts.concat(extras);
        } else {
          parts[parts.indexOf("Other")] = extra;
        }
      }
    }

    let selects = document.getElementsByClassName("cb-activities");
    let activities = [];
    Array.from(selects).forEach((opt) => {
      if (opt.checked) activities.push(opt.value);
    });
    let data = {
      status: 0,
      admin: dept.length > 0 ? dept[0].admin : admin,
      requestType: parseInt(st),
      requestor: parseInt(document.getElementById("driver").value),
      vehicle: document.getElementById("vehicle").value,
      mileage: parseInt(document.getElementById("mileage").value),
      supplier: document.getElementById("supplier").value,
      cost: document.getElementById("cost").value,
      project: project,
      approver: approver,
      fundcode: fundcode,
      category: document.getElementById("category").value,
      activity: activities.join(),
      account: document.getElementById("account").value,
      source: document.getElementById("source").value,
      parts: parts.join(","),
      description: description,
      startTime: date,
      location: this.state.region.trim(),
    };
    if (st == 2) data.history = this.state.history;
    let supplierInput = document.getElementById("supplier");
    data.isNewSupplier = supplierInput.type === "text";

    console.log("data: ", data);
    this.sendRequest(data);
  }
  handleCheckbox = (event) => {
    let dateField = document.getElementById("date");
    if (event.target.checked) {
      const date = new Date();
      let today = Commons.dateFormat(date.getTime() / 1000);
      dateField.type = "text";
      dateField.value = today;
    } else {
      dateField.type = "date";
    }
  };

  sendRequest = (data) => {
    fetch(config.base_url + "/request", {
      method: "post",
      headers: { "Content-type": "application/json" },
      body: JSON.stringify(data),
    })
      .then((res) => res.json())
      .then((result) => {
        if (result.error) {
          this.props.onFeedback(false, result.error);
          this.handleCancel();
        } else {
          Fetcher.fetchRequests(this.props.user)
            .then((requests) => {
              this.props.onRefresh(requests);
              this.props.onFeedback(true, result.response);
            })
            .catch((err) => {
              this.props.onFeedback(true, result.response);
            })
            .finally(() => {
              this.handleCancel();
            });
        }
      })
      .catch((error) => {
        console.error("eeeeerrrror: ", error);
        this.props.onFeedback(false, error);
        this.handleCancel();
      });
  };
  render() {
    return (
      <div
        className={
          "flex-row flex-center center-self" +
          (!this.state.isVisible ? "hidden" : "")
        }
      >
        <form
          name="vehicle-request-form"
          id="vehicle-request-form"
          className="center-self border-all-main"
          onSubmit={this.handleSubmit}
        >
          <h2 className="w-100 bg-color-main">Maintenance Request</h2>

          <div className="w-100 flex-row flex-start">
            <div className="form-group">
              <label htmlFor="driver" className="text-color-accent">
                Select Driver
              </label>
              <select
                id="driver"
                className="form-control"
                onChange={this.handleDriverChange}
              >
                {this.state.drivers.map((user) => {
                  return (
                    <option key={user.id} value={user.id}>
                      {user.fname + " " + user.lname}
                    </option>
                  );
                })}
              </select>
            </div>
            <div className="form-group">
              <label htmlFor="vehicle" className="text-color-accent">
                Select Vehicle
              </label>
              <select
                id="vehicle"
                className="form-control"
                onChange={this.handleVehicleChange}
                defaultValue={this.state.selectedVehicle}
              >
                <option value={""}>--select--</option>
                {this.state.filteredVehicles.map((v) => {
                  return <option key={v.id}>{v.pnumber}</option>;
                })}
              </select>
            </div>
            <div className="form-group">
              <label htmlFor="mileage" className="text-color-accent">
                Mileage (Km)
              </label>
              <input
                className="form-control "
                type="number"
                name="mileage"
                id="mileage"
                placeholder="Enter Mileage in Km"
                defaultValue={
                  this.state.selectedVehicle !== undefined
                    ? this.state.selectedVehicle.current_mileage
                    : 0
                }
              />
            </div>
          </div>

          <div className="flex-row flex-start w-100">
            <div className="form-group">
              <label htmlFor="type" className="text-color-accent">
                Select Maintenance Type
              </label>
              <select
                id="type"
                className="form-control"
                onChange={this.handleServiceChange}
              >
                <option value={2}>Normal Car Service</option>
                <option value={3}>Full Car Service</option>
                <option value={4}>Repair Service</option>
              </select>
            </div>
            <div className="form-group">
              <label htmlFor="supplier" className="text-color-accent">
                Select Supplier
              </label>
              {this.state.filteredSuppliers.length > 0 ? (
                <select id="supplier" className="form-control">
                  <option value="">--select--</option>
                  {this.state.filteredSuppliers.map((supp) => {
                    return (
                      <option key={supp.id} value={supp.id}>
                        {supp.name}
                      </option>
                    );
                  })}
                </select>
              ) : (
                <input
                  type="text"
                  placeholder="Enter supplier name"
                  name="supplier"
                  id="supplier"
                  className="form-control"
                />
              )}
            </div>
            <div className="form-group">
              <label htmlFor="cost" className="text-color-accent">
                Estimated Cost (TSH)
              </label>
              <input
                className="form-control "
                type="number"
                name="cost"
                id="cost"
                placeholder="Enter cost in TSH"
                defaultValue={Commons.formatNumber(this.state.cost)}
                onChange={this.handleCostChange}
              />
            </div>
          </div>
          {this.state.serviceType == 2 ? (
            <div className="form-group">
              <label htmlFor="project" className="text-color-accent">
                Charging Instructions
              </label>
              {this.state.history.length > 0 ? (
                <div className="form-group">
                  {this.state.history.map((h) => {
                    return (
                      <div className="flex-row flex-start" key={h.key}>
                        <input
                          type="text"
                          className="form-control"
                          readOnly={true}
                          defaultValue={h.key + " - " + h.project}
                        />
                        <span className="form-control text-color-accent">
                          {h.distance +
                            " Km (" +
                            ((100 * h.distance) / this.state.total).toFixed(1) +
                            "%) - Cost: Tsh. " +
                            Commons.formatNumber(
                              (
                                (h.distance / this.state.total) *
                                this.state.cost
                              ).toFixed(1)
                            )}
                        </span>
                      </div>
                    );
                  })}
                </div>
              ) : (
                <div className="flex-row flex-start">
                  <div className="form-group">
                    <label htmlFor="project" className="text-color-accent">
                      Project
                    </label>
                    <select
                      id="project"
                      className="form-control"
                      onChange={this.handleProjectChange}
                    >
                      <option>--select--</option>
                      {this.state.projects.map((pj) => {
                        return <option key={pj.id}>{pj.code}</option>;
                      })}
                    </select>
                  </div>
                  <div className="form-group">
                    <label htmlFor="fund_code" className="text-color-accent">
                      Fund Code
                    </label>
                    <select
                      id="fund_code"
                      className="form-control"
                      onChange={this.handleFundcodeChange}
                    >
                      <option>--select--</option>
                      {this.state.filteredCodes.map((fc) => {
                        return <option key={fc.id}>{fc.code}</option>;
                      })}
                    </select>
                  </div>
                </div>
              )}
            </div>
          ) : (
            <div className="flex-row flex-start">
              <div className="form-group">
                <label htmlFor="project" className="text-color-accent">
                  Project
                </label>
                <select
                  id="project"
                  className="form-control"
                  onChange={this.handleProjectChange}
                >
                  <option>--select--</option>
                  {this.state.projects.map((pj) => {
                    return <option key={pj.id}>{pj.code}</option>;
                  })}
                </select>
              </div>
              <div className="form-group">
                <label htmlFor="fund_code" className="text-color-accent">
                  Fund Code
                </label>
                <select
                  id="fund_code"
                  className="form-control"
                  onChange={this.handleFundcodeChange}
                >
                  <option>--select--</option>
                  {this.state.filteredCodes.map((fc) => {
                    return <option key={fc.id}>{fc.code}</option>;
                  })}
                </select>
              </div>
              <div className="form-group">
                <label htmlFor="activity" className="text-color-accent">
                  Activities
                </label>
                <div className="form-group flex-row flex-start">
                  {this.state.filteredActivities.map((a) => {
                    return (
                      <span key={"lb" + a} className=" flex-row flex-even">
                        <input
                          className="form-control cb-activities"
                          type="checkbox"
                          id={"cb" + a}
                          value={a}
                        />
                        <label htmlFor={"cb" + a} className="text-color-accent">
                          {a}
                        </label>
                      </span>
                    );
                  })}
                </div>
              </div>
            </div>
          )}

          <div className="flex-row flex-start w-100">
            <div className="form-group">
              <label htmlFor="account" className="text-color-accent">
                Account
              </label>
              <input
                type="text"
                id="account"
                name="account"
                className="form-control"
              />
            </div>
            <div className="form-group">
              <label htmlFor="source" className="text-color-accent">
                Source
              </label>
              <input
                type="text"
                id="source"
                name="source"
                className="form-control"
              />
            </div>
            <div className="form-group">
              <label htmlFor="category" className="text-color-accent">
                Category
              </label>
              <input type="text" id="category" className="form-control" />
            </div>
          </div>
          <div className="flex-row flex-start w-100">
            <div className="form-group">
              <label htmlFor="date" className="text-color-accent">
                Date Required
              </label>
              <input
                type="date"
                id="date"
                name="date"
                className="form-control"
              />
            </div>
            <div className="form-group">
              <label htmlFor="today" className="form-label text-color-accent">
                Today
              </label>
              <input
                onChange={this.handleCheckbox}
                className="form-control"
                type="checkbox"
                name="today"
                id="today"
              />
            </div>
          </div>

          {this.state.serviceType == 2 ? (
            <div className="form-group">
              <label htmlFor="parts" className="text-color-accent">
                Select Parts
              </label>

              <div className="form-group border-all-main">
                {this.state.rows.map((row, index) => {
                  return (
                    <div className="flex-row flex-start" key={index}>
                      {row.map((part) => {
                        //  if (index > 5) return;
                        return (
                          <Checkbox
                            extra={(state) => this.handleExtra(state)}
                            text={part.name}
                            key={part.id}
                            id={part.id}
                            onChange={(state, value) =>
                              this.handleChange(state, value)
                            }
                          />
                        );
                      })}
                    </div>
                  );
                })}
                {this.state.hasExtra ? (
                  <div className="form-group">
                    <label htmlFor="extra" className="text-color-accent">
                      Other
                    </label>
                    <input
                      type="text"
                      id="extra"
                      name="extra"
                      className="form-control"
                    />
                  </div>
                ) : null}
              </div>
            </div>
          ) : this.state.serviceType == 4 ? (
            <>
              <div className="form-group">
                <label htmlFor="reason" className="text-color-accent">
                  Required Parts
                </label>
                <input
                  type="text"
                  id="parts"
                  name="parts"
                  className="form-control"
                />
              </div>

              <div className="form-group">
                <label htmlFor="reason" className="text-color-accent">
                  Describe the problem
                </label>
                <textarea
                  id="reason"
                  name="reason"
                  className="form-control"
                  rows="10"
                ></textarea>
              </div>
            </>
          ) : (
            <>
              <div className="form-group">
                <label htmlFor="reason" className="text-color-accent">
                  Required Parts
                </label>
                <input
                  type="text"
                  id="parts"
                  name="parts"
                  className="form-control"
                />
              </div>

              <div className="form-group">
                <label htmlFor="reason" className="text-color-accent">
                  Provide a reason
                </label>
                <textarea
                  id="reason"
                  name="reason"
                  className="form-control"
                  rows="10"
                ></textarea>
              </div>
            </>
          )}
          <div className="form-group">
            <span className="flex-row flex-space">
              <input
                className="btn form-control bg-color-accent"
                type="submit"
                id="submit"
                value="Submit"
              />
              <span
                id="cancel-user"
                className="btn text-color-accent"
                onClick={this.handleCancel}
              >
                CANCEL
              </span>
            </span>
          </div>
        </form>
      </div>
    );
  }
}
export default MaintenanceForm;
