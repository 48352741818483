import React from "react";
import xlsExport from "xlsexport";
import {
  VictoryBar,
  VictoryChart,
  VictoryAxis,
  VictoryTheme,
  VictoryPie,
  VictoryLine,
  VictoryLabel,
  VictoryGroup,
  VictoryLegend,
  Border
} from "victory";
import DataTable from "react-data-table-component";

class PerformaceListReport extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      reportFormat: "",
      chartOptions: {},
      isMounted: false,
      data: [],
      labels: [],
      distanceData: [],
      fuelData: [],
      fuelCostData: [],
      maintenanceData: [],
      labels: [],
      distanceFuelData: [],
      distanceCostData: [],
      totalCostData: [],
      title: "",
      unit: "",
      report: []
    };
    this.findTotal = this.findTotal.bind(this);
    this.findTotalDistance = this.findTotalDistance.bind(this);
    this.getFullName = this.getFullName.bind(this);
    this.formatDate = this.formatDate.bind(this);
    this.formatNumber = this.formatNumber.bind(this);
    this.groupByArray = this.groupByArray.bind(this);
    this.exportToXls = this.exportToXls.bind(this);
    this.generateChart = this.generateChart.bind(this);
  }
  componentDidMount() {
    console.log("props: ", this.props.dependent, this.props.title);

    let report = this.props.report;
    this.setState(
      {
        report: report,
        reportFormat: this.props.reportFormat,
        title: this.props.title
      },
      () => {
        console.log("state: ", this.state.report);
        this.generateChart();
      }
    );
  }
  componentWillUnmount() {
    this.setState({ isMounted: false });
  }
  generateChart = () => {
    let type = this.state.reportFormat.toLowerCase();
    if (type !== "" && type !== "list") {
      let myData = this.props.report;
      console.log("grouped: ", myData);
      let labels = myData.map(d => {
        return d.key;
      });

      let distanceCostData = myData.map(d => {
        let data = {};
        data.x = d.key;
        data.y =
          d.distance === 0 ? 0 : (d.fuel_cost + d.maintenance) / d.distance;
        return data;
      });
      let distanceFuelData = myData.map(d => {
        let data = {};
        data.x = d.key;
        data.y = d.fuel_amount === 0 ? 0 : d.distance / d.fuel_amount;
        return data;
      });
      let totalCostData = myData.map(d => {
        let data = {};
        data.x = d.key;
        data.y = d.fuel_cost + d.maintenance;
        return data;
      });

      let distanceData = myData.map(d => {
        let data = {};
        data.x = d.key;
        data.y = d.distance;
        return data;
      });

      let fuelData = myData.map(d => {
        let data = {};
        data.x = d.key;
        data.y = d.fuel_amount;
        return data;
      });
      let fuelCostData = myData.map(d => {
        let data = {};
        data.x = d.key;
        data.y = d.fuel_cost;
        return data;
      });
      let maintenanceData = myData.map(d => {
        let data = {};
        data.x = d.key;
        data.y = d.maintenance;
        return data;
      });

      // console.log("datasource: ", sourcedata);
      this.setState(
        {
          isMounted: true,
          distanceData: distanceData,
          fuelData: fuelData,
          fuelCostData: fuelCostData,
          maintenanceData: maintenanceData,
          labels: labels,
          distanceFuelData: distanceFuelData,
          distanceCostData: distanceCostData,
          totalCostData: totalCostData
        },
        () => {
          let title = this.props.title;
          let data = this.state.data;
          let unit = "";
          switch (this.props.dependent) {
            case "distance":
              title = "Distance Covered";
              data = this.state.distanceData;
              unit = " Km";
              break;
            case "fuel":
              title = "Fuel Consumption";
              data = this.state.fuelData;
              unit = " lt";
              break;
            case "fuel_cost":
              title = "Cost of Fuel";
              data = this.state.fuelCostData;
              unit = " Tsh";
              break;
            case "distance_fuel":
              title = "Average Fuel Consumption";
              data = this.state.distanceFuelData;
              unit = " Km/Lt";
              break;
            case "total_cost":
              title = "Total Cost (Fuel and Maintenance)";
              data = this.state.totalCostData;
              unit = " Tsh";
              break;
            case "maintenance":
              title = "Cost of Maintenance";
              data = this.state.maintenanceData;
              unit = " Tsh";
              break;
            case "distance_cost":
              title = "Average Cost per Kilometer";
              data = this.state.distanceCostData;
              unit = " Tsh/Km";
              break;
          }
          this.setState({ title: title, data: data, unit: unit });
        }
      );
    } else {
      let source = this.props.report;
      this.setState({ report: source });
    }
  };
  formatNumber = num => {
    if (typeof num === "string") num = parseFloat(num);
    return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
  };
  exportToXls = () => {
    let data = this.props.report.map(r => {
      let rep = {
        vehicle: r.key,
        distance: r.distance,
        fuel_amount: r.fuel_amount,
        fuel_cost: r.fuel_cost,
        maintenance_cost: r.maintenance,
        total_cost: r.fuel_cost + r.maintenance,
        fuel_consumption: r.fuel_amount === 0 ? 0 : r.distance / r.fuel_amount,
        cost_per_kilometer:
          r.distance === 0 ? 0 : (r.fuel_cost + r.maintenance) / r.distance
      };

      return rep;
    });
    let xls = new xlsExport(data, this.props.title);
    xls.exportToXLS("performance_report.xls");
  };
  groupByArray = (xs, key) => {
    let result = xs.reduce((rv, x) => {
      let v = key instanceof Function ? key(x) : x[key];
      let el = rv.find(r => r && r.key === v);
      if (el) {
        el.values.push(x);
      } else {
        rv.push({ key: v, values: [x] });
      }
      return rv;
    }, []);

    console.log("grouped by: ", result);
    return result;
  };

  exportToPdf = () => {
    let xls = new xlsExport(this.state.report, this.props.title);

    xls.exportToPDF("fuel_report.pdf");
  };
  formatDate = seconds => {
    let months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec"
    ];
    let dateObject = new Date(seconds * 1000);
    let date =
      dateObject.getDate() +
      " " +
      months[dateObject.getMonth()] +
      " " +
      dateObject.getFullYear();

    return date;
  };
  findTotalDistance = () => {
    let total = this.props.report.reduce((a, b) => a + b.distance, 0);

    return total;
  };
  findTotal = () => {
    let total = this.props.report.reduce(
      (a, b) => a + (b.fuel_cost + b.maintenance),
      0
    );

    return total;
  };
  getFullName = userid => {
    if (userid === 1) return "System Admin";
    let user = this.props.users.filter(u => {
      return u.id === userid;
    });
    if (user.length > 0) return user[0].fname + " " + user[0].lname;

    return userid;
  };
  render() {
    return (
      <div className="center-self">
        <h2>{"Performance Report"}</h2>
        <p>
          {this.formatDate(this.props.dateRange.start) +
            " to " +
            this.formatDate(this.props.dateRange.end)}
        </p>
        <h3>{this.state.title}</h3>
        <div className="content-list">
          {this.state.reportFormat.toLowerCase() === "list" &&
          !this.state.isMounted ? (
            <DataTable
              pagination={true}
              theme="default"
              columns={[
                { name: "Vehicle", selector: "key", sortable: true },
                {
                  name: "Fuel (ltr)",
                  selector: "fuel_amount",
                  sortable: true,
                  format: r => this.formatNumber(r.fuel_amount)
                },
                {
                  name: "Cost of Fuel (Tsh)",
                  selector: "fuel_cost",
                  sortable: true,
                  format: r => this.formatNumber(r.fuel_cost)
                },
                {
                  name: "Cost of Maintenance (Tsh)",
                  selector: "maintenance",
                  sortable: true,
                  format: r => this.formatNumber(r.maintenance)
                },
                {
                  name: "Distance (Km)",
                  selector: "distance",
                  sortable: true,
                  format: r => this.formatNumber(r.distance)
                },
                {
                  name: "Consumption (Km/ltr)",
                  selector: r =>
                    this.formatNumber((r.distance / r.fuel_amount).toFixed(1)),
                  sortable: true
                },
                {
                  name: "Fuel Cost per Km (Tsh/Km)",
                  selector: r =>
                    this.formatNumber((r.fuel_cost / r.distance).toFixed(1)),
                  sortable: true
                },
                {
                  name: "Maintenance Cost per Km (Tsh/Km)",
                  selector: r =>
                    this.formatNumber((r.maintenance / r.distance).toFixed(1)),
                  sortable: true
                },
                {
                  name: "Avg Cost per Km (Tsh/Km)",
                  selector: r =>
                    this.formatNumber(
                      ((r.maintenance + r.fuel_cost) / r.distance).toFixed(1)
                    ),
                  sortable: true
                }
              ]}
              data={this.state.report}
            />
          ) : (
            <div className="center-self flex-row flex-center w-100 border-all-main">
              {this.state.reportFormat.toLowerCase() === "column" ? (
                <div className="w-80">
                  <VictoryChart
                    domainPadding={20}
                    theme={VictoryTheme.material}
                  >
                    <VictoryAxis
                      label={"Vehicle"}
                      style={{
                        axis: { stroke: "#756f6a" },
                        axisLabel: { fontSize: 10, padding: 30 },

                        ticks: { stroke: "grey", size: 5 },
                        tickLabels: { fontSize: 10, padding: 10 }
                      }}
                      tickValues={[]}
                      tickFormat={this.state.labels}
                      tickLabelComponent={
                        <VictoryLabel
                          angle={30}
                          style={{ fontSize: "6px", wordWrap: "break-word" }}
                        />
                      }
                    />
                    <VictoryAxis
                      dependentAxis
                      tickFormat={x => {
                        return x;
                      }}
                      label={this.state.title + " in " + this.state.unit}
                      style={{
                        axis: { stroke: "#756f6a" },
                        axisLabel: { fontSize: 10, padding: 40 },

                        ticks: { stroke: "grey", size: 5 },
                        tickLabels: { fontSize: 10, padding: 5 }
                      }}
                      tickLabelComponent={
                        <VictoryLabel
                          angle={0}
                          style={{ fontSize: "6px", wordWrap: "break-word" }}
                        />
                      }
                    />

                    <VictoryBar
                      data={this.state.data}
                      labels={({ datum }) =>
                        this.formatNumber(datum.y) + this.state.unit
                      }
                      style={{ labels: { fontSize: "6px" } }}
                      x="x"
                      y="y"
                      tickLabelComponent={
                        <VictoryLabel
                          angle={0}
                          style={{ fontSize: "6px", wordWrap: "break-word" }}
                        />
                      }
                    />
                  </VictoryChart>
                </div>
              ) : this.state.reportFormat.toLowerCase() === "pie" ? (
                <div className="w-80">
                  <svg className="center-self w-100">
                    <VictoryLegend
                      standalone={false}
                      x={20}
                      y={40}
                      orientation="horizontal"
                      gutter={20}
                      title="Legend"
                      borderComponent={<Border width="90%" />}
                      borderPadding={5}
                      colorScale="qualitative"
                      centerTitle
                      style={{
                        border: { stroke: "black" },
                        textAlign: "center"
                      }}
                      data={this.state.labels.map(d => {
                        let r = {};
                        r.name = d;
                        return r;
                      })}
                    />
                  </svg>

                  <VictoryPie
                    padAngle={3}
                    padding={{ left: 80, right: 80 }}
                    data={this.state.data}
                    theme={VictoryTheme.material}
                    labels={({ datum }) =>
                      (datum.y < 1000
                        ? this.formatNumber(datum.y.toFixed(1))
                        : this.formatNumber((datum.y / 1000).toFixed(1)) +
                          "k ") + this.state.unit
                    }
                    innerRadius={25}
                    labelPosition="centroid"
                    style={{ labels: { fontSize: "6px" } }}
                    labelComponent={
                      <VictoryLabel
                        angle={45}
                        style={{ fontSize: "6px", wordWrap: "break-word" }}
                      />
                    }
                    style={{
                      data: {
                        stroke: "#c43a31",
                        strokeWidth: 0,
                        strokeLinecap: "round"
                      }
                    }}
                  />
                </div>
              ) : this.state.reportFormat.toLowerCase() === "line" ? (
                <div className="w-80">
                  <VictoryChart theme={VictoryTheme.material}>
                    <VictoryAxis
                      label={this.state.title + " in " + this.state.unit}
                      dependentAxis
                      tickFormat={x => {
                        return x < 1000 ? x : (x / 1000).toFixed(1) + "k";
                      }}
                      style={{
                        axis: { stroke: "#756f6a" },
                        axisLabel: { fontSize: 10, padding: 40 }
                      }}
                      tickLabelComponent={
                        <VictoryLabel
                          angle={0}
                          style={{ fontSize: "6px", wordWrap: "break-word" }}
                        />
                      }
                    />
                    <VictoryAxis
                      label={"Vehicle"}
                      style={{
                        axis: { stroke: "#756f6a" },
                        axisLabel: { fontSize: 10, padding: 30 },
                        grid: {
                          stroke: ({ tick }) => (tick > 0.5 ? "red" : "grey")
                        },
                        ticks: { stroke: "grey", size: 5 },
                        tickLabels: { fontSize: 15, padding: 5 }
                      }}
                      tickValues={[]}
                      tickFormat={this.state.labels}
                      tickLabelComponent={
                        <VictoryLabel
                          angle={30}
                          style={{ fontSize: "6px", wordWrap: "break-word" }}
                        />
                      }
                    />
                    <VictoryLine
                      style={{
                        data: {
                          stroke: "#c43a31",
                          strokeWidth: 1,
                          strokeLinecap: "round"
                        }
                      }}
                      data={this.state.data}
                    />
                  </VictoryChart>
                </div>
              ) : null}
            </div>
          )}
        </div>
        <div className="form-group">
          <span className="flex-row flex-space">
            {this.state.reportFormat.toLowerCase() === "list" ? (
              <input
                className="btn form-control btn-success"
                type="button"
                id="exportXls"
                value="Export to Excel"
                onClick={this.exportToXls}
              />
            ) : null}
            {/* <input
            className="btn form-control btn-error"
            type="button"
            id="exportPdf"
            value="Export to PDF"
            onClick={exportToPdf}
          /> */}

            <span
              id="cancel-user"
              className="btn text-color-accent"
              onClick={() => this.props.cancelButtonClick()}
            >
              CLOSE
            </span>
          </span>
        </div>
      </div>
    );
  }
}
export default PerformaceListReport;
