import React from "react";
import logo from "../images/care-logo.png";
import Dashboard from "./dashboard";

const config = require("../utils/config.json");

class PasswordResetForm extends React.Component {
  constructor(props) {
    super(props);
    this.handleForm = this.handleForm.bind(this);
    this.state = {
      hasError: false,
      errorMsg: "Invalid username or password",
      user: undefined
    };
  }
  handleForm = e => {
    e.preventDefault();
    let inputs = Array.from(e.target.elements);
    inputs = inputs.filter(i => {
      return i.id !== "btn-submit";
    });

    let email = inputs[0].value;

    fetch(config.base_url + "/reset_password/" + email, {
      method: "GET",
      headers: { "Content-type": "application/json" }
    })
      .then(res => res.json())
      .then(result => {
        this.setState({
          hasError: true,
          errorMsg: result.error ? result.error : result.response
        });

        // window.location.pathname = "/admin";
      })
     
      .catch(err => {
        this.setState({
          hasError: true,
          errorMsg: err
        });
      });
  };
  render() {
    if (this.state.user !== undefined) {
      // window.location.pathname = "/admin";
      return <Dashboard user={this.state.user} />;
    }
    return (
      <main>
        <div className="left-col">
          <img
            className="logo-large"
            src={logo}
            alt="care international logo"
          />
          <div className="branding">
            <p>Vehicle Management System</p>
          </div>
        </div>
        <div className="bg-color-main-dark right-col">
          <form name="form-login" onSubmit={this.handleForm}>
            {this.state.hasError ? (
              <div id="error" className="error">
                {this.state.errorMsg}
              </div>
            ) : null}
            <h2 className="flex-row flex-center">Password Reset</h2>
            <div className="form-group">
              <label className="form-label text-white" htmlFor="username">
                E-mail
              </label>
              <input
                className="form-control"
                type="email"
                id="email"
                placeholder="Enter your registered email address"
              />
            </div>

            <div id="forgot" className="form-group">
              <a href="/">Back to login</a>
            </div>
            <div className="form-group">
              <input
                className="form-control btn bg-color-white-dark text-color-accent"
                type="submit"
                id="btn-submit"
                value="Reset Password"
              />
            </div>
          </form>
          <div className="bottom-detail">
            <p>&copy; All rights reserved</p>
          </div>
        </div>
      </main>
    );
  }
}
export default PasswordResetForm;
