import React from "react";
import CustomDialog from "./custom_dialog";
const config = require("../utils/config.json");

class ProjectDeleteForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      description: "",
      comment: "",
      complete: false,
      response: ""
    };

    this.handleCancel = this.handleCancel.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  handleCancel = e => {
    // e.preventDefault();
    this.props.onCancel();
  };
  componentDidMount = () => {
    let pj = this.props.projects[0];
    this.setState({ description: pj.name, comment: pj.comment });
  };
  handleChange = e => {
    let id = parseInt(e.target.value);

    let projects = this.props.projects.filter(p => {
      return p.id === id;
    });

    if (projects.length > 0)
      this.setState(
        { description: projects[0].name, comment: projects[0].comment },
        () => {}
      );
  };
  handleSubmit = e => {
    e.preventDefault();
    let pid = document.getElementById("project_id").value;
    let comment = document.getElementById("comment");
    if (comment.value != null && comment.value.length > 0) {
      let body = { comment: comment.value };
      fetch(config.base_url + "/project/" + pid, {
        method: "delete",
        body: JSON.stringify(body),
        headers: { "Content-type": "application/json" }
      })
        .then(res => res.json())
        .then(result => {
          let error = result.error;
          if (error) this.setState({ complete: true, response: error });
          else this.setState({ complete: true, response: result.response });
        })
        .catch(err => {
          this.setState({ complete: true, response: err });
        });
    } else {
      comment.classList.add("fail");
      comment.focus();
      return;
    }
  };

  render() {
    return (
      <>
        <div className="overlay w-100 flex-row flex-center flex-middle">
          <form
            className="bg-color-white-dark w-50"
            onSubmit={this.handleSubmit}
          >
            <h2>Delete Project</h2>
            <div className="form-group">
              <label htmlFor="project_id">Select Project Code/ID</label>

              <select
                onChange={this.handleChange}
                className="form-control"
                id="project_id"
                name="project_id"
              >
                {this.props.projects.map(p => {
                  return (
                    <option key={p.id} value={p.id}>
                      {p.code}
                    </option>
                  );
                })}
              </select>
            </div>
            <div className="form-group">
              <label htmlFor="description">{this.state.description}</label>
            </div>
            <div className="form-group">
              <label htmlFor="comment">Reason for Deletion</label>

              <input
                onChange={this.handleChange}
                className="form-control"
                type="text"
                id="comment"
                name="comment"
                value={this.state.comment}
                placeholder="Enter a reason for deleting this project"
              />
            </div>
            <div className="form-group">
              <span className="flex-row flex-space">
                <input
                  className="btn form-control btn-error"
                  type="submit"
                  id="btndelete"
                  value="Delete"
                />
                <span
                  id="cancel"
                  className="btn text-color-accent"
                  onClick={this.handleCancel}
                >
                  CANCEL
                </span>
              </span>
            </div>
          </form>
        </div>
        {this.state.complete ? (
          <CustomDialog
            title={"Delete Project"}
            onCancel={this.handleCancel}
            msg={this.state.response}
          />
        ) : null}
      </>
    );
  }
}
export default ProjectDeleteForm;
