import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
// import { Component } from "react";
import LoginForm from "./loginForm";
import Dashboard from "./dashboard";
import NotFound from "./not_found";
import PasswordResetForm from "./password_reset";

class CustomRouter extends React.Component {
  constructor(props) {
    super(props);
    // this.state = { user: { id: "1", role: "admin" } };
  }
  render() {
    return (
      <Router>
        <Routes>
          <Route exact stric path="/" element={<LoginForm />} />
          <Route exact strict
            path="/dashboard"
            element={<Dashboard />} 
          />
          <Route exact strict path="/signout" element={<LoginForm />} />
          <Route exact strict path="/password_reset" element={<PasswordResetForm />} />
          {/* <Route element={<NotFound />} /> */}
        </Routes>
      </Router>
    );
  }
}
export default CustomRouter;
// export const routes = [
//   {
//     path:"/",element: <LoginForm />
//   },
//   {path:"/sign_out",element:<LoginForm />},
//   {path:"/password_reset",element:<PasswordResetForm />},
//   {path:"/dashboard",element:<Dashboard />},
//   {path:"*",element:<NotFound />}
// ]
