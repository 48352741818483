import React from "react";
import { getCityNames } from "postcodes-tz";
import Fetcher from "../utils/fetcher.js";
import Commons from "../utils/commons.js";
import CustomDialog from "./custom_dialog.js";

const config = require("../utils/config.json");

class VehicleDetailForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      errors: [],
      isVisible: true,
      projects: this.props.projects,
      fcodes: this.props.fundcodes,
      filteredCodes: [],
      fundcode: props.vehicle.fundcode,
      vehicle: props.vehicle,
      selectedProject: null,
      isAuthorized: false,
      confirmDelete: false,
    };

    this.handleDateChange = this.handleDateChange.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
    this.handleProjectChange = this.handleProjectChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleNewForm = this.handleNewForm.bind(this);
    this.handleBlur = this.handleBlur.bind(this);
    this.handleFocus = this.handleFocus.bind(this);
    this.handleFundcodeChange = this.handleFundcodeChange.bind(this);
    this.refresh = this.refresh.bind(this);
    this.deleteVehicle = this.deleteVehicle.bind(this);
    this.handleDeleteVehicle = this.handleDeleteVehicle.bind(this);
  }

  refresh = (msg) => {
    Fetcher.fetchVehicles()
      .then((vehicles) => {
        this.props.onRefresh(vehicles);
      })
      .catch((err) => {
        this.props.onFeedback(false, "Could not refresh vehicle list");
      })
      .finally(() => {
        this.props.onFeedback(true, msg);
        this.handleCancel();
      });
  };
  deleteVehicle = () => {
    let vehicle = this.props.vehicle;
    console.log("deleting vehicle...", vehicle.pnumber);
    fetch(config.base_url + "/vehicle/" + vehicle.id, {
      method: "DELETE",
      headers: { "Content-type": "application/json" },
    })
      .then((res) => res.json())
      .then((response) => {
        this.refresh(response.response);
      });
    this.handleCancel();
  };
  handleDeleteVehicle = () => {
    console.log("showing dialog...");
    this.setState({ confirmDelete: true });
  };
  handleFundcodeChange = (e) => {
    this.setState({ fundcode: e.target.value });
  };
  handleProjectChange(e) {
    e.preventDefault();
    let project = e.target.value;
    let pj = this.props.projects.filter((p) => {
      return p.code === project;
    });
    if (pj.length > 0) {
      let codes = Commons.getCodes(project, this.props.fundcodes);
      this.setState({
        selectedProject: pj[0],
        filteredCodes: codes,
      });
    }
  }

  componentDidMount() {
    console.log("selected ve: ", this.props.vehicle);
    let user = this.props.user;

    this.setState({ isAuthorized: user.position === 1 || user.position === 6 });
    let project = this.props.projects.filter((p) => {
      return p.code === this.props.vehicle.project;
    });
    let selectedProject = {};
    if (project.length > 0) selectedProject = project[0];

    let codes = Commons.getCodes(
      this.props.vehicle.project,
      this.props.fundcodes
    );
    this.setState({
      vehicle: this.props.vehicle,
      selectedProject: selectedProject,
      selectedDate: this.props.vehicle.a_date,
      fundcode: this.props.vehicle.fundcode,
      filteredCodes: codes,
    });
  }
  handleNewForm() {
    this.setState({ isVisible: true });
  }

  handleDateChange(e) {
    console.log("selected date: ", e.target.value);
    let date = new Date(e.target.value);
    let secs = date.getTime() / 1000;
    this.setState({ selectedDate: secs });
    e.target.type = "text";
  }
  handleFocus(event) {
    event.target.type = "date";
  }
  handleBlur(event) {
    console.log("selected date: ", this.state.selectedDate);
    event.target.type = "text";
    event.target.value = Commons.dateFormat(this.state.selectedDate);
  }

  handleSubmit(e) {
    e.preventDefault();
    console.log("submitting form...");
    let reg = document.getElementById("reg_no");
    let chassis = document.getElementById("chassis");
    let make = document.getElementById("make");
    let model = document.getElementById("model");
    let year = document.getElementById("year");
    let mileage = document.getElementById("mileage");
    let cmileage = document.getElementById("cmileage");
    let nmileage = document.getElementById("nmileage");
    let donor = document.getElementById("donor");
    let project = document.getElementById("project");
    let fundcode = document.getElementById("fundcode");
    let cost = document.getElementById("cost");
    let date = document.getElementById("date");
    let location = document.getElementById("location");
    let vehicle = {
      in_use: 0,
      pnumber: reg.value,
      chassis_no: chassis.value,
      brand: make.value,
      model: model.value,
      year: parseInt(year.value),
      mileage: parseInt(mileage.value),
      current_mileage: parseInt(cmileage.value),
      next_service_mileage: parseInt(nmileage.value),
      donor: donor.value,
      project: project.value,
      fundcode: fundcode.value,
      a_cost: cost.value,
      a_date: new Date(date.value).getTime() / 1000,
      location: location.value,
    };
    let old_vehicle = this.props.vehicle;
    let newKeys = Object.keys(vehicle).filter((key) => {
      return old_vehicle[key] !== vehicle[key];
    });
    let newVehicle = {};
    newKeys.forEach((k) => {
      newVehicle[k] = vehicle[k];
    });
    console.log("newVehicle: ", newVehicle);
    this.save(newVehicle);
  }
  save(vehicle) {
    fetch(config.base_url + "/vehicle/" + this.props.vehicle.id, {
      method: "put",
      headers: { "Content-type": "application/json" },
      body: JSON.stringify(vehicle),
    })
      .then((res) => res.json())
      .then((result) => {
        if (result.error) {
          this.props.onFeedback(false, result.error);
          this.handleCancel();
        } else {
          this.refresh(result.response);
        }
      })
      .catch((error) => {
        console.log("error: ", error);
        this.props.onFeedback(false, error.error);
      });
  }

  handleCancel() {
    console.log("Cancel form...");
    this.props.cancelButtonClick(false);
  }
  render() {
    return (
      <div className="flex-row flex-center center-self ">
        <form
          name="new-vehicle-form"
          id="new-vehicle-form"
          className="flex-center border-all-main"
          onSubmit={this.handleSubmit}
        >
          <h2 className="w-100 bg-color-main">Vehicle Details</h2>

          <div className="w-100 flex-row flex-start">
            <div className="form-group">
              <label className="text-color-accent" htmlFor="reg_no">
                Registration Number
              </label>
              <input
                readOnly={!this.state.isAuthorized}
                className="form-control "
                type="text"
                name="reg_no"
                id="reg_no"
                placeholder="Enter plate number"
                defaultValue={this.state.vehicle.pnumber}
              />
            </div>
            <div className="form-group">
              <label htmlFor="make" className=" text-color-accent">
                Make/Brand
              </label>
              <input
                readOnly={!this.state.isAuthorized}
                className="form-control "
                type="text"
                name="make"
                id="make"
                placeholder="Make"
                defaultValue={this.state.vehicle.brand}
              />
            </div>
            <div className="form-group">
              <label htmlFor="model" className=" text-color-accent">
                Model
              </label>
              <input
                readOnly={!this.state.isAuthorized}
                className="form-control "
                type="text"
                name="model"
                id="model"
                placeholder="Vehicle Model"
                defaultValue={this.state.vehicle.model}
              />
            </div>
          </div>
          <div className="flex-row flex-start w-100">
            <div className="form-group">
              <label htmlFor="year" className=" text-color-accent">
                Year of Manufacture
              </label>
              <select
                readOnly={!this.state.isAuthorized}
                defaultValue={this.state.vehicle.year}
                className="form-control "
                name="year"
                id="year"
              >
                {[...Array(30).keys()].map((y) => {
                  return (
                    <option key={y}>{new Date().getFullYear() - y}</option>
                  );
                })}
              </select>
            </div>
            <div className="form-group">
              <label htmlFor="chassis" className=" text-color-accent">
                Chassis Number
              </label>
              <input
                readOnly={!this.state.isAuthorized}
                className="form-control "
                type="text"
                name="chassis"
                id="chassis"
                defaultValue={this.state.vehicle.chassis_no}
              />
            </div>
          </div>
          <div className="flex-row flex-start w-100">
            <div className="form-group">
              <label htmlFor="mileage" className=" text-color-accent">
                Initial Mileage (Km)
              </label>
              <input
                readOnly={!this.state.isAuthorized}
                className="form-control "
                type="number"
                name="mileage"
                id="mileage"
                placeholder="Enter mileage in Km"
                defaultValue={this.state.vehicle.mileage}
              />
            </div>
            <div className="form-group">
              <label htmlFor="cmileage" className=" text-color-accent">
                Current Mileage (Km)
              </label>
              <input
                readOnly={!this.state.isAuthorized}
                className="form-control "
                type="number"
                name="cmileage"
                id="cmileage"
                placeholder="Enter mileage in Km"
                defaultValue={this.state.vehicle.current_mileage}
              />
            </div>
            <div className="form-group">
              <label htmlFor="nmileage" className=" text-color-accent">
                Next Service Mileage (Km)
              </label>
              <input
                readOnly={!this.state.isAuthorized}
                className="form-control "
                type="number"
                name="nmileage"
                id="nmileage"
                placeholder="Enter mileage in Km"
                defaultValue={this.state.vehicle.next_service_mileage}
              />
            </div>
          </div>

          <div className="flex-row flex-start w-100">
            <div className="form-group" id="donnor-group">
              <label htmlFor="donor" className=" text-color-accent">
                Donnor
              </label>
              <input
                readOnly={!this.state.isAuthorized}
                className="form-control "
                type="text"
                name="donor"
                id="donor"
                placeholder="World Bank"
                defaultValue={this.state.vehicle.donor}
              />
            </div>
            <div className="form-group">
              <label htmlFor="project" className=" text-color-accent">
                Project
              </label>
              <select
                readOnly={!this.state.isAuthorized}
                onChange={this.handleProjectChange}
                className="form-control"
                name="project"
                id="project"
                value={
                  this.state.selectedProject != null
                    ? this.state.selectedProject.code
                    : this.state.vehicle.project
                }
              >
                {this.state.selectedProject == null ? (
                  <option>Select Project</option>
                ) : null}
                {this.state.projects.map((project) => {
                  return <option key={project.id}>{project.code}</option>;
                })}
              </select>
            </div>
            <div className="form-group">
              <label htmlFor="fundcode" className=" text-color-accent">
                Fund Code
              </label>
              <select
                readOnly={!this.state.isAuthorized}
                onChange={this.handleFundcodeChange}
                className="form-control"
                name="fundcode"
                id="fundcode"
                value={this.state.fundcode}
              >
                {this.props.vehicle.fundcode == "" ? (
                  <option>select code</option>
                ) : null}
                {this.state.filteredCodes.map((fc) => {
                  return <option key={fc.code}>{fc.code}</option>;
                })}
              </select>
            </div>
          </div>
          <div className="flex-row flex-start w-100">
            <div className="form-group" id="donnor-group">
              <label htmlFor="cost" className=" text-color-accent">
                Acquisition Cost
              </label>
              <input
                readOnly={!this.state.isAuthorized}
                className="form-control "
                type="text"
                name="cost"
                id="cost"
                placeholder="Enter cost of acquisition"
                defaultValue={Commons.formatNumber(this.state.vehicle.a_cost)}
              />
            </div>
            <div className="form-group">
              <label htmlFor="date" className=" text-color-accent">
                Acquisition Date
              </label>
              <input
                readOnly={!this.state.isAuthorized}
                type="text"
                onChange={this.handleDateChange}
                onFocus={this.handleFocus}
                onBlur={this.handleBlur}
                value={Commons.dateFormat(this.state.selectedDate)}
                className="form-control "
                name="date"
                id="date"
              />
            </div>
            <div className="form-group">
              <label htmlFor="location" className=" text-color-accent">
                Select Location
              </label>
              <select
                readOnly={!this.state.isAuthorized}
                className="form-control "
                name="location"
                id="location"
                defaultValue={this.state.vehicle.location.trim()}
              >
                {getCityNames().map((c) => {
                  return <option key={c}>{c}</option>;
                })}
              </select>
            </div>
          </div>

          <div className="form-group">
            <span className="flex-row flex-space">
              {this.state.isAuthorized ? (
                <>
                  <input
                    className={"btn form-control bg-color-accent"}
                    type="submit"
                    id="submitVehicle"
                    value="Update"
                  />

                  <button
                    id="deleteVehicle"
                    type="button"
                    onClick={this.handleDeleteVehicle}
                    className="btn btn-reject form-control"
                  >
                    Delete
                  </button>
                </>
              ) : null}

              <span
                id="cancel-vehicle"
                className="btn text-color-accent"
                onClick={this.handleCancel}
              >
                CANCEL
              </span>
            </span>
          </div>
        </form>
        {this.state.confirmDelete ? (
          <CustomDialog
            title="Delete Vehicle"
            msg="Are you sure you want to delete this vehicle?"
            buttonText="Delete"
            onCancel={this.handleCancel}
            onAction={this.deleteVehicle}
          />
        ) : null}
      </div>
    );
  }
}
export default VehicleDetailForm;
