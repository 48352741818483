import React from "react";
import { getCityNames } from "postcodes-tz";
import CustomDialog from "./custom_dialog";
const config = require("../utils/config.json");

class DepartmentEditForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      department: null,
      deptChanged: false,
      complete: false,
      response: ""
    };

    this.handleCancel = this.handleCancel.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleDeptChange = this.handleDeptChange.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }
  componentDidMount() {
    console.log("def: ", this.props.departments);
    this.setState({ department: this.props.departments[0] });
  }
  handleChange = event => {
    event.preventDefault();
    let dept = this.state.department;
    let value = event.target.value;

    switch (event.target.id) {
      case "code":
        dept.code = value;
        break;
      case "description":
        dept.description = value;
        break;
      case "region":
        dept.region = value;
        break;
      case "admin":
        dept.admin = value;
        break;
    }

    this.setState({ department: dept, deptChanged: true });
  };
  handleCancel = e => {
    // e.preventDefault();
    this.props.onCancel();
  };
  handleDeptChange = e => {
    let id = parseInt(e.target.value);
    let dept = this.props.departments.filter(d => {
      return d.id === id;
    });
    if (dept.length > 0) this.setState({ department: dept[0] }, () => {});
  };
  handleSubmit = e => {
    e.preventDefault();
    fetch(config.base_url + "/department/" + this.state.department.id, {
      method: "put",
      headers: { "Content-type": "application/json" },
      body: JSON.stringify(this.state.department)
    })
      .then(res => res.json())
      .then(result => {
        let error = result.error;
        if (error) this.setState({ complete: true, response: error });
        else this.setState({ complete: true, response: result.response });
      })
      .catch(err => {
        this.setState({ complete: true, response: err });
      });
  };

  render() {
    return (
      <>
        <div className="overlay w-100 flex-row flex-center flex-middle">
          <form
            className="bg-color-white-dark w-50"
            onSubmit={this.handleSubmit}
          >
            <h2>Edit Program Office</h2>
            <div className="form-group">
              <label htmlFor="department_id">Select Department ID</label>

              <select
                onChange={this.handleDeptChange}
                className="form-control"
                id="department_id"
                name="department_id"
              >
                {this.props.departments.map(d => {
                  return (
                    <option key={d.id} value={d.id}>
                      {d.code}
                    </option>
                  );
                })}
              </select>
            </div>
            {this.state.department !== null ? (
              <>
                <div className="form-group">
                  <label htmlFor="code">Department Code</label>

                  <input
                    onChange={this.handleChange}
                    className="form-control"
                    type="text"
                    id="code"
                    name="code"
                    value={this.state.department.code}
                    placeholder="Enter department code in the form TZ000X"
                  />
                </div>

                <div className="form-group">
                  <label htmlFor="description">Description</label>

                  <input
                    onChange={this.handleChange}
                    className="form-control"
                    type="text"
                    id="description"
                    name="description"
                    value={this.state.department.description}
                    placeholder="Enter a description"
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="region">Select Region</label>

                  <select
                    onChange={this.handleChange}
                    className="form-control"
                    id="region"
                    name="region"
                    value={this.state.department.region}
                  >
                    {getCityNames("asc").map(city => {
                      return <option key={city}>{city}</option>;
                    })}
                  </select>
                </div>
                <div className="form-group">
                  <label htmlFor="admin">Choose Admin</label>

                  <select
                    onChange={this.handleChange}
                    className="form-control"
                    id="admin"
                    name="admin"
                    value={this.state.department.admin}
                  >
                    {this.props.admins.length > 0 ? (
                      this.props.admins.map(admin => {
                        return (
                          <option key={admin.id} value={admin.id}>
                            {admin.fname + " " + admin.lname}
                          </option>
                        );
                      })
                    ) : (
                      <option>No admins</option>
                    )}
                  </select>
                </div>
                <div className="form-group">
                  <span className="flex-row flex-space">
                    {this.state.deptChanged ? (
                      <input
                        className="btn form-control bg-color-accent"
                        type="submit"
                        id="btnUpdate"
                        value="Update"
                      />
                    ) : null}
                    <span
                      id="cancel-user"
                      className="btn text-color-accent"
                      onClick={this.handleCancel}
                    >
                      CANCEL
                    </span>
                  </span>
                </div>
              </>
            ) : null}
          </form>
        </div>{" "}
        {this.state.complete ? (
          <CustomDialog
            title={"Update Regional Office"}
            onCancel={this.handleCancel}
            msg={this.state.response}
          />
        ) : null}
      </>
    );
  }
}
export default DepartmentEditForm;
