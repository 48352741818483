import React,{useState} from "react";
import {useNavigate} from 'react-router-dom';
import { Spinner } from "react-bootstrap";
import logo from "../images/care-logo.png";
import Dashboard from "./dashboard";
const config = require("../utils/config.json");

const LoginForm = () => {
  const [hasError, setHasError] = useState(false);
  const [errorMsg, setErrorMsg] = useState("Invalid email or password");
  const [user, setUser] = useState(undefined);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate()
  const handleForm = (e) => {
    setIsLoading(true);
    e.preventDefault();

    // Perform login
    let body = {
      email: document.getElementById("email").value,
      password: document.getElementById("password").value,
    };
    
    fetch(config.base_url + "/signin", {
      method: "POST",
      headers: { "Content-type": "application/json" },
      body: JSON.stringify(body),
    })
      .then((res) => res.json())
      .then((response) => {
        if (response.error) {
          setHasError(true);
          setErrorMsg(response.error);
        } else {
          if(typeof response.result == 'object'){
            let user = response.result;
            setUser(user);
            window.sessionStorage.setItem('AUTH',JSON.stringify(user))
            navigate('/dashboard')
          }
          else{
            setHasError(true);
          setErrorMsg(response.result);
          }
        }

        
      })
      .catch((err) => {
        setHasError(true);
        setErrorMsg("There was a problem signing you in. Please try again later");
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <main>
      <div className="left-col">
        <img
          className="logo-large"
          src={logo}
          alt="care international logo"
        />
        <div className="branding">
          <p>Vehicle Management System</p>
        </div>
      </div>
      <div className="bg-color-main-dark right-col">
        <form name="form-login" onSubmit={handleForm}>
          {hasError ? (
            <div id="error" className="error">
              {errorMsg}
            </div>
          ) : null}

          <div className="form-group">
            <label className="text-white" htmlFor="email">
              Email
            </label>
            <input
              className="form-control"
              type="email"
              id="email"
              placeholder="Enter your email"
            />
          </div>
          <div className="form-group">
            <label className="tex-white" htmlFor="password">
              Password
            </label>
            <input
              className="form-control"
              type="password"
              id="password"
              placeholder="Enter password"
            />
          </div>

          <div id="forgot" className="form-group">
            <a href="/password_reset">I forgot my password</a>
          </div>
          <div className="form-group">
            <button
              className="form-control btn bg-color-white-dark text-color-accent"
              type="submit"
              id="btn-submit"
            >
              {isLoading ? (
                <Spinner variant="dark" animation="border" />
              ) : (
                "Sign In"
              )}
            </button>
          </div>
        </form>
        <div className="bottom-detail">
          <p>&copy; All rights reserved</p>
        </div>
      </div>
    </main>
  );
};

export default LoginForm;
