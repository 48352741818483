import React from "react";
import SearchBar from "./search_bar";
import VehicleForm from "./vehicle_form";
import VehicleDetailForm from "./vehicle_detail_form";
import VehicleListItem from "./vehicle_list_item";
import Fetcher from "../utils/fetcher";

const config = require("../utils/config.json");
class VehicleContainer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showForm: false,
      formAction: "",
      selectedVehicle: {},
      projects: [],
      fcodes: [],
      vehicles: [],
      filteredVehicles: [],
      user: this.props.user,
    };

    this.handleButtonClick = this.handleButtonClick.bind(this);
    this.cancelForm = this.cancelForm.bind(this);
    this.handleFeedback = this.handleFeedback.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
    this.getData = this.getData.bind(this);
    this.handleItemClick = this.handleItemClick.bind(this);
    this.updateVehicles = this.updateVehicles.bind(this);
    // this.getCodes = this.getCodes.bind(this);
    // this.handleProjectChange = this.handleProjectChange.bind(this);
  }
  updateVehicles = (vehicle_list) => {
    this.props.onRefresh(vehicle_list);
    this.setState({ vehicles: vehicle_list, filteredVehicles: vehicle_list });
  };
  handleItemClick = (vehicle) => {
    console.log("vehicle clicked: ", vehicle);
    this.setState({
      showForm: true,
      selectedVehicle: vehicle,
      formAction: "edit",
    });
  };
  getData() {
    Fetcher.fetchFundcodes("all")
      .then((response) => {
        this.setState({ fcodes: response });
      })
      .catch((err) => {
        this.handleFeedback(false, err);
      });

    Fetcher.fetchProjects()
      .then((response) => {
        console.log("getData(): ", response);
        this.setState({ projects: response });
      })
      .catch((err) => {
        this.handleFeedback(false, err);
      });
    Fetcher.fetchVehicles()
      .then((response) => {
        console.log("vehicles: ", response);
        this.setState({
          vehicles: response,
          filteredVehicles: response,
        });
      })
      .catch((error) => {
        this.handleFeedback(false, error);
      });
  }
  handleSearch(e) {
    console.log("searching...:", e.target.value);
    e.persist();
    let keyword = e.target.value.toLocaleLowerCase();
    let fVehicles = this.state.vehicles;
    fVehicles = fVehicles.filter((v) => {
      return (
        v.pnumber.toLocaleLowerCase().search(keyword) !== -1 ||
        v.model.toLocaleLowerCase().search(keyword) !== -1 ||
        v.location.toLocaleLowerCase().search(keyword) !== -1
      );
    });
    console.log("Filtered: ", fVehicles);
    this.setState((ps) => {
      return { filteredVehicles: fVehicles };
    });
  }
  cancelForm(state) {
    this.setState({ showForm: state, formAction: "" });
  }
  handleButtonClick(e) {
    e.preventDefault();
    this.setState({ showForm: true });
  }
  newButtonClick = (state) => {
    this.setState({ showForm: state });
  };
  handleFeedback(status, msg) {
    this.props.onFeedback(status, msg);
  }
  componentDidMount() {
    this.getData();
  }
  render() {
    return (
      <section id={this.props.id} className="content">
        <div className="actions flex-row flex-space border-bottom-main">
          {this.props.user.position === 6 ? (
            <button
              id={this.props.buttonNew}
              className="bg-color-main"
              onClick={this.handleButtonClick}
            >
              {"New Vehicle"}
            </button>
          ) : null}
          <SearchBar onChange={this.handleSearch} />
        </div>
        {!this.state.showForm ? (
          <VehicleListItem
            vehicles={this.state.filteredVehicles}
            onClick={(v) => this.handleItemClick(v)}
          />
        ) : (
          <div className="center-self content-list">
            {this.state.formAction === "edit" ? (
              <VehicleDetailForm
                user={this.state.user}
                cancelButtonClick={this.cancelForm}
                vehicle={this.state.selectedVehicle}
                projects={this.state.projects}
                fundcodes={this.state.fcodes}
                onFeedback={(status, msg) => this.handleFeedback(status, msg)}
                onRefresh={(list) => this.updateVehicles(list)}
              />
            ) : (
              <VehicleForm
                user={this.state.user}
                cancelButtonClick={this.cancelForm}
                fundcodes={this.state.fcodes}
                projects={this.state.projects}
                onFeedback={(status, msg) => this.handleFeedback(status, msg)}
                onRefresh={(list) => this.updateVehicles(list)}
              />
            )}
          </div>
        )}
      </section>
    );
  }
}
export default VehicleContainer;
