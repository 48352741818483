import React from "react";
import xlsExport from "xlsexport";
import {
  VictoryBar,
  VictoryChart,
  VictoryAxis,
  VictoryTheme,
  VictoryPie,
  VictoryLine,
  VictoryLabel,
  VictoryLegend,
  Border
} from "victory";

class CombinedListReport extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      reportFormat: "",
      chartOptions: {},
      isMounted: false,
      data: [],
      labels: [],
      values: [],
      title: ""
    };
    this.findTotal = this.findTotal.bind(this);
    this.getFullName = this.getFullName.bind(this);
    this.formatDate = this.formatDate.bind(this);
    this.formatNumber = this.formatNumber.bind(this);
    this.groupByArray = this.groupByArray.bind(this);
    this.exportToXls = this.exportToXls.bind(this);
    this.generateChart = this.generateChart.bind(this);
  }
  componentDidMount() {
    this.setState({ title: this.props.title });
    let report = this.props.report.map(r => {
      let r2 = r;
      let newtype = "";
      switch (r.type) {
        case 1:
          newtype = "Refueling";
          break;
        case 2:
          newtype = "Normal Car Service";
          break;
        case 3:
          newtype = "General Car Service";
          break;
        case 4:
          newtype = "Repair Service";
          break;
      }
      r2.serviceType = newtype;
      return r2;
    });
    this.setState({ report: report });
    this.setState({ reportFormat: this.props.reportFormat }, () => {
      this.generateChart();
    });
  }
  componentWillUnmount() {
    this.setState({ isMounted: false });
  }
  generateChart = () => {
    let type = this.state.reportFormat.toLowerCase();
    if (type !== "" && type !== "list") {
      let myData = this.groupByArray(this.props.report, this.props.groupBy);
      console.log("grouped: ", myData);
      let labels = myData.map(d => {
        return d.key;
      });
      let values = myData.map(d => {
        let value = 0;
        d.values.forEach(v => {
          if (v.type === 1) {
            value += parseFloat(v.price) * v.amount;
          } else value += parseFloat(v.cost);
          return value;
        });
      });

      let sourcedata = myData.map(d => {
        let data = {};
        data.x = d.key;
        data.y = d.values.reduce(
          (a, b) =>
            a +
            (b.type === 1
              ? parseFloat(b.price) * b.amount
              : parseFloat(b.cost)),
          0
        );
        return data;
      });

      let options = {
        title: { text: this.props.title },

        data: [{ type: "column", dataPoints: sourcedata }]
      };
      console.log("datasource: ", sourcedata);
      this.setState({
        chartOptions: options,
        isMounted: true,
        data: sourcedata,
        labels: labels,
        values: values
      });
    } else {
      let source = this.props.report.map(r => {
        let r1 = r;
        if (r.type === 1) r1.cost = parseFloat(r.price) * r.amount;
        return r1;
      });
      this.setState({ report: source });
    }
  };
  formatNumber = num => {
    if (typeof num === "string") num = parseFloat(num);
    return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
  };
  exportToXls = () => {
    let data = this.props.report.map(r => {
      let rep = {
        date: this.formatDate(r.date_processed),
        vehicle: r.vehicle,
        project: r.project,
        fundcode: r.fundcode,
        requestor: this.getFullName(r.requestor),
        approved_by: this.getFullName(r.attended_by),
        approver: this.getFullName(r.approver),
        cost: r.cost,
        supplier: r.supplier_name
      };

      return rep;
    });
    let xls = new xlsExport(data, this.props.title);
    xls.exportToXLS("maintenance_report.xls");
  };
  groupByArray = (xs, key) => {
    let result = xs.reduce((rv, x) => {
      let v = key instanceof Function ? key(x) : x[key];
      let el = rv.find(r => r && r.key === v);
      if (el) {
        el.values.push(x);
      } else {
        rv.push({ key: v, values: [x] });
      }
      return rv;
    }, []);

    console.log("grouped by: ", result);
    return result;
  };

  exportToPdf = () => {
    let xls = new xlsExport(this.state.report, this.props.title);

    xls.exportToPDF("fuel_report.pdf");
  };
  formatDate = seconds => {
    let months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec"
    ];
    let dateObject = new Date(seconds * 1000);
    let date =
      dateObject.getDate() +
      " " +
      months[dateObject.getMonth()] +
      " " +
      dateObject.getFullYear();

    return date;
  };

  findTotal = () => {
    let total = 0;
    this.props.report.forEach(item => {
      total +=
        item.type === 1
          ? parseFloat(item.a_price) * item.a_amount
          : parseFloat(item.a_cost);
      // return total;
    });
    return total;
  };
  getFullName = userid => {
    if (userid === 1) return "System Admin";
    let user = this.props.users.filter(u => {
      return u.id === userid;
    });
    if (user.length > 0) return user[0].fname + " " + user[0].lname;

    return userid;
  };
  render() {
    return (
      <div className="center-self">
        <h2>{"Combined Fuel and Maintenance Cost " + this.state.title}</h2>
        <p>
          {this.formatDate(this.props.dateRange.start) +
            " to " +
            this.formatDate(this.props.dateRange.end)}
        </p>
        <h3>{"Total Cost: " + this.formatNumber(this.findTotal()) + " Tsh"}</h3>
        <div className="content-list">
          {this.state.reportFormat.toLowerCase() === "list" &&
          !this.state.isMounted ? (
            <>
              <div className="list-item-head flex-row flex-start">
                <span>Date</span>
                <span>Vehicle</span>
                <span>Cost (Tsh)</span>
                <span>Supplier</span>
                <span>Fund Code</span>
                <span>Requested by</span>
                <span>Approved by</span>
              </div>

              {this.state.report.map(r => {
                return (
                  <div
                    className="list-item flex-row flex-start"
                    key={r.id + "." + r.date_processed}
                  >
                    <span>{this.formatDate(r.date_processed)}</span>
                    <span>{r.vehicle}</span>
                    <span>
                      {this.formatNumber(
                        parseFloat(r.type === 1 ? r.cost : r.a_cost)
                      )}
                    </span>
                    <span>{r.supplier_name}</span>
                    <span>{r.fundcode}</span>
                    <span>{this.getFullName(r.requestor)}</span>
                    <span>{this.getFullName(r.attended_by)}</span>
                  </div>
                );
              })}
            </>
          ) : (
            <div className="center-self flex-row flex-center w-100 border-all-main">
              {this.state.reportFormat.toLowerCase() === "column" ? (
                <div className="w-80">
                  <VictoryChart
                    domainPadding={20}
                    theme={VictoryTheme.material}
                  >
                    <VictoryAxis
                      label={
                        this.props.groupBy.substring(0, 1).toUpperCase() +
                        this.props.groupBy.substring(1)
                      }
                      style={{
                        axis: { stroke: "#756f6a" },
                        axisLabel: { fontSize: 10, padding: 30 },
                        grid: {
                          stroke: ({ tick }) => (tick > 0.5 ? "red" : "grey")
                        },
                        ticks: { stroke: "grey", size: 5 },
                        tickLabels: { fontSize: 15, padding: 5 }
                      }}
                      tickValues={[]}
                      tickFormat={this.state.labels}
                      tickLabelComponent={
                        <VictoryLabel
                          angle={30}
                          style={{ fontSize: "6px", wordWrap: "break-word" }}
                        />
                      }
                    />
                    <VictoryAxis
                      label="Cost in Tsh"
                      dependentAxis
                      tickFormat={x => {
                        return x < 1000 ? x : (x / 1000).toFixed(2) + "k";
                      }}
                      style={{
                        axis: { stroke: "#756f6a" },
                        axisLabel: { fontSize: 10, padding: 40 },

                        ticks: { stroke: "grey", size: 5 },
                        tickLabels: { fontSize: 10, padding: 5 }
                      }}
                      tickLabelComponent={
                        <VictoryLabel
                          angle={0}
                          style={{ fontSize: "6px", wordWrap: "break-word" }}
                        />
                      }
                    />
                    <VictoryBar
                      data={this.state.data}
                      style={{ labels: { fontSize: "6px" } }}
                      x="x"
                      y="y"
                      tickLabelComponent={
                        <VictoryLabel
                          angle={0}
                          style={{ fontSize: "6px", wordWrap: "break-word" }}
                        />
                      }
                    />
                  </VictoryChart>
                </div>
              ) : this.state.reportFormat.toLowerCase() === "pie" ? (
                <div className="w-80">
                  <svg className="center-self w-100">
                    <VictoryLegend
                      standalone={false}
                      x={20}
                      y={40}
                      orientation="horizontal"
                      gutter={20}
                      title="Legend"
                      borderComponent={<Border />}
                      borderPadding={5}
                      colorScale="qualitative"
                      centerTitle
                      style={{
                        border: { stroke: "black" },
                        textAlign: "center"
                      }}
                      data={this.state.labels.map(d => {
                        let r = {};
                        r.name = d;
                        return r;
                      })}
                    />
                  </svg>

                  <VictoryPie
                    padAngle={3}
                    padding={{ left: 80, right: 80 }}
                    colorScale="qualitative"
                    data={this.state.data}
                    theme={VictoryTheme.material}
                    labels={({ datum }) =>
                      "Tsh. " +
                      this.formatNumber((datum.y / 1000).toFixed(2)) +
                      "k"
                    }
                    innerRadius={25}
                    labelPosition="centroid"
                    style={{ labels: { fontSize: "6px" } }}
                    labelComponent={
                      <VictoryLabel
                        angle={45}
                        style={{ fontSize: "6px", wordWrap: "break-word" }}
                      />
                    }
                    style={{
                      data: {
                        stroke: "#c43a31",
                        strokeWidth: 0,
                        strokeLinecap: "round"
                      }
                    }}
                  />
                </div>
              ) : this.state.reportFormat.toLowerCase() === "line" ? (
                <div className="w-80">
                  <VictoryChart theme={VictoryTheme.material}>
                    <VictoryAxis
                      label={"Cost in Tsh"}
                      dependentAxis
                      tickFormat={x => {
                        return x < 1000 ? x : (x / 1000).toFixed(1) + "k";
                      }}
                      style={{
                        axis: { stroke: "#756f6a" },
                        axisLabel: { fontSize: 10, padding: 40 }
                      }}
                      tickLabelComponent={
                        <VictoryLabel
                          angle={0}
                          style={{ fontSize: "6px", wordWrap: "break-word" }}
                        />
                      }
                    />
                    <VictoryAxis
                      label={this.props.groupBy
                        .substring(0, 1)
                        .toUpperCase()
                        .concat(this.props.groupBy.substring(1))}
                      style={{
                        axis: { stroke: "#756f6a" },
                        axisLabel: { fontSize: 10, padding: 30 },
                        grid: {
                          stroke: ({ tick }) => (tick > 0.5 ? "red" : "grey")
                        },
                        ticks: { stroke: "grey", size: 5 },
                        tickLabels: { fontSize: 15, padding: 5 }
                      }}
                      tickValues={[]}
                      tickFormat={this.state.labels}
                      tickLabelComponent={
                        <VictoryLabel
                          angle={30}
                          style={{ fontSize: "6px", wordWrap: "break-word" }}
                        />
                      }
                    />
                    <VictoryLine
                      style={{
                        data: {
                          stroke: "#c43a31",
                          strokeWidth: 1,
                          strokeLinecap: "round"
                        }
                      }}
                      data={this.state.data}
                    />
                  </VictoryChart>
                </div>
              ) : null}
            </div>
          )}
        </div>
        <div className="form-group">
          <span className="flex-row flex-space">
            {this.state.reportFormat.toLowerCase() === "list" ? (
              <input
                className="btn form-control btn-success"
                type="button"
                id="exportXls"
                value="Export to Excel"
                onClick={this.exportToXls}
              />
            ) : null}
            {/* <input
            className="btn form-control btn-error"
            type="button"
            id="exportPdf"
            value="Export to PDF"
            onClick={exportToPdf}
          /> */}

            <span
              id="cancel-user"
              className="btn text-color-accent"
              onClick={() => this.props.cancelButtonClick()}
            >
              CLOSE
            </span>
          </span>
        </div>
      </div>
    );
  }
}
export default CombinedListReport;
