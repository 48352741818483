import React from "react";
import NotificationList from "./notification_list_item";

class NotificationsContainer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  handleFeedback(status, msg) {
    this.props.onFeedback(status, msg);
  }

  render() {
    return (
      <section id={this.props.id} className="content">
        <h2>Recent Activities</h2>
        <div className="actions flex-row flex-space border-bottom-main">
          <NotificationList
            onItemClick={(notification) => this.props.onItemClick(notification)}
            notifications={this.props.notifications}
            user={this.props.user}
          />
        </div>
      </section>
    );
  }
}
export default NotificationsContainer;
