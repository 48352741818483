import React from "react";
import CustomDialog from "./custom_dialog";
import Fetcher from "../utils/fetcher";
const config = require("../utils/config.json");

class SupplierDeleteForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      complete: false,
      response: "",
      selected: false,
    };

    this.handleCancel = this.handleCancel.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  handleCancel = (e) => {
    // e.preventDefault();
    this.props.onCancel();
  };
  componentDidMount = () => {};
  handleChange = (e) => {
    let value = e.target.value;
    if (!value.includes("--")) {
      this.setState({ selected: true });
    } else this.setState({ selected: false });
  };
  handleSubmit = (e) => {
    e.preventDefault();
    let sid = document.getElementById("supplier_id").value;

    fetch(config.base_url + "/supplier/" + sid, {
      method: "delete",
      headers: { "Content-type": "application/json" },
    })
      .then((res) => res.json())
      .then((result) => {
        let error = result.error;
        if (error) this.setState({ complete: true, response: error });
        else
          this.setState({ complete: true, response: result.response }, () => {
            Fetcher.fetchSuppliers()
              .then((suppliers) => {
                this.props.onRefresh(suppliers);
                this.props.onFeedback(false, result.response);
              })
              .catch((err) => {
                this.props.onFeedback(false, "Could not refresh supplier list");
              });
          });
      })
      .catch((err) => {
        this.setState({ complete: true, response: err });
      });
  };

  render() {
    return (
      <>
        <div className="overlay w-100 flex-row flex-center flex-middle">
          <form
            className="bg-color-white-dark w-50"
            onSubmit={this.handleSubmit}
          >
            <h2>Delete Supplier</h2>
            <div className="form-group">
              <label htmlFor="supplier_id">Select Supplier</label>

              <select
                onChange={this.handleChange}
                className="form-control"
                id="supplier_id"
                name="supplier_id"
              >
                <option>--select--</option>
                {this.props.suppliers.map((s) => {
                  return (
                    <option key={s.id} value={s.id}>
                      {s.name}
                    </option>
                  );
                })}
              </select>
            </div>

            <div className="form-group">
              <span className="flex-row flex-space">
                {this.state.selected ? (
                  <input
                    className="btn form-control btn-error"
                    type="submit"
                    id="btndelete"
                    value="Delete"
                  />
                ) : null}
                <span
                  id="cancel"
                  className="btn text-color-accent"
                  onClick={this.handleCancel}
                >
                  CANCEL
                </span>
              </span>
            </div>
          </form>
        </div>
        {this.state.complete ? (
          <CustomDialog
            title={"Delete Supplier"}
            onCancel={this.handleCancel}
            msg={this.state.response}
          />
        ) : null}
      </>
    );
  }
}
export default SupplierDeleteForm;
