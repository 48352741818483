import React from "react";

class TripListItem extends React.Component {
  constructor(props) {
    super(props);

    this.state = { trip: props.trip };
    this.handleClick = this.handleClick.bind(this);
    this.formatDate = this.formatDate.bind(this);
  }
  formatDate = (seconds) => {
    let months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    let dateObject = new Date(seconds * 1000);
    let date =
      dateObject.getDate() +
      " " +
      months[dateObject.getMonth()] +
      " " +
      dateObject.getFullYear();

    return date;
  };
  handleClick = () => {
    this.props.click(this.props.trip);
  };

  render() {
    return (
      <table className="content-list border-all-main">
        <thead>
          <tr className="list-item-head flex-row flex-start w-100 bg-color-main">
            <td>Vehicle</td>
            <td>Driver</td>
            <td>Requestor</td>
            <td>From</td>
            <td>To</td>
            <td>Distance (Km)</td>
            <td>Project</td>
            <td>Fund Code</td>
            <td>Date</td>
            <td>Status</td>
          </tr>
        </thead>
        <tbody>
          {this.props.trips.length > 0 ? (
            this.props.trips.map((trip) => {
              return (
                <tr
                  key={trip.id}
                  className="list-item flex-row flex-start"
                  onClick={() => this.props.onClick(trip)}
                >
                  <td className="text-left">{trip.vehicle}</td>
                  <td className="text-left">{trip.nameOfDriver}</td>
                  <td className="text-left">{trip.nameOfRequestor}</td>
                  <td className="text-left">
                    {trip.start_location === null
                      ? "Not set"
                      : trip.start_location}
                  </td>
                  <td className="text-left">
                    {trip.destination === null ? "Not set" : trip.destination}
                  </td>
                  <td className="text-left">
                    {trip.end_mileage - trip.start_mileage}
                  </td>
                  <td className="text-left">
                    {trip.project === null ? "Not set" : trip.project}
                  </td>
                  <td className="text-left">{trip.fundcode}</td>
                  <td className="text-left">
                    {this.formatDate(trip.date_created)}
                  </td>
                  <td
                    className={
                      "text-left " +
                      (trip.status === "Incomplete"
                        ? "error-text"
                        : trip.status === "Complete"
                        ? "text-success"
                        : "")
                    }
                  >
                    {trip.status}
                  </td>
                </tr>
              );
            })
          ) : (
            <tr>
              <td>No Trips</td>
            </tr>
          )}
        </tbody>
      </table>
    );
  }
}
export default TripListItem;
