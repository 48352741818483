import React from "react";
import Fetcher from "../utils/fetcher";
import Commons from "../utils/commons";
import CustomDialog from "./custom_dialog";
import { getCityNames } from "postcodes-tz";
const config = require("../utils/config.json");

class MaintenanceRequestClosing extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      comment: "",
      users: [],
      drivers: this.props.drivers,
      complete: false,
      hasError: false,
      selectedDate: "",
      parts: props.request.parts,
      cost: props.request.cost,
      mileage: props.request.mileage,
      location: props.user.location,
      start_time: props.request.start_time,
      lastRequest: {},
      distance: 0,
      supplierAvailable: props.suppliers.length > 0,
      supplier: props.request.supplier,
      description: props.request.description,
      history: [],
      needsConfirmation: false,
    };
    this.user = props.user;
    this.handleBlur = this.handleBlur.bind(this);
    this.handleDateChange = this.handleDateChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.getUsers = this.getUsers.bind(this);
    this.handleComplete = this.handleComplete.bind(this);
    this.handleFocus = this.handleFocus.bind(this);
    this.handleDiscard = this.handleDiscard.bind(this);
  }

  handleFocus(e) {
    this.state.complete ? e.preventDefault() : (e.target.type = "date");
  }
  handleBlur = (event) => {
    event.preventDefault();
    event.target.type = "text";
    event.target.value = this.state.selectedDate;
  };
  handleChange = (event) => {
    let req = this.props.request;
    let distanceInput = document.getElementById("distance");

    event.preventDefault();
    let input = event.target;
    let value = input.value;
    switch (input.id) {
      case "mileage":
        value = input.value.replace(",", "");
        this.setState(
          {
            mileage: value,
            distance: value - req.mileage,
          },
          () => {
            distanceInput.value = this.state.distance;
            this.setState({
              hasError:
                this.state.parts === "" ||
                this.state.description === "" ||
                this.state.distance < 0 ||
                this.state.cost < 0,
            });
          }
        );
        break;
      case "cost":
        value = input.value.replace(",", "");
        this.setState(
          {
            cost: value,
          },
          () => {
            this.setState({
              hasError:
                this.state.parts === "" ||
                this.state.description === "" ||
                this.state.distance < 0 ||
                this.state.cost < 0,
            });
          }
        );
        break;
      case "parts":
        this.setState(
          {
            parts: value,
          },
          () => {
            this.setState({
              hasError:
                this.state.parts === "" ||
                this.state.description === "" ||
                this.state.distance < 0 ||
                this.state.cost < 0,
            });
          }
        );
        break;
      case "description":
        this.setState({ description: value }, () => {
          this.setState({
            hasError:
              this.state.parts === "" ||
              this.state.description === "" ||
              this.state.distance < 0 ||
              this.state.cost < 0,
          });
        });
        break;
      case "supplier":
        if (parseInt(value) === 0) {
          this.setState(
            { supplierAvailable: false, supplier: parseInt(value) },
            () => {
              if (!this.state.supplierAvailable) {
                document.getElementById("supplier_new").focus = true;
              }
            }
          );
        } else {
          this.setState({ supplierAvailable: true, supplier: parseInt(value) });
        }
        break;
      case "supplier_new":
        this.setState({ supplierAvailable: false, supplier: value });
        break;
      case "region":
        this.setState({ location: value });
        break;
      case "comment":
        this.setState({ comment: value });
        break;
    }
  };
  handleComplete = (event) => {
    // let supplierInput = document.getElementById("supplier");
    // let vehicle = document.getElementById("vehicle").value;
    let dateInput = document.getElementById("date_processed");
    let date = new Date(dateInput.value);
    let dateProcessed = date.getTime() / 1000;
    let req = this.props.request;

    let data = {
      requestId: req.id,
      requestType: req.type,
      comment: this.state.comment,
      status: 3,
      cost: this.state.cost,
      parts: this.state.parts,
      description: this.state.description,
      supplier: this.state.supplier,
      isNewSupplier: typeof this.state.supplier === "string",
      closer: this.props.user.id,
      mileage: this.state.mileage,
      date_processed: dateProcessed,
      location: this.state.location,
      vehicle: req.vehicle,
    };

    if (event.target.id === "cancelRequest") {
      data = {
        requestId: req.id,
        requestType: req.type,
        comment: this.state.comment,
        closer: this.props.user.id,
        status: 5,
      };
    }

    this.updateRequest(data);
  };

  updateRequest = (data) => {
    fetch(config.base_url + "/request/" + data.requestId, {
      method: "post",
      headers: { "Content-type": "application/json" },
      body: JSON.stringify(data),
    })
      .then((res) => res.json())
      .then((result) => {
        console.log("just test: ", result);
        Fetcher.fetchRequests(this.props.user)
          .then((requests) => {
            this.props.onRefresh(requests);
            this.props.onFeedback(true, result.response);
            this.handleCancel();
          })
          .catch((error) => {
            this.props.onFeedback(false, error.error);
            this.handleCancel();
          });
      })
      .catch((error) => {
        this.props.onFeedback(false, error.error);
        this.handleCancel();
      });
  };

  getUsers() {
    let myUsers = this.props.users.map((u) => {
      let user = u;
      user.position = u.position;
      return user;
    });
    this.setState({ users: myUsers, filteredUsers: myUsers }, () => {
      let drivers = this.state.drivers.filter((u) => {
        return u.position === 2;
      });
    });
  }
  handleDiscard = () => {
    let comment = document.getElementById("comment").value;
    let req = this.props.request;
    let user = this.props.user;
    let data = {
      requestId: req.id,
      requestType: req.type,
      comment: comment,
      closer: user.id,
      status: 5,
      requestor: req.requestor,
      approver: req.approver,
      vehicle: req.vehicle,
    };
    // console.log("Test: ", data);
    this.updateRequest(data);
  };
  componentDidMount() {
    this.getUsers();
    let req = this.props.request;
    if (req !== undefined) {
      Fetcher.fetchLastRequest(req.vehicle, req.type)
        .then((request) => {
          this.setState({ lastRequest: request[0] });
          Fetcher.fetchVehicleHistory(
            req.vehicle,
            request.length > 0 ? request[0].last_updated : false
          ).then((history) => {
            if (history.length > 0) {
              let newHistory = Commons.groupByArray(history, "fundcode");
              let mappedHistory = newHistory.map((h) => {
                let hist = h;
                hist.distance = h.values.reduce((a, b) => a + b.distance, 0);
                hist.project = h.values[0].project;

                return hist;
              });
              let total = mappedHistory.reduce((a, b) => a + b.distance, 0);
              this.setState({ history: mappedHistory, total: total });
            }
            this.setState({
              cost: req.cost,
              supplier_name: req.supplier_name,
              mileage: req.mileage,
              start_time: req.start_time,
              isEditable:
                req.requestor === this.user.id ||
                this.user.username === "admin",
              complete:
                req.statusText === "Closed" ||
                req.statusText === "Cancelled" ||
                req.statusText === "Rejected"
                  ? true
                  : false,
            });
          });
        })
        .catch((err) => {
          // this.props.onFeedback(false, err);
        });
    }

    let supplier = this.props.suppliers.filter((s) => {
      return s.region === this.user.location;
    });
    this.setState({
      supplierAvailable: supplier.length > 0,
      supplier: req.supplier,
    });
  }

  handleCancel() {
    // console.log("cancelling user form");
    this.props.cancelButtonClick();
  }

  handleSubmit(e) {
    e.preventDefault();

    // this.props.onFeedback(true, "testing...");
  }
  handleDateChange(event) {
    event.preventDefault();
    let dateInput = event.target;
    let date = new Date(dateInput.value);
    let formatedDate = Commons.dateFormat(date.getTime() / 1000);
    dateInput.type = "text";
    dateInput.value = formatedDate;
    this.setState({ selectedDate: formatedDate });
  }

  render() {
    return (
      <>
        <div className={"flex-row flex-center center-self"}>
          <form
            name="vehicle-request-form"
            id="vehicle-request-form"
            className="center-self border-all-main"
            onSubmit={this.handleSubmit}
          >
            <h2 className="w-100 bg-color-main">
              Maintenance Request Completion Form
            </h2>
            <div className="w-100 flex-row flex-start">
              <div className="form-group">
                <label htmlFor="date_requested" className=" text-color-accent">
                  Date Requested
                </label>
                <input
                  readOnly={true}
                  className="form-control "
                  type="text"
                  name="date_requested"
                  id="date_requested"
                  value={Commons.dateFormat(this.props.request.date_created)}
                />
              </div>
              <div className="form-group">
                <label htmlFor="from" className="form-label text-color-accent">
                  Date Completed
                </label>

                <input
                  readOnly={this.state.complete}
                  onBlur={this.handleBlur}
                  onFocus={this.handleFocus}
                  onChange={this.handleDateChange}
                  className="form-control "
                  type="text"
                  name="date_processed"
                  id="date_processed"
                  defaultValue={Commons.dateFormat(this.state.start_time)}
                />
              </div>
              <div className="form-group">
                <label
                  htmlFor="requestor"
                  className="form-label text-color-accent"
                >
                  Requestor's Name
                </label>

                <input
                  readOnly={true}
                  type="text"
                  className="form-control "
                  name="requestor"
                  id="requestor"
                  defaultValue={this.props.request.nameOfRequestor}
                />
              </div>
            </div>
            <div className="w-100 flex-row flex-start">
              <div className="form-group">
                <label
                  htmlFor="vehicle"
                  className="form-label text-color-accent"
                >
                  Vehicle
                </label>
                <input
                  readOnly={true}
                  className="form-control "
                  type="text"
                  name="vehicle"
                  id="vehicle"
                  placeholder="No vehicle info"
                  defaultValue={this.props.request.vehicle}
                />
              </div>
              <div className="form-group">
                <label
                  htmlFor="mileage"
                  className="form-label text-color-accent"
                >
                  Current Mileage
                </label>
                <input
                  readOnly={this.state.complete}
                  onChange={this.handleChange}
                  className="form-control "
                  type="text"
                  name="mileage"
                  id="mileage"
                  placeholder="No mileage info"
                  defaultValue={this.state.mileage}
                />
              </div>

              <div className="form-group">
                <label
                  htmlFor="lmileage"
                  className="form-label text-color-accent"
                >
                  Last Service Mileage
                </label>
                <input
                  readOnly={true}
                  className="form-control "
                  type="text"
                  name="lmileage"
                  id="lmileage"
                  placeholder="No mileage info"
                  defaultValue={
                    this.state.lastRequest
                      ? this.state.lastRequest.mileage
                      : this.props.request.mileage
                  }
                />
              </div>
              <div className="form-group">
                <label
                  htmlFor="distance"
                  className="form-label text-color-accent"
                >
                  Distance Covered
                </label>
                <input
                  readOnly={true}
                  className={
                    "form-control " + (this.state.distance < 0 ? "fail" : "")
                  }
                  type="text"
                  name="distance"
                  id="distance"
                  placeholder="No mileage info"
                  defaultValue={this.state.distance}
                />
              </div>
            </div>
            <div className="flex-row flex-start w-100">
              <div className="form-group">
                <label
                  htmlFor="supplier"
                  className="form-label text-color-accent"
                >
                  Supplier
                </label>

                <select
                  readOnly={this.state.complete}
                  onChange={this.handleChange}
                  className="form-control "
                  type="text"
                  name="supplier"
                  id="supplier"
                  value={this.props.request.supplier}
                >
                  {this.props.suppliers.map((s) => {
                    return (
                      <option key={s.id} value={s.id}>
                        {s.name}
                      </option>
                    );
                  })}
                  <option value="0">No Supplier</option>
                </select>
              </div>
              {!this.state.supplierAvailable ? (
                <>
                  <div className="form-group">
                    <label
                      htmlFor="supplier"
                      className="form-label text-color-accent"
                    >
                      Supplier
                    </label>

                    <input
                      readOnly={this.state.complete}
                      onChange={this.handleChange}
                      className="form-control "
                      type="text"
                      name="supplier_new"
                      id="supplier_new"
                      defaultValue={""}
                    />
                  </div>
                  <div className="form-group">
                    <label
                      htmlFor="region"
                      className="form-label text-color-accent"
                    >
                      Region
                    </label>

                    <select
                      readOnly={this.state.complete}
                      onChange={this.handleChange}
                      className="form-control "
                      type="text"
                      name="region"
                      id="region"
                      defaultValue={this.state.location}
                    >
                      {getCityNames().map((c, i) => {
                        return (
                          <option key={i} value={c}>
                            {c}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                </>
              ) : null}

              <div className="form-group">
                <label htmlFor="cost" className="form-label text-color-accent">
                  Cost (Tsh)
                </label>

                <input
                  readOnly={!this.state.isEditable}
                  onChange={this.handleChange}
                  className={
                    "form-control " + (this.state.cost < 0 ? "fail" : "")
                  }
                  type="text"
                  name="cost"
                  id="cost"
                  defaultValue={Commons.formatNumber(this.state.cost)}
                />
              </div>
            </div>

            <div className="form-group">
              <label htmlFor="parts" className="form-label text-color-accent">
                Parts Changed (Separate with comma)
              </label>
              <input
                onChange={this.handleChange}
                readOnly={this.state.complete}
                className="form-control "
                type="text"
                name="parts"
                id="parts"
                value={this.state.parts}
              />
            </div>
            {this.props.request.type == 2 ? (
              <div className="form-group">
                <label htmlFor="" className="form-label text-color-accent">
                  Charging Instrutions
                </label>
                {this.state.history.length > 0 ? (
                  <div className="form-group">
                    {this.state.history.map((h) => {
                      return (
                        <div className="flex-row flex-start" key={h.key}>
                          <input
                            type="text"
                            className="form-control"
                            readOnly={true}
                            defaultValue={h.key + " - " + h.project}
                          />
                          <span className="form-control text-color-accent">
                            {h.distance +
                              " Km (" +
                              ((100 * h.distance) / this.state.total).toFixed(
                                1
                              ) +
                              "%) - Cost: Tsh. " +
                              Commons.formatNumber(
                                (
                                  (h.distance / this.state.total) *
                                  this.state.cost
                                ).toFixed(1)
                              )}
                          </span>
                        </div>
                      );
                    })}
                  </div>
                ) : (
                  <div className="flex-row flex-start">
                    <div className="form-group">
                      <label
                        htmlFor="project"
                        className="form-label text-color-accent"
                      >
                        Project
                      </label>
                      <input
                        readOnly={this.state.processed}
                        type="text"
                        className="form-control"
                        name="project"
                        id="project"
                        defaultValue={this.props.request.project}
                      />
                    </div>
                    <div className="form-group">
                      <label
                        htmlFor="fund_code"
                        className="form-label text-color-accent"
                      >
                        Fund Code
                      </label>
                      <input
                        readOnly={this.state.processed}
                        type="text"
                        id="fund_code"
                        className="form-control"
                        defaultValue={this.props.request.fundcode}
                      />
                    </div>
                  </div>
                )}
              </div>
            ) : (
              <div className="flex-row flex-start">
                <div className="form-group">
                  <label
                    htmlFor="project"
                    className="form-label text-color-accent"
                  >
                    Project
                  </label>
                  <input
                    readOnly={this.state.processed}
                    type="text"
                    className="form-control"
                    name="project"
                    id="project"
                    defaultValue={this.props.request.project}
                  />
                </div>
                <div className="form-group">
                  <label
                    htmlFor="fund_code"
                    className="form-label text-color-accent"
                  >
                    Fund Code
                  </label>
                  <input
                    readOnly={this.state.processed}
                    type="text"
                    id="fund_code"
                    className="form-control"
                    defaultValue={this.props.request.fundcode}
                  />
                </div>
              </div>
            )}

            {this.props.user.id === this.props.request.requestor ? (
              <div className="form-group">
                <label
                  htmlFor="comment"
                  className="form-label text-color-accent"
                >
                  Requestor's Comment
                </label>
                <input
                  readOnly={this.state.complete}
                  onChange={this.handleChange}
                  id="comment"
                  name="comment"
                  className="form-control"
                  defaultValue={this.props.request.comment}
                />
              </div>
            ) : null}

            <div className="form-group">
              <label htmlFor="remark" className="form-label text-color-accent">
                Approver's Remarks
              </label>
              <input
                readOnly={true}
                id="remark"
                name="remark"
                className="form-control"
                defaultValue={this.props.request.remark}
              />
            </div>

            <div className="form-group">
              <label htmlFor="remark" className="form-label text-color-accent">
                Authorizer's Remarks
              </label>
              <input
                readOnly={true}
                id="remark1"
                name="remark1"
                className="form-control"
                defaultValue={this.props.request.remark1}
              />
            </div>

            <div className="flex-row flex-space flex-middle">
              <div className="form-group">
                <label
                  htmlFor="approver"
                  className="form-label text-color-accent"
                >
                  {this.props.request.statusText +
                    " by " +
                    (this.props.request.status === 3 ||
                    this.props.request.status === 5
                      ? this.props.request.nameOfCloser
                      : this.props.request.nameOfAttender)}
                </label>
              </div>
              {this.state.hasError ? (
                <div className="form-group">
                  <span className="form-label error-text">
                    {this.state.cost > this.props.request.cost
                      ? "Cost exceeds the cost approved"
                      : this.state.distance < 0
                      ? "Distance covered cannot be negative"
                      : null}
                  </span>
                </div>
              ) : null}
            </div>

            <div className="form-group">
              <span className="flex-row flex-space">
                {!this.state.complete && this.state.isEditable ? (
                  <>
                    {this.state.hasError ? null : (
                      <span
                        className="btn btn-success"
                        id="complete"
                        onClick={this.handleComplete}
                      >
                        COMPLETE
                      </span>
                    )}
                  </>
                ) : null}
                {this.props.user.id == this.props.request.requestor &&
                this.props.request.status != 3 &&
                this.props.request.status != 5 ? (
                  <span
                    id="cancel-request"
                    className="btn-reject btn "
                    onClick={() => {
                      this.setState({ needsConfirmation: true });
                    }}
                  >
                    DISCARD
                  </span>
                ) : null}
                <span
                  id="cancel"
                  className="btn text-color-accent"
                  onClick={this.handleCancel}
                >
                  CLOSE
                </span>
              </span>
            </div>
          </form>
        </div>
        {this.state.needsConfirmation ? (
          <CustomDialog
            title={"Confirm Request Discard"}
            onCancel={this.handleCancel}
            msg={"Are you sure you want to discard this request?"}
            onAction={this.handleDiscard}
            buttonText={"DISCARD"}
          />
        ) : null}
      </>
    );
  }
}
export default MaintenanceRequestClosing;
