import React from "react";

class CustomButton extends React.Component {
  constructor(props) {
    super(props);
    this.handleClick = this.handleClick.bind(this);
  }

  handleClick(e) {
    e.preventDefault();
    console.log("clicked: " + this.props.id);
    this.props.onButtonClick(this.props.id);
  }
  render() {
    return (
      <div
        id={this.props.id}
        className={
          "menu-item " +
          (this.props.clicked ? "bg-color-accent" : "bg-color-main-dark")
        }
        onClick={this.handleClick}
      >
        <i
          className={
            "material-icons " +
            (this.props.id === "vehicles" ? " vehicle-space" : "")
          }
        >
          {this.props.icon}
        </i>
        <span className="mx-3">{this.props.text}</span>
      </div>
    );
  }
}

export default CustomButton;
