import React from "react";
import avatar from "../images/avatar.svg";

const Avatar = props => {
  return (
    <div className="">
      {/* <img className="avatar" alt="avatar" src={avatar} /> */}
      <p>{props.username}</p>
    </div>
  );
};

export default Avatar;
