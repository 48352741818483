import React from "react";
import SearchBar from "./search_bar";
import RequestForm from "./request_form";
import FuelForm from "./fuel_form";
import RequestListItem from "./request_list_item";
import MaintenanceForm from "./maintenance_form";
import VehicleRequestApproval from "./vehicle_request_approval";
import FuelRequestApproval from "./fuel_request_approval";
import MaintenanceRequestApproval from "./maintenance_request_approval";
import Fetcher from "../utils/fetcher";
import FuelRequestClosing from "./fuel_request_closing";
import MaintenanceRequestClosing from "./maintenance_request_closing";

class RequestContainer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loadComplete: false,
      isVehicle: false,
      isFuel: false,
      isMaintenance: false,
      isFuelApproval: false,
      isFuelClosing: false,
      isMaintenanceApproval: false,
      isMaintenanceClosing: false,
      canRequestAll: false,
      canRequestVehicle: false,
      canRequestMaintance: false,
      positions: [],
      users: [],
      fundcodes: [],
      filteredUsers: [],
      requests: [],
      request: null,
      filteredRequests: [],
      stata: [],
      vehicles: [],
      suppliers: [],
      projects: [],
      hasNext:false,hasPrevious:false,currentPage:0,totalPages:0,pageSize:10
    };
    this.sortRequests = this.sortRequests.bind(this);
    this.handleVehicleButtonClick = this.handleVehicleButtonClick.bind(this);
    this.handleFuelButtonClick = this.handleFuelButtonClick.bind(this);
    this.refreshRequests = this.refreshRequests.bind(this);
    this.refreshUsers = this.refreshUsers.bind(this);
    this.handleMaintenanceButtonClick = this.handleMaintenanceButtonClick.bind(
      this
    );
    this.cancelForm = this.cancelForm.bind(this);
    this.handleFeedback = this.handleFeedback.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
    this.loadData = this.loadData.bind(this);
    this.handleItemClick = this.handleItemClick.bind(this);
    this.handlePageChange = this.handlePageChange.bind(this);
  }
  refreshRequests = (list) => {
    let sortedRequests = this.sortRequests(list);
    this.setState({
      requests: sortedRequests,
      filteredRequests: sortedRequests,
    });
  };
  refreshUsers = (users) => {
    this.setState({ users: users }, () => {
      this.props.onUpdateUsers(users);
    });
  };
  handleItemClick(item) {
    this.setState(
      {
        request: item,
        isVehicleApproval: item.type === 0 ? true : false,
        isMaintenance: false,
        isFuelApproval:
          item.type === 1 && (item.status === 0 || item.status === 1)
            ? true
            : false,
        isFuelClosing: item.type === 1 && item.status >= 2 ? true : false,
        isMaintenanceApproval: item.type >= 2 && item.status < 2 ? true : false,
        isMaintenanceClosing: item.type >= 2 && item.status >= 2 ? true : false,
      },
      () => {
        console.log("item type: " + item.type + "/item-status: " + item.status);
        console.log(
          "res: " + this.state.isFuelApproval + "/" + this.state.isFuelClosing
        );
      }
    );
  }

  handleSearch(e) {
    e.persist();
    let keyword = e.target.value.toLowerCase();
    let fRequests = this.state.requests;
    fRequests = fRequests.filter((req) => {
      return (
        (req.vehicle && req.vehicle.toLowerCase().search(keyword) !== -1) ||
        (req.nameOfApprover &&
          req.nameOfApprover.toLowerCase().search(keyword) !== -1) ||
        (req.nameOfRequestor &&
          req.nameOfRequestor.toLowerCase().search(keyword) !== -1) ||
        (req.fundcode && req.fundcode.toLowerCase().search(keyword) !== -1) ||
        req.statusText.toLowerCase().search(keyword) !== -1 ||
        req.typeText.toLowerCase().search(keyword) !== -1
      );
    });

    this.setState((ps) => {
      return { filteredRequests: fRequests };
    });
  }
  handleFeedback(status, msg) {
    this.props.onFeedback(status, msg);
  }

  cancelForm() {
    this.setState({
      isVehicle: false,
      isFuel: false,
      isMaintenance: false,
      isFuelApproval: false,
      isMaintenanceApproval: false,
      isVehicleApproval: false,
      isMaintenanceClosing: false,
      isFuelClosing: false,
    });
  }
  handleVehicleButtonClick() {
    this.setState({
      isVehicleApproval: false,
      isVehicle: true,
      isFuel: false,
      isMaintenance: false,
      isFuelApproval: false,
      isMaintenanceApproval: false,
      isMaintenanceClosing: false,
      isFuelClosing: false,
    });
  }
  handleFuelButtonClick(e) {
    e.preventDefault();
    this.setState({
      isFuel: true,
      isVehicle: false,
      isMaintenance: false,
      isFuelApproval: false,
      isMaintenanceApproval: false,
      isMaintenanceClosing: false,
      isFuelClosing: false,
    });
  }
  handleMaintenanceButtonClick(e) {
    e.preventDefault();
    this.setState({
      isVehicle: false,
      isFuel: false,
      isMaintenance: true,
      isFuelApproval: false,
      isMaintenanceApproval: false,
      isVehicleApproval: false,
      isMaintenanceClosing: false,
      isFuelClosing: false,
      pageSize: 10,currentPage:1
    });
  }
  loadData = async (user) =>{
    const [vehicles,projects,requests,users,suppliers,fundcodes] = await Promise.all([
      Fetcher.fetchVehicles(),Fetcher.fetchProjects(),Fetcher.fetchRequests(user,{page:this.state.currentPage,take:this.state.pageSize}),Fetcher.getActiveUsers(),Fetcher.fetchSuppliers(),Fetcher.fetchFundcodes("active")
    ]);
    
    this.setState({
      vehicles:vehicles,projects:projects,requests:requests.requests,suppliers:suppliers,fundcodes:fundcodes,users:users.users,hasNext:requests.hasNext,hasPrevious:requests.hasPrevious,currentPage:requests.page,totalPages:requests.totalPages
    })
  
  }
  sortRequests(requests) {
    let sortedRequests = requests.sort((a, b) => {
      if (a.date_created > b.date_created) return -1;
      if (a.date_created < b.date_created) return 1;
      return 0;
    });
    return sortedRequests;
  }
  async componentDidMount() {
    let user = this.props.user;
    await this.loadData(user);
    switch (user.position) {
      case 1:
      case 6:
        this.setState({
          canRequestAll: true,
          canRequestMaintance: false,
          canRequestVehicle: false,
        });
        break;
      case 2:
        this.setState({
          canRequestAll: false,
          canRequestMaintance: true,
          canRequestVehicle: false,
        });
        break;
      case 3:
      case 4:
      case 5:
        this.setState({
          canRequestAll: false,
          canRequestMaintance: false,
          canRequestVehicle: true,
        });
        break;
    }

    if (this.props.defaultRequest != null) {
      let item = this.props.defaultRequest;

      this.setState({
        request: item,
        isVehicleApproval: item.type === 0 ? true : false,
        isMaintenance: false,
        isFuelApproval:
          item.type === 1 && (item.status === 0 || item.status === 1)
            ? true
            : false,
        isFuelClosing: item.type === 1 && item.status >= 2 ? true : false,
        isMaintenanceApproval:
          item.type >= 2 && item.status === 1 ? true : false,
        isMaintenanceClosing: item.type >= 2 && item.status >= 2 ? true : false,
      });
    }
  }
  handlePageChange = (page)=>{
    this.setState({currentPage:page},()=>{
      this.loadData(this.props.user)
    })
  }
  handlePageSizeChange = (pageSize)=>{
    this.setState({pageSize: pageSize,currentPage:1},()=>{
      this.loadData(this.props.user)
    })
  }
  render() {
    return (
      <section id={this.props.id} className="content">
        <div className="actions flex-row flex-space border-bottom-main">
          {this.state.canRequestAll || this.state.canRequestVehicle ? (
            <button
              id={this.props.buttonNew}
              className="bg-color-main"
              onClick={(e) => this.handleVehicleButtonClick(e)}
            >
              Vehicle Request
            </button>
          ) : null}
          {this.state.canRequestAll || this.state.canRequestMaintance ? (
            <>
              <button
                id={this.props.buttonNew}
                className="bg-color-main"
                onClick={this.handleFuelButtonClick}
              >
                Fuel Request
              </button>
              <button
                id={this.props.buttonNew}
                className="bg-color-main"
                onClick={this.handleMaintenanceButtonClick}
              >
                Maintenance Request
              </button>
            </>
          ) : null}

          <SearchBar onChange={this.handleSearch} />
        </div>
        {!(
          this.state.isFuel ||
          this.state.isVehicle ||
          this.state.isMaintenance ||
          this.state.isVehicleApproval ||
          this.state.isFuelApproval ||
          this.state.isMaintenanceApproval ||
          this.state.isFuelClosing ||
          this.state.isMaintenanceClosing
        ) ? (
          <RequestListItem
            requests={this.state.requests}
            stata={this.state.stata}
            click={(r) => this.handleItemClick(r)}
            onClick={(user) => this.handleItemClick(user)}
              hasNext={this.state.hasNext}
              hasPrevious={this.state.hasPrevious}
              currentPage={this.state.currentPage}
              totalPages={this.state.totalPages}
              onPageChange={(page) => this.handlePageChange(page)}
              onPageSizeChange={(pageSize) => this.handlePageSizeChange(pageSize)}
              pageSize={this.state.pageSize}
          />
        ) : this.state.isFuel ? (
          <div className="content-list center-self">
            <FuelForm
              user={this.props.user}
              onFeedback={(status, msg) => this.handleFeedback(status, msg)}
              positions={this.state.positions}
              cancelButtonClick={this.cancelForm}
              users={this.state.users}
              drivers = {this.props.drivers}
              vehicles={this.state.vehicles}
              suppliers={this.state.suppliers}
              projects={this.state.projects}
              departments={this.props.departments}
              onRefresh={(reqs) => this.refreshRequests(reqs)}
              fundcodes={this.state.fundcodes}
              isAdmin={this.state.canRequestAll}
            />
          </div>
        ) : this.state.isFuelApproval ? (
          <div className="content-list center-self">
            <FuelRequestApproval
              suppliers={this.state.suppliers}
              user={this.props.user}
              drivers = {this.props.drivers}
              request={this.state.request}
              projects={this.state.projects}
              onFeedback={(status, msg) => this.handleFeedback(status, msg)}
              onRefresh={(reqs) => this.refreshRequests(reqs)}
              positions={this.state.positions}
              fundcodes={this.state.fundcodes}
              cancelButtonClick={this.cancelForm}
              users={this.state.users}
              vehicles={this.state.vehicles}
            />
          </div>
        ) : this.state.isFuelClosing ? (
          <FuelRequestClosing
            suppliers={this.state.suppliers}
            user={this.props.user}
            drivers = {this.props.drivers}
            request={this.state.request}
            projects={this.state.projects}
            onFeedback={(status, msg) => this.handleFeedback(status, msg)}
            onRefresh={(reqs) => this.refreshRequests(reqs)}
            positions={this.state.positions}
            fundcodes={this.state.fundcodes}
            cancelButtonClick={this.cancelForm}
            users={this.state.users}
            vehicles={this.state.vehicles}
          />
        ) : this.state.isMaintenance ? (
          <div className="content-list center-self">
            <MaintenanceForm
              user={this.props.user}
              drivers = {this.props.drivers}
              onFeedback={(status, msg) => this.handleFeedback(status, msg)}
              onRefresh={(reqs) => this.refreshRequests(reqs)}
              positions={this.state.positions}
              cancelButtonClick={this.cancelForm}
              users={this.state.users}
              vehicles={this.state.vehicles}
              suppliers={this.state.suppliers}
              projects={this.state.projects}
              fundcodes={this.state.fundcodes}
              parts={this.state.parts}
              isAdmin={this.state.canRequestAll}
              departments={this.props.departments}
            />
          </div>
        ) : this.state.isMaintenanceApproval ? (
          <div className="content-list center-self">
            <MaintenanceRequestApproval
              suppliers={this.state.suppliers}
              user={this.props.user}
              drivers = {this.props.drivers}
              request={this.state.request}
              projects={this.state.projects}
              onFeedback={(status, msg) => this.handleFeedback(status, msg)}
              onRefresh={(reqs) => this.refreshRequests(reqs)}
              positions={this.state.positions}
              fundcodes={this.state.fundcodes}
              cancelButtonClick={this.cancelForm}
              users={this.state.users}
              vehicles={this.state.vehicles}
            />
          </div>
        ) : this.state.isMaintenanceClosing ? (
          <MaintenanceRequestClosing
            suppliers={this.state.suppliers}
            user={this.props.user}
            request={this.state.request}
            drivers = {this.props.drivers}
            projects={this.state.projects}
            onFeedback={(status, msg) => this.handleFeedback(status, msg)}
            onRefresh={(reqs) => this.refreshRequests(reqs)}
            positions={this.state.positions}
            fundcodes={this.state.fundcodes}
            cancelButtonClick={this.cancelForm}
            users={this.state.users}
            vehicles={this.state.vehicles}
          />
        ) : this.state.isVehicleApproval ? (
          <div className="content-list center-self">
            <VehicleRequestApproval
              user={this.props.user}
              drivers = {this.props.drivers}
              request={this.state.request}
              projects={this.state.projects}
              onFeedback={(status, msg) => this.handleFeedback(status, msg)}
              positions={this.state.positions}
              fundcodes={this.state.fundcodes}
              cancelButtonClick={this.cancelForm}
              users={this.state.users}
              onRefresh={(list) => this.refreshRequests(list)}
              onRefreshUsers={(users) => this.refreshUsers(users)}
              vehicles={this.state.vehicles.filter((v) => {
                return v.in_use === 0;
              })}
            />
          </div>
        ) : (
          <div className="content-list center-self">
            <RequestForm
              user={this.props.user}
              projects={this.state.projects}
              drivers = {this.props.drivers}
              onFeedback={(status, msg) => this.handleFeedback(status, msg)}
              positions={this.state.positions}
              fundcodes={this.state.fundcodes}
              onRefresh={(reqs) => this.refreshRequests(reqs)}
              cancelButtonClick={this.cancelForm}
              users={this.state.users}
              isAdmin={this.state.canRequestAll}
              departments={this.props.departments}
            />
          </div>
        )}
      </section>
    );
  }
}
export default RequestContainer;
