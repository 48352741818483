import React, { useEffect, useMemo, useState } from "react";
import xlsExport from "xlsexport";
import {
  VictoryBar,
  VictoryChart,
  VictoryAxis,
  VictoryTheme,
  VictoryPie,
  VictoryLine,
  VictoryLabel,
  VictoryLegend,
  Border,
} from "victory";
import {
  getCoreRowModel,
  ColumnDef,
  flexRender,
  useReactTable} from '@tanstack/react-table'
import Commons from "../utils/commons";

const SMSListReport = (props)=>{
const [reportFormat,setReportFormat] = useState("list")
  const getPreviousPage = ()=>{
    props.onPageChange(props.currentPage - 1);
  }
  const getNextPage=()=>{
    const nextPage =  props.currentPage + 1;
    props.onPageChange(nextPage)
  }
  const columns = 
    [
      {
        accessorFn: row => row.createdAt,
        id: 'createdAt',
        cell: info => info.getValue(),
        header: () => <span>Date</span>,
        footer: props => props.column.id,
      },
      {
        accessorFn: row => row.destination,
        id: 'destination',
        cell: info => info.getValue(),
        header: () => <span>Destination</span>,
        footer: props => props.column.id,
      },
      {
        accessorFn: row => row.message,
        id: 'message',
        cell: info => info.getValue(),
        header: () => <span>Message Body</span>,
        footer: props => props.column.id,
      },
      {
        accessorFn: row => row.status,
        id: 'status',
        cell: info => info.getValue() == 1 ? "Sent": "Not Sent",
        header: () => <span>Status</span>,
        footer: props => props.column.id,
      },
      
    ]
  
const data = props.report ? props.report : [];

  const table = useReactTable ({
    data,
    columns,
    enableColumnResizing: true,
    columnResizeMode: 'onChange',
    getCoreRowModel: getCoreRowModel(),
    debugTable: true,
    debugHeaders: true,
    debugColumns: true,
  })

  if(props.report && props.report.length > 0){
    return ( 
      <div className="center-self">
       
        <h2>{"SMS Report"}</h2>
        <p>
         {props.dateRange}
        </p>
        <h3>
          {"Total Cost of SMS: " +
            props.totalCost}
        </h3>
        <div className="content-list">
          {reportFormat.toLowerCase() === "list" ? (<>
            <table className="w-full text-left border-all-main p-2">
        <thead>
          {table.getHeaderGroups().map(headerGroup => (
            <tr key={headerGroup.id} className="bg-color-main px-2 py-1">
              {headerGroup.headers.map(header => {
                return (
                  <th
                    key={header.id}
                    colSpan={header.colSpan}
                    style={{ position: 'relative', width: header.getSize() }}
                  >
                    {header.isPlaceholder
                      ? null
                      : flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}
                    {header.column.getCanResize() && (
                      <div
                        onMouseDown={header.getResizeHandler()}
                        onTouchStart={header.getResizeHandler()}
                        className={`resizer ${
                          header.column.getIsResizing() ? 'isResizing' : ''
                        }`}
                      ></div>
                    )}
                  </th>
                )
              })}
            </tr>
          ))}
        </thead>
        <tbody>
          {table.getRowModel().rows.map(row => {
            return (
              <tr key={row.id} className="list-item">
                {row.getVisibleCells().map(cell => {
                  return (
                    <td key={cell.id} style={{ width: cell.column.getSize() }} className="border-bottom px-1">
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext()
                      )}
                    </td>
                  )
                })}
              </tr>
            )
          })}
        </tbody>
        </table>
      <div className="w-full border-top mt-3 flex-row flex-center align-items-center ">
        
        {props.hasPrevious ? <span className="material-icons fw-light mx-2" onClick={getPreviousPage}>arrow_back</span>:null}
        <span>Showing page {props.currentPage} of  {props.totalPages}</span>
        {props.hasNext ? <span className="material-icons fw-light mx-2" onClick={getNextPage}>arrow_forward</span>:null}
        </div></>
      ):null}
       
      </div></div>)
  }
  else{
    return (null)
  }

}
export default SMSListReport;
