import React from "react";
import SearchBar from "./search_bar";
import TripDetailForm from "./trip_detail_form";
import TripListItem from "./trip_list_item";
import Fetcher from "../utils/fetcher";

class TripContainer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loadComplete: false,
      formVisible: false,
      users: [],
      trips: [],
      vehicles:[],
      filteredTrips: [],
    };

    this.handleButtonClick = this.handleButtonClick.bind(this);
    this.cancelForm = this.cancelForm.bind(this);
    this.handleFeedback = this.handleFeedback.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
    this.loadData = this.loadData.bind(this);
    this.handleItemClick = this.handleItemClick.bind(this);
    this.refreshTrips = this.refreshTrips.bind(this);
  }

  refreshTrips = (data) => {
    this.setState({ trips: data.trips, filteredTrips: data.trips,vehicles:data.vehicles },()=>{
      this.props.onRefresh(data);
    });
  };
  handleSearch(e) {
    e.persist();
    let keyword = e.target.value.toLowerCase();
    let ftrips = this.state.trips;
    ftrips = ftrips.filter((ft) => {
      return (
        ft.vehicle.toLowerCase().search(keyword) !== -1 ||
        ft.nameOfDriver.toLowerCase().search(keyword) !== -1 ||
        ft.start_city.toLowerCase().search(keyword) !== -1 ||
        ft.destination_city.toLowerCase().search(keyword) !== -1 ||
        ft.start_location.toLowerCase().search(keyword) !== -1 ||
        ft.destination.toLowerCase().search(keyword) !== -1 ||
        ft.nameOfRequestor.toLowerCase().search(keyword) !== -1 ||
        ft.status.toLowerCase().search(keyword) !== -1
      );
    });

    this.setState((ps) => {
      return { filteredTrips: ftrips };
    });
  }
  cancelForm() {
    this.setState({ formVisible: false });
  }
  handleButtonClick(e) {
    e.preventDefault();
    this.setState({ showForm: true });
  }
  newButtonClick = (state) => {
    this.setState({ showForm: state });
  };
  handleFeedback(status, message) {
    this.props.onFeedback(status, message);
  }

  loadData() {
    //load trip logs
    Fetcher.fetchTrips(this.props.user)
      .then((trips) => {
        console.log("trips:", trips);
        this.setState({ trips: trips, filteredTrips: trips });
      })
      .catch((error) => {
        this.props.onFeedback(false, error);
      });
    this.setState({ users: this.props.users });
  }
  handleItemClick(item) {
    this.setState({
      trip: item,
      formVisible: true,
    });
  }

  componentDidMount() {
    this.loadData();
  }

  render() {
    return (
      <section id={this.props.id} className="content">
        {/* <h1>{this.props.title}</h1> */}
        <div className="actions flex-row flex-space">
          <SearchBar onChange={this.handleSearch} />
        </div>
        {!this.state.formVisible ? (
          <TripListItem
            trips={this.state.filteredTrips}
            onClick={(t) => this.handleItemClick(t)}
          />
        ) : (
          <div className="center-self content-list">
            <TripDetailForm
              user={this.props.user}
              trip={this.state.trip}
              cancelButtonClick={this.cancelForm}
              onRefresh={(data) => this.refreshTrips(data)}
              users={this.props.users}
              vehicles={this.props.vehicles}
              onFeedback={(status, msg) => this.handleFeedback(status, msg)}
            />
          </div>
        )}
      </section>
    );
  }
}
export default TripContainer;
