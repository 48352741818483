import React from "react";
import Fetcher from "../utils/fetcher";
import Commons from "../utils/commons";
import CustomDialog from "./custom_dialog";
const config = require("../utils/config.json");

class VehicleRequestApproval extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      driverSet: false,
      vehicleSet: false,
      isVisible: true,
      projects: props.projects,
      positions: props.positions,
      users: [],
      drivers: props.drivers,
      availableVehicles: [],
      fcodes: props.fundcodes,
      filteredCodes: [],
      filteredUsers: [],
      approvable: undefined,
      processed: undefined,
      isEditable: undefined,
      needsConfirmation: false,
      action: "",

      can_approve:
        props.request.status === 0 &&
        (props.user.id === props.request.approver ||
          props.user.position === 6 ||
          props.user.position === 3),
      can_assign:
        props.request.status === 1 &&
        (props.user.id === props.request.admin ||
          props.user.position === 6 ||
          props.user.position === 3),
    };
    this.user = props.user;
    this.handlePositionChange = this.handlePositionChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
    this.handleProjectChange = this.handleProjectChange.bind(this);
    this.getUsers = this.getUsers.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
    this.handleApprove = this.handleApprove.bind(this);
    this.handleReject = this.handleReject.bind(this);
    this.getUserName = this.getUserName.bind(this);
    this.handleDiscard = this.handleDiscard.bind(this);
    this.requestConfirmation = this.requestConfirmation.bind(this);
    this.handleAssign = this.handleAssign.bind(this);
  }
  requestConfirmation = (action) => {
    var my_action;
    if (action === "reject") my_action = this.handleReject;
    else my_action = this.handleDiscard;
    this.setState({ needsConfirmation: true, action: my_action });
  };
  handleDiscard = (event) => {
    let comment =
      document.getElementById("comment").value || "Discarded request";
    let req = this.props.request;

    let data = {
      requestId: req.id,
      requestType: req.type,
      comment: comment,
      attender: this.props.user.id,
      status: 5,
      requestor: req.requestor,
      approver: req.approver,
      vehicle: req.vehicle,
    };

    this.updateRequest(data);
  };
  handleApprove = () => {
    let remarks = document.getElementById("remark").value;
    // let vehicle = document.getElementById("ass_vehicle").value;
    // let driver = document.getElementById("ass_driver").value;

    let req = this.props.request;
    let destination = req.destination;
    let destination_city = req.destination_city;
    let data = {
      requestId: req.id,
      requestType: req.type,
      remark: remarks,
      attender: this.props.user.id,
      status: 1,
      admin: req.admin,
      approved_by: this.props.user.id,
      // driver: driver,
      // vehicle: vehicle,
      requestor: req.requestor,
      requestorName: req.nameOfRequestor,
      attenderName: this.props.user.fname + " " + this.props.user.lname,
      approver: req.approver,
      destination: destination + " (" + destination_city + ")",
    };
    console.log("data: ", data);
    this.updateRequest(data);
  };
  handleAssign = () => {
    let remarks = document.getElementById("remarks1").value;
    let vehicle = document.getElementById("ass_vehicle").value;
    let driver = document.getElementById("ass_driver").value;

    let req = this.props.request;
    let destination = req.destination;
    let destination_city = req.destination_city;
    let data = {
      requestId: req.id,
      requestType: req.type,
      remark: remarks,
      attender: this.props.user.id,
      status: 2,
      destination_city: destination_city,

      driver: driver,
      vehicle: vehicle,
      requestor: req.requestor,
      requestorName: req.nameOfRequestor,
      attenderName: this.props.user.fname + " " + this.props.user.lname,
      approver: req.approver,
      destination: destination + " (" + destination_city + ")",
    };
    this.updateRequest(data);
  };
  handleReject = () => {
    let remarks = document.getElementById("remark").value;
    let req = this.props.request;

    let data = {
      requestId: req.id,
      requestType: req.type,
      remark: remarks,
      attender: this.props.user.id,
      status: 4,
      requestor: req.requestor,
      approver: req.approver,
    };
    this.updateRequest(data);
  };
  updateRequest = (data) => {
    fetch(config.base_url + "/request/" + data.requestId, {
      method: "put",
      headers: { "Content-type": "application/json" },
      body: JSON.stringify(data),
    })
      .then((res) => res.json())
      .then((result) => {
        Fetcher.fetchRequests(this.props.user)
          .then((requests) => {
            this.props.onRefresh(requests);
            Fetcher.fetchUsers()
              .then((users) => {
                this.props.onRefreshUsers(users);
                this.props.onFeedback(true, result.response);
              })
              .catch((e) => {
                this.props.onFeedback(true, result.response);
              });
          })
          .catch((e) => {
            this.props.onFeedback(true, result.response);
          });
      })
      .catch((error) => {
        this.props.onFeedback(false, error.error);
        // this.handleCancel();
      })
      .finally(() => {
        this.handleCancel();
      });
  };
  handleProjectChange(e) {
    e.preventDefault();
    let project = e.target.value;
    let codes = Commons.getCodes(project, this.props.fundcodes);
    this.setState({ filteredCodes: codes });
  }

  getPositionValue = (id) => {
    let values = this.props.positions.filter((p) => {
      return p.id === id;
    });

    return values.length > 0 ? values[0] : "Unknown";
  };
  handleSearch(e) {
    e.persist();
    let value = e.target.value;
    let keyword = value.toLocaleLowerCase();
    let fusers = this.state.users;
    console.log("fusers: ", fusers);
    fusers = fusers.filter((u) => {
      return (
        u.fname.toLocaleLowerCase().search(keyword) !== -1 ||
        u.lname.toLocaleLowerCase().search(keyword) !== -1
        // || u.position.name.toLocaleLowerCase().search(keyword) !== -1
      );
    });
    this.setState({ filteredUsers: fusers, searchValue: keyword });
  }

  getUsers() {
    let req = this.props.request;
    let myUsers = this.props.users;
    this.setState({ users: myUsers, filteredUsers: myUsers }, () => {
      let drivers = this.props.drivers.filter((u) => {
        return (
          u.busy === 0 &&
          u.location.trim() === req.start_city.trim()
        );
      });
      this.setState({ drivers: drivers });
    });
  }

  componentDidMount() {
    this.getUsers();
    let request = this.props.request;
    console.log("request: ", request);
    if (request !== undefined) {
      let aVehicles = this.props.vehicles.filter((v) => {
        return (
          v.in_use === 0 && v.location.trim() === request.start_city.trim()
        );
      });
      this.setState({ availableVehicles: aVehicles });
    }
  }
  getUserName(id) {
    if (id === 1) {
      return "System Administrator";
    }
    let users = this.state.users.filter((u) => {
      return u.id === id;
    });
    if (users.length > 0) {
      return users[0].fname + " " + users[0].lname;
    } else return "Unknown";
  }
  handleCancel() {
    console.log("cancelling user form");
    this.props.cancelButtonClick();
  }
  handlePositionChange() {}
  handleSubmit(e) {
    e.preventDefault();

    // this.props.onFeedback(true, "testing...");
  }

  render() {
    return (
      <>
        <div className={"flex-row flex-center center-self"}>
          <form
            name="vehicle-request-form"
            id="vehicle-request-form"
            className="center-self border-all-main"
            onSubmit={this.handleSubmit}
          >
            <h2 className="w-100 bg-color-main">Vehicle Request Approval</h2>

            <div className="w-100 flex-row flex-start">
              <div className="form-group">
                <label className="text-color-accent" htmlFor="date_requested">
                  Date Requested
                </label>
                <input
                  readOnly={true}
                  className="form-control "
                  type="text"
                  name="date_requested"
                  id="date_requested"
                  defaultValue={Commons.dateFormat(
                    this.props.request.date_created
                  )}
                />
              </div>
              <div className="form-group">
                <label htmlFor="date_required" className=" text-color-accent">
                  Date Required
                </label>

                <input
                  readOnly={true}
                  className="form-control "
                  type="text"
                  name="date_required"
                  id="date_required"
                  defaultValue={Commons.dateFormat(
                    this.props.request.start_time
                  )}
                />
              </div>
              <div className="form-group">
                <label htmlFor="requestor" className=" text-color-accent">
                  Requestor's Name
                </label>

                <input
                  readOnly={true}
                  type="text"
                  className="form-control "
                  name="requestor"
                  id="requestor"
                  defaultValue={this.props.request.nameOfRequestor}
                />
              </div>
            </div>
            <div className="flex-row flex-space w-100">
              <div className="form-group">
                <label htmlFor="start_location" className=" text-color-accent">
                  Start Location
                </label>
                <input
                  readOnly={true}
                  className="form-control "
                  type="text"
                  name="start_location"
                  id="start_location"
                  placeholder="No start location"
                  defaultValue={
                    this.props.request.start_location +
                    " (" +
                    this.props.request.start_city +
                    ")"
                  }
                />
              </div>
              <div className="form-group">
                <label htmlFor="destination" className=" text-color-accent">
                  Final Destination
                </label>
                <input
                  readOnly={true}
                  className="form-control "
                  type="text"
                  name="destination"
                  id="destination"
                  placeholder="Enter list of destinations"
                  defaultValue={
                    this.props.request.destination +
                    " (" +
                    this.props.request.destination_city +
                    ")"
                  }
                />
              </div>
              <div className="form-group">
                <label htmlFor="end_time" className=" text-color-accent">
                  Expected Return
                </label>
                <input
                  readOnly={true}
                  className="form-control "
                  type="text"
                  name="end_time"
                  id="end_time"
                  placeholder="Enter list of destinations"
                  defaultValue={Commons.dateFormatWithTime(
                    this.props.request.end_time
                  )}
                />
              </div>
            </div>
            <div className="form-group">
              <label htmlFor="purpose" className=" text-color-accent">
                Purpose
              </label>
              <input
                readOnly={true}
                className="form-control "
                type="text"
                name="purpose"
                id="purpose"
                placeholder="Purpose of trip"
                defaultValue={this.props.request.purpose}
              />
            </div>

            <div className="flex-row flex-start w-100">
              <div className="form-group">
                <label htmlFor="project" className=" text-color-accent">
                  Project
                </label>
                <input
                  readOnly={true}
                  type="text"
                  className="form-control"
                  name="project"
                  id="project"
                  defaultValue={this.props.request.project}
                />
              </div>
              <div className="form-group">
                <label htmlFor="fund_code" className=" text-color-accent">
                  Fund Code
                </label>
                <input
                  readOnly={true}
                  type="text"
                  id="fund_code"
                  className="form-control"
                  defaultValue={this.props.request.fundcode}
                />
              </div>
              <div className="form-group">
                <label htmlFor="account" className=" text-color-accent">
                  Account
                </label>
                <input
                  readOnly={true}
                  className="form-control "
                  type="text"
                  name="account"
                  id="account"
                  defaultValue={this.props.request.account}
                />
              </div>
            </div>
            <div className="flex-row flex-start w-100">
              <div className="form-group">
                <label htmlFor="source" className=" text-color-accent">
                  Source
                </label>
                <input
                  readOnly={true}
                  id="source"
                  name="source"
                  className="form-control"
                  defaultValue={this.props.request.source}
                />
              </div>
              <div className="form-group">
                <label htmlFor="category" className=" text-color-accent">
                  Category
                </label>
                <input
                  readOnly={true}
                  id="category"
                  className="form-control"
                  name="category"
                  defaultValue={this.props.request.category}
                />
              </div>
              <div className="form-group">
                <label htmlFor="activity" className=" text-color-accent">
                  Activity
                </label>
                <input
                  readOnly={true}
                  id="activity"
                  name="activity"
                  className="form-control"
                  defaultValue={this.props.request.activity}
                />
              </div>
            </div>
            {this.props.request.status === 2 ||
            this.props.request.status === 3 ? (
              <div className="flex-row flex-start w-100">
                <div className="form-group">
                  <label htmlFor="driver" className=" text-color-accent">
                    Assigned Driver
                  </label>
                  <input
                    readOnly={true}
                    id="driver"
                    name="driver"
                    className="form-control"
                    defaultValue={this.props.request.nameOfDriver}
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="vehicle" className=" text-color-accent">
                    Assigned Vehicle
                  </label>
                  <input
                    readOnly={true}
                    id="vehicle"
                    className="form-control"
                    name="vehicle"
                    defaultValue={this.props.request.vehicle}
                  />
                </div>
              </div>
            ) : null}
            {this.state.can_assign ? (
              <>
                <div className="flex-row flex-start w-100">
                  <div className="form-group">
                    <label htmlFor="ass_vehicle" className=" text-color-accent">
                      Assign Vehicle
                    </label>
                    <select
                      className="form-control"
                      name="ass_vehicle"
                      id="ass_vehicle"
                      onChange={() => {
                        this.setState({ vehicleSet: true });
                      }}
                    >
                      <option>--select--</option>
                      {this.state.availableVehicles.length > 0 ? (
                        this.state.availableVehicles.map((v) => {
                          return (
                            <option key={v.id} value={v.id}>
                              {v.pnumber}
                            </option>
                          );
                        })
                      ) : (
                        <option>No available vehicle</option>
                      )}
                    </select>
                  </div>
                  <div className="form-group">
                    <label htmlFor="ass_driver" className=" text-color-accent">
                      Assign Driver
                    </label>
                    <select
                      className="form-control"
                      name="ass_driver"
                      id="ass_driver"
                      onChange={() => {
                        this.setState({ driverSet: true });
                      }}
                    >
                      <option value="select">--select--</option>
                      {this.state.drivers.length > 0 ? (
                        this.state.drivers.map((d) => {
                          return (
                            <option key={d.id} value={d.id}>
                              {d.fname + " " + d.lname}
                            </option>
                          );
                        })
                      ) : (
                        <option>No drivers available</option>
                      )}
                    </select>
                  </div>
                </div>
              </>
            ) : null}
            {this.state.can_approve ||
            this.state.can_assign ||
            this.props.request.status > 1 ? (
              <>
                <div className="form-group">
                  <label htmlFor="remark" className=" text-color-accent">
                    Approver Remarks
                  </label>
                  <input
                    readOnly={!this.state.can_approve}
                    id="remark"
                    name="remark"
                    className="form-control"
                    defaultValue={this.props.request.remark}
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="remarks1" className=" text-color-accent">
                    Authorizer's Remarks
                  </label>
                  <input
                    readOnly={!this.state.can_assign}
                    id="remarks1"
                    name="remarks1"
                    className="form-control"
                    defaultValue={this.props.request.remark1}
                  />
                </div>
              </>
            ) : null}
            {this.props.user.id === this.props.request.requestor &&
            this.props.request.status < 3 ? (
              <div className="form-group">
                <label htmlFor="comment" className=" text-color-accent">
                  Comment
                </label>
                <input
                  id="comment"
                  name="comment"
                  className="form-control"
                  defaultValue={this.props.request.comment}
                />
              </div>
            ) : null}
            <div className="form-group">
              <label htmlFor="approver" className=" text-color-accent">
                {this.props.request.status === 1
                  ? " Waiting for admin " +
                    this.props.request.nameOfAdmin +
                    " to authorize resources"
                  : this.props.request.status === 0
                  ? this.props.request.statusText +
                    " approval by " +
                    this.props.request.nameOfDefaultApprover
                  : this.props.request.status === 2
                  ? this.props.request.statusText +
                    " by " +
                    this.props.request.nameOfAttender
                  : this.props.request.status === 3
                  ? this.props.request.statusText +
                    " by " +
                    this.props.request.nameOfCloser
                  : this.props.request.statusText +
                    " by " +
                    this.props.request.nameOfAttender}
              </label>
            </div>
            <div className="form-group">
              <span className="flex-row flex-space">
                {this.state.can_approve ? (
                  <>
                    <input
                      className="btn form-control btn-success"
                      type="button"
                      id="approve"
                      value="Approve"
                      onClick={this.handleApprove}
                    />

                    <input
                      className="btn form-control btn-reject"
                      type="button"
                      id="reject"
                      value="Reject"
                      onClick={() => {
                        this.requestConfirmation("reject");
                      }}
                    />
                  </>
                ) : null}
                {this.props.user.id == this.props.request.requestor &&
                this.props.request.status < 3 ? (
                  <input
                    type="button"
                    value="DISCARD"
                    id="cancel-request"
                    className="btn-reject btn form-control"
                    onClick={() => {
                      this.requestConfirmation("discard");
                    }}
                  />
                ) : null}
                {this.state.can_assign ? (
                  <>
                    {this.state.vehicleSet && this.state.driverSet ? (
                      <input
                        className="btn form-control btn-success"
                        type="button"
                        id="authorize"
                        value="Authorize"
                        onClick={this.handleAssign}
                      />
                    ) : null}
                    <input
                      className="btn form-control btn-reject"
                      type="button"
                      id="reject"
                      value="Reject"
                      onClick={() => {
                        this.requestConfirmation("reject");
                      }}
                    />
                  </>
                ) : null}
                <span
                  id="cancel-user"
                  className="btn text-color-accent"
                  onClick={this.handleCancel}
                >
                  CANCEL
                </span>
              </span>
            </div>
          </form>
        </div>
        {this.state.needsConfirmation ? (
          <CustomDialog
            title={"Confirm Request Discard"}
            onCancel={this.handleCancel}
            msg={"Are you sure you want to discard this request?"}
            onAction={this.state.action}
            buttonText={"DISCARD"}
          />
        ) : null}
      </>
    );
  }
}
export default VehicleRequestApproval;
