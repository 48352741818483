import React from "react";
import CustomDialog from "./custom_dialog";
import Fetcher from "../utils/fetcher";
import commons from "../utils/commons";

const config = require("../utils/config.json");
class ProjectEditForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      project: null,
      oldProject: null,
      projectChanged: false,
      complete: false,
      response: "",
      coordinators: props.coordinators,
    };

    this.handleCancel = this.handleCancel.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleProjectChange = this.handleProjectChange.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleDateFocus = this.handleDateFocus.bind(this);
  }
  handleDateFocus = (e) => {
    e.target.type = "date";
  };
  componentDidMount() {
    this.setState({
      project: this.props.projects[0],
      oldProject: this.props.projects[0].code,
    });
  }

  handleChange = (event) => {
    event.preventDefault();
    let project = this.state.project;
    let value = event.target.value;

    switch (event.target.id) {
      case "code":
        project.code = value;
        // this.setState({ oldProject: value });
        break;
      case "name":
        project.name = value;
        break;
      case "coordinator":
        project.coordinator = value;
        break;
      case "donor":
        project.donor = value;
        break;
      case "start_date":
        let sdate = new Date(value);
        project.start_date = sdate.getTime() / 1000;
        event.target.type = "text";
        break;
      case "end_date":
        let edate = new Date(value);
        project.end_date = edate.getTime() / 1000;
        event.target.type = "text";
        break;
      case "department":
        let tg = event.target;
        let text = tg.options[tg.selectedIndex].text;
        value = tg.options[tg.selectedIndex].value;
        // fundcode.department_detail.code = text;
        project.department = value;
        let coords = this.props.coordinators.filter((c) => {
          return c.department == value;
        });
        this.setState({ coordinators: coords });
        break;
    }

    this.setState({ project: project, projectChanged: true });
  };
  handleCancel = (e) => {
    // e.preventDefault();
    this.props.onCancel();
  };
  handleProjectChange = (e) => {
    let id = parseInt(e.target.options[e.target.options.selectedIndex].value);
    let project = this.props.projects.filter((p) => {
      return p.id === id;
    });
    if (project.length > 0)
      this.setState(
        { project: project[0], oldProject: project[0].code },
        () => {
          console.log("project: ", id, this.state.project);
        }
      );
  };
  handleSubmit = (e) => {
    e.preventDefault();
    let body = this.state.project;
    body.prevCode = this.state.oldProject;
    delete body.department_detail;
    fetch(config.base_url + "/project/" + this.state.project.id, {
      method: "put",
      headers: { "Content-type": "application/json" },
      body: JSON.stringify(body),
    })
      .then((res) => res.json())
      .then((result) => {
        let error = result.error;
        if (error) this.setState({ complete: true, response: error });
        else
          this.setState({ complete: true, response: result.response }, () => {
            Fetcher.fetchProjects()
              .then((projects) => {
                this.props.onRefresh(projects);
              })
              .catch((err) => {})
              .finally(() => {
                this.props.onFeedback(true, result.response);
              });
          });
      })
      .catch((err) => {
        this.setState({ complete: true, response: err });
      });
  };

  render() {
    return (
      <>
        <div className="overlay w-100 flex-row flex-center flex-middle">
          <form
            className="bg-color-white-dark w-50"
            onSubmit={this.handleSubmit}
          >
            <h2>Edit Project Details</h2>
            <div className="form-group">
              <label htmlFor="project_id">Select Project Code</label>

              <select
                onChange={this.handleProjectChange}
                className="form-control"
                id="project_id"
                name="project_id"
              >
                {this.props.projects.map((p) => {
                  return (
                    <option key={p.id} value={p.id}>
                      {p.code}
                    </option>
                  );
                })}
              </select>
            </div>
            {this.state.project !== null ? (
              <>
                <div className="w-100 flex-row flex-start">
                  <div className="form-group">
                    <label htmlFor="code">Project Code</label>

                    <input
                      onChange={this.handleChange}
                      className="form-control"
                      type="text"
                      id="code"
                      name="code"
                      value={this.state.project.code}
                      placeholder="Enter project code"
                    />
                  </div>

                  <div className="form-group">
                    <label htmlFor="name">Project Name</label>

                    <input
                      onChange={this.handleChange}
                      className="form-control"
                      type="text"
                      id="name"
                      name="name"
                      value={this.state.project.name}
                      placeholder="Enter a name"
                    />
                  </div>
                </div>
                <div className="flex-row flex-start flex-middle w-100">
                  <div className="form-group">
                    <label htmlFor="start_date">Project Start Date</label>

                    <input
                      onChange={this.handleChange}
                      onFocus={this.handleDateFocus}
                      className="form-control"
                      type="text"
                      id="start_date"
                      name="start_date"
                      value={commons.dateFormat(this.state.project.start_date)}
                      placeholder="Enter a date"
                    />
                  </div>

                  <div className="form-group">
                    <label htmlFor="end_date">Project End Date</label>

                    <input
                      onChange={this.handleChange}
                      onFocus={this.handleDateFocus}
                      className="form-control"
                      type="text"
                      id="end_date"
                      name="end_date"
                      value={commons.dateFormat(this.state.project.end_date)}
                      placeholder="Enter an date"
                    />
                  </div>
                </div>
                <div className="form-group">
                  <label htmlFor="donor">Donor</label>

                  <input
                    type="text"
                    onChange={this.handleChange}
                    className="form-control"
                    id="donor"
                    name="donor"
                    value={this.state.project.donor}
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="department">Department</label>

                  <select
                    onChange={this.handleChange}
                    className="form-control"
                    id="department"
                    name="department"
                    value={
                      this.state.project.department == null
                        ? -1
                        : this.state.project.department
                    }
                  >
                    <option value="-1">--select department--</option>
                    {this.props.departments.map((d) => {
                      return (
                        <option key={d.id} value={d.id}>
                          {d.code}
                        </option>
                      );
                    })}
                  </select>
                </div>

                <div className="form-group">
                  <label htmlFor="coordinator">Choose a Coordinator</label>

                  <select
                    onChange={this.handleChange}
                    className="form-control"
                    id="coordinator"
                    name="coordinator"
                    value={this.state.project.coordinator}
                  >
                    {this.state.coordinators.length > 0 ? (
                      this.state.coordinators.map((coordinator) => {
                        return (
                          <option key={coordinator.id} value={coordinator.id}>
                            {coordinator.fname + " " + coordinator.lname}
                          </option>
                        );
                      })
                    ) : (
                      <option>No coordinators</option>
                    )}
                  </select>
                </div>
                <div className="form-group">
                  <span className="flex-row flex-space">
                    {this.state.projectChanged ? (
                      <input
                        className="btn form-control bg-color-accent"
                        type="submit"
                        id="btnUpdate"
                        value="Update"
                      />
                    ) : null}
                    <span
                      id="cancel-user"
                      className="btn text-color-accent"
                      onClick={this.handleCancel}
                    >
                      CANCEL
                    </span>
                  </span>
                </div>
              </>
            ) : null}
          </form>
        </div>{" "}
        {this.state.complete ? (
          <CustomDialog
            title={"Update Project Details"}
            onCancel={this.handleCancel}
            msg={this.state.response}
          />
        ) : null}
      </>
    );
  }
}
export default ProjectEditForm;
