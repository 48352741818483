import React from "react";
import Fetcher from "../utils/fetcher";
import Commons from "../utils/commons";
import MaintenanceRequestClosing from "./maintenance_request_closing";
import CustomDialog from "./custom_dialog";
const config = require("../utils/config.json");

class MaintenanceRequestApproval extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isVisible: true,
      projects: props.projects,
      positions: props.positions,
      users: [],
      drivers: props.drivers,
      fcodes: props.fundcodes,
      filteredCodes: [],
      filteredUsers: [],
      approvable: undefined,
      processed: undefined,
      isEditable: undefined,
      request: this.props.request,
      lastRequest: null,
      history: [],
      total: 0,
      needsConfirmation: false,
      action: "",
      can_approve:
        props.request.status === 0 &&
        (props.user.id === props.request.approver ||
          props.user.position === 6 ||
          props.user.position === 3),
      can_authorize:
        props.request.status === 1 &&
        (props.user.id === props.request.admin ||
          props.user.position === 6 ||
          props.user.position === 3),
    };
    this.user = props.user;
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
    this.getUsers = this.getUsers.bind(this);
    this.handleApprove = this.handleApprove.bind(this);
    this.handleReject = this.handleReject.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleDiscard = this.handleDiscard.bind(this);
    this.requestConfirmation = this.requestConfirmation.bind(this);
    this.handleAuthorize = this.handleAuthorize.bind(this);
  }
  requestConfirmation = (action) => {
    var my_action;
    if (action === "reject") my_action = this.handleReject;
    else my_action = this.handleDiscard;
    this.setState({ needsConfirmation: true, action: my_action });
  };
  handleDiscard = () => {
    let remarks = "Discarded requests";
    let req = this.props.request;
    let user = this.props.user;
    let data = {
      requestId: req.id,
      requestType: req.type,
      remark: remarks,
      closer: user.id,
      status: 5,
      requestor: req.requestor,
      approver: req.approver,
      vehicle: req.vehicle,
    };
    // console.log("Test: ", data);
    this.updateRequest(data);
  };
  handleChange = (e) => {
    e.preventDefault();
    let r = this.state.request;
    r.cost = parseInt(e.target.value);
    this.setState({ request: r });
  };
  handleApprove = () => {
    let remarks = document.getElementById("remark").value;
    // let vehicle = document.getElementById("vehicle").value;
    // let driver = document.getElementById("driver").value;
    let req = this.props.request;

    let data = {
      admin: req.admin,
      adminName: req.nameOfAdmin,
      requestId: req.id,
      requestType: req.type,
      remark: remarks,
      attender: this.props.user.id,
      status: 1,
      requestor: req.requestor,
      approver: req.approver,
      vehicle: req.vehicle,
    };
    this.updateRequest(data);
  };
  handleAuthorize = () => {
    let remarks = document.getElementById("remark1").value;
    // let vehicle = document.getElementById("vehicle").value;
    // let driver = document.getElementById("driver").value;
    let req = this.props.request;

    let data = {
      admin: req.admin,
      requestId: req.id,
      requestType: req.type,
      remark: remarks,
      attender: this.props.user.id,
      status: 2,
      adminName: req.nameOfAttender,
      currentStatus: req.status,
      requestor: req.requestor,
      approver: req.approver,
      vehicle: req.vehicle,
    };
    this.updateRequest(data);
  };
  handleReject = () => {
    let req = this.props.request;
    var remarks;
    if (req.status === 0) {
      if (this.props.user.id === req.requestor)
        remarks = document.getElementById("comment").value;
      else remarks = document.getElementById("remark").value;
    } else remarks = document.getElementById("remark1").value;

    let data = {
      requestId: req.id,
      requestType: req.type,
      attender: this.props.user.id,
      status: 4,
      currentStatus: req.status,
      requestor: req.requestor,
      approver: req.approver,
      vehicle: req.vehicle,
    };
    if (this.props.user.id === req.requestor) data.comment = remarks;
    else data.remark = remarks;
    this.updateRequest(data);
  };
  updateRequest = (data) => {
    fetch(config.base_url + "/request/" + data.requestId, {
      method: "post",
      headers: { "Content-type": "application/json" },
      body: JSON.stringify(data),
    })
      .then((res) => res.json())
      .then((result) => {
        Fetcher.fetchRequests(this.props.user)
          .then((requests) => {
            this.props.onRefresh(requests);
            this.props.onFeedback(true, result.response);
            this.handleCancel();
          })
          .catch((err) => {
            this.props.onFeedback(true, result.response);
            this.handleCancel();
          });
      })
      .catch((error) => {
        this.props.onFeedback(false, error.error);
        this.handleCancel();
      });
  };

  getUsers() {
    let myUsers = this.props.users;
    this.setState({ users: myUsers, filteredUsers: myUsers }, () => {
      let drivers = this.state.drivers.filter((u) => {
        return u.busy === 0;
      });
      this.setState({ drivers: drivers });
    });
  }

  componentDidMount() {
    this.getUsers();
    let reqType = this.props.request.type;
    let req = this.props.request;
    //get last approved request
    Fetcher.fetchLastRequest(req.vehicle, reqType)
      .then((result) => {
        this.setState({ lastRequest: result[0] }, () => {
          if (req.type == 2) {
            Fetcher.fetchVehicleHistory(
              req.vehicle,
              this.state.lastRequest !== undefined
                ? this.state.lastRequest.last_updated
                : false
            )
              .then((history) => {
                if (history.length > 0) {
                  let newHistory = Commons.groupByArray(history, "fundcode");
                  let mappedHistory = newHistory.map((h) => {
                    let hist = h;
                    hist.distance = h.values.reduce(
                      (a, b) => a + b.distance,
                      0
                    );
                    hist.project = h.values[0].project;

                    return hist;
                  });
                  let total = mappedHistory.reduce((a, b) => a + b.distance, 0);
                  console.log("total: ", total);
                  this.setState({ history: mappedHistory, total: total });
                }
              })
              .catch((err) => {
                console.error("error history: ", err);
              });
          }
        });
      })
      .catch((err) => {
        console.error(err.error);
      });
    console.log("suppliers: ", this.props.suppliers);
    let supplier = this.props.suppliers.filter((s) => {
      return s.id === req.supplier;
    });

    if (req !== undefined) {
      req.supplier_name = supplier[0].name;
      if (
        (req.approver === this.user.id && this.user.id !== req.requestor) ||
        this.user.username === "admin" ||
        (this.user.position == 3 && this.user.id !== req.approver)
      ) {
        this.setState(
          {
            request: req,
            approvable: true,
            processed: req.status !== 1 ? true : false,
          },
          () => {
            this.setState({
              isEditable: this.state.approvable && !this.state.processed,
            });
          }
        );
      } else {
        this.setState(
          {
            approvable: false,
            processed: req.status !== 1 ? true : false,
          },
          () => {
            this.setState({
              isEditable: false,
            });
          }
        );
      }
    }
  }

  handleCancel() {
    console.log("cancelling user form");
    this.props.cancelButtonClick();
  }

  handleSubmit(e) {
    e.preventDefault();

    // this.props.onFeedback(true, "testing...");
  }

  render() {
    return this.props.request.status >= 2 ? (
      <MaintenanceRequestClosing
        suppliers={this.props.suppliers}
        user={this.props.user}
        drivers = {this.state.drivers}
        request={this.props.request}
        onFeedback={(status, msg) => this.props.onFeedback(status, msg)}
        onRefresh={(reqs) => this.props.onRefresh(reqs)}
        cancelButtonClick={this.handleCancel}
        users={this.props.users}
        lastRequest={this.state.lastRequest}
      />
    ) : (
      <>
        <div className="flex-row flex-center center-self">
          <form
            name="vehicle-request-form"
            id="vehicle-request-form"
            className="center-self border-all-main"
            onSubmit={this.handleSubmit}
          >
            <h2 className="w-100 bg-color-main">
              Maintenance Request Approval
            </h2>
            <div className="w-100 flex-row flex-start">
              <div className="form-group">
                <label className="text-color-accent" htmlFor="date_requested">
                  Date Requested
                </label>
                <input
                  readOnly={this.state.processed}
                  className="form-control "
                  type="text"
                  name="date_requested"
                  id="date_requested"
                  defaultValue={Commons.dateFormat(
                    this.props.request.date_created
                  )}
                />
              </div>
              <div className="form-group">
                <label
                  htmlFor="requestor"
                  className="form-label text-color-accent"
                >
                  Requestor's Name
                </label>

                <input
                  readOnly={this.state.processed}
                  type="text"
                  className="form-control "
                  name="requestor"
                  id="requestor"
                  defaultValue={this.state.request.nameOfRequestor}
                />
              </div>
              <div className="form-group">
                <label
                  htmlFor="vehicle"
                  className="form-label text-color-accent"
                >
                  Vehicle
                </label>
                <input
                  readOnly={this.state.processed}
                  className="form-control "
                  type="text"
                  name="vehicle"
                  id="vehicle"
                  placeholder="No vehicle info"
                  defaultValue={this.props.request.vehicle}
                />
              </div>
            </div>
            <div className="w-100 flex-row flex-start">
              <div className="form-group">
                <label
                  htmlFor="mileage"
                  className="form-label text-color-accent"
                >
                  Mileage
                </label>
                <input
                  readOnly={this.state.processed}
                  className="form-control "
                  type="text"
                  name="mileage"
                  id="mileage"
                  placeholder="No mileage info"
                  defaultValue={this.props.request.mileage}
                />
              </div>
              <div className="form-group">
                <label
                  htmlFor="service"
                  className="form-label text-color-accent"
                >
                  Service Type
                </label>
                <input
                  readOnly={this.state.processed}
                  className="form-control "
                  type="text"
                  name="service"
                  id="service"
                  placeholder="Type of service"
                  defaultValue={Commons.getRequestTypeText(
                    this.props.request.type
                  )}
                />
              </div>
            </div>
            {this.state.processed || this.state.isEditable ? (
              <div className="w-100 flex-row flex-start">
                <div className="form-group">
                  <label
                    htmlFor="lmileage"
                    className="form-label text-color-accent"
                  >
                    last Service Mileage
                  </label>
                  <input
                    readOnly={true}
                    className="form-control "
                    type="text"
                    name="lmileage"
                    id="lmileage"
                    placeholder="No mileage info"
                    defaultValue={
                      this.state.lastRequest != null
                        ? this.state.lastRequest.mileage
                        : ""
                    }
                  />
                </div>
                <div className="form-group">
                  <label
                    htmlFor="distance"
                    className="form-label text-color-accent"
                  >
                    Distance Covered
                  </label>
                  <input
                    readOnly={true}
                    className="form-control "
                    type="text"
                    name="distance"
                    id="distance"
                    placeholder="No mileage info"
                    defaultValue={
                      this.state.lastRequest != null
                        ? this.props.request.mileage -
                          this.state.lastRequest.mileage
                        : ""
                    }
                  />
                </div>
              </div>
            ) : null}

            <div className="flex-row flex-center w-100">
              <div className="form-group">
                <label
                  htmlFor="amount"
                  className="form-label text-color-accent"
                >
                  Estimated Cost (Tsh)
                </label>

                <input
                  onChange={this.handleChange}
                  readOnly={this.state.processed}
                  className="form-control "
                  type="text"
                  name="amount"
                  id="amount"
                  defaultValue={Commons.formatNumber(this.props.request.cost)}
                />
              </div>{" "}
              <div className="form-group">
                <label
                  htmlFor="supplier"
                  className="form-label text-color-accent"
                >
                  Supplier
                </label>
                <input
                  readOnly={this.state.processed}
                  className="form-control "
                  type="text"
                  name="supplier"
                  id="supplier"
                  defaultValue={this.state.request.supplier_name}
                />
              </div>
              <div className="form-group">
                <label htmlFor="from" className="form-label text-color-accent">
                  Date Required
                </label>

                <input
                  readOnly={this.state.processed}
                  className="form-control "
                  type="text"
                  name="date_required"
                  id="date_required"
                  defaultValue={Commons.dateFormat(
                    this.props.request.start_time
                  )}
                />
              </div>
            </div>
            <div className="flex-row flex-start w-100">
              <div className="form-group">
                <label htmlFor="parts" className="form-label text-color-accent">
                  Parts
                </label>
                <input
                  readOnly={this.state.processed}
                  className="form-control "
                  type="text"
                  name="parts"
                  id="parts"
                  defaultValue={this.state.request.parts}
                />
              </div>
            </div>
            <div className="flex-row flex-start w-100">
              <div className="form-group">
                <label
                  htmlFor="description"
                  className="form-label text-color-accent"
                >
                  Description
                </label>
                <input
                  readOnly={this.state.processed}
                  className="form-control "
                  type="text"
                  name="description"
                  id="description"
                  defaultValue={this.state.request.description}
                />
              </div>
            </div>

            {this.props.request.type == 2 ? (
              <div className="form-group">
                <label htmlFor="" className="form-label">
                  Charging Instrutions
                </label>
                {this.state.history.length > 0 ? (
                  <div className="form-group">
                    {this.state.history.map((h) => {
                      return (
                        <div className="flex-row flex-start" key={h.key}>
                          <input
                            type="text"
                            className="form-control"
                            readOnly={true}
                            defaultValue={h.key + " - " + h.project}
                          />
                          <span className="form-control text-color-accent">
                            {h.distance +
                              " Km (" +
                              ((100 * h.distance) / this.state.total).toFixed(
                                1
                              ) +
                              "%) - Cost: Tsh. " +
                              Commons.formatNumber(
                                (
                                  (h.distance / this.state.total) *
                                  this.state.request.cost
                                ).toFixed(1)
                              )}
                          </span>
                        </div>
                      );
                    })}
                  </div>
                ) : (
                  <div className="flex-row flex-start">
                    <div className="form-group">
                      <label
                        htmlFor="project"
                        className="form-label text-color-accent"
                      >
                        Project
                      </label>
                      <input
                        readOnly={this.state.processed}
                        type="text"
                        className="form-control"
                        name="project"
                        id="project"
                        defaultValue={this.props.request.project}
                      />
                    </div>
                    <div className="form-group">
                      <label
                        htmlFor="fund_code"
                        className="form-label text-color-accent"
                      >
                        Fund Code
                      </label>
                      <input
                        readOnly={this.state.processed}
                        type="text"
                        id="fund_code"
                        className="form-control"
                        defaultValue={this.props.request.fundcode}
                      />
                    </div>
                  </div>
                )}
              </div>
            ) : (
              <div className="flex-row flex-start">
                <div className="form-group">
                  <label
                    htmlFor="project"
                    className="form-label text-color-accent"
                  >
                    Project
                  </label>
                  <input
                    readOnly={this.state.processed}
                    type="text"
                    className="form-control"
                    name="project"
                    id="project"
                    defaultValue={this.props.request.project}
                  />
                </div>
                <div className="form-group">
                  <label
                    htmlFor="fund_code"
                    className="form-label text-color-accent"
                  >
                    Fund Code
                  </label>
                  <input
                    readOnly={this.state.processed}
                    type="text"
                    id="fund_code"
                    className="form-control"
                    defaultValue={this.props.request.fundcode}
                  />
                </div>
              </div>
            )}

            <div className="flex-row flex-start w-100">
              <div className="form-group">
                <label
                  htmlFor="account"
                  className="form-label text-color-accent"
                >
                  Account
                </label>
                <input
                  readOnly={this.state.processed}
                  className="form-control "
                  type="text"
                  name="account"
                  id="account"
                  defaultValue={this.props.request.account}
                />
              </div>
              <div className="form-group">
                <label
                  htmlFor="source"
                  className="form-label text-color-accent"
                >
                  Source
                </label>
                <input
                  readOnly={this.state.processed}
                  id="source"
                  name="source"
                  className="form-control"
                  defaultValue={this.props.request.source}
                />
              </div>
              <div className="form-group">
                <label
                  htmlFor="category"
                  className="form-label text-color-accent"
                >
                  Category
                </label>
                <input
                  readOnly={this.state.processed}
                  id="category"
                  className="form-control"
                  name="category"
                  defaultValue={this.props.request.category}
                />
              </div>
              <div className="form-group">
                <label
                  htmlFor="activity"
                  className="form-label text-color-accent"
                >
                  Activity
                </label>
                <input
                  readOnly={this.state.processed}
                  id="activity"
                  name="activity"
                  className="form-control"
                  defaultValue={this.props.request.activity}
                />
              </div>
            </div>
            <div className="form-group">
              <label htmlFor="comment" className="form-label text-color-accent">
                Requestor's Comment
              </label>
              <input
                readOnly={
                  !(
                    this.props.user.id === this.props.request.requestor &&
                    this.props.request.status < 3
                  )
                }
                id="comment"
                name="comment"
                className="form-control"
                defaultValue={this.props.request.comment}
              />
            </div>
            {this.state.can_approve || this.state.can_authorize ? (
              <>
                <div className="form-group">
                  <label
                    htmlFor="remark"
                    className="form-label text-color-accent"
                  >
                    Approver's Remarks
                  </label>
                  <input
                    readOnly={!this.state.can_approve}
                    id="remark"
                    name="remark"
                    className="form-control"
                    defaultValue={this.props.request.remark}
                  />
                </div>
                {this.state.can_authorize ? (
                  <div className="form-group">
                    <label
                      htmlFor="remark"
                      className="form-label text-color-accent"
                    >
                      Authorizer's Remarks
                    </label>
                    <input
                      readOnly={!this.state.can_authorize}
                      id="remark1"
                      name="remark1"
                      className="form-control"
                      defaultValue={this.props.request.remark1}
                    />
                  </div>
                ) : null}
              </>
            ) : null}
            <div className="form-group">
              <label
                htmlFor="approver"
                className="form-label text-color-accent"
              >
                <div className="form-group">
                  <label htmlFor="approver" className=" text-color-accent">
                    {this.props.request.status === 1
                      ? " Waiting for admin " +
                        this.props.request.nameOfAdmin +
                        " to authorize resources"
                      : this.props.request.status === 0
                      ? this.props.request.statusText +
                        " approval by " +
                        this.props.request.nameOfDefaultApprover
                      : this.props.request.status === 2
                      ? this.props.request.statusText +
                        " by " +
                        this.props.request.nameOfAttender
                      : this.props.request.status === 3
                      ? this.props.request.statusText +
                        " by " +
                        this.props.request.nameOfCloser
                      : this.props.request.statusText +
                        " by " +
                        this.props.request.nameOfAttender}
                  </label>
                </div>
              </label>
            </div>
            <div className="form-group">
              <span className="flex-row flex-space">
                {this.state.can_approve || this.state.can_authorize ? (
                  <>
                    {this.state.can_approve ? (
                      <input
                        className="btn form-control btn-success"
                        type="button"
                        id="approve"
                        value="Approve"
                        onClick={this.handleApprove}
                      />
                    ) : (
                      <input
                        className="btn form-control btn-success"
                        type="button"
                        id="authorize"
                        value="Authorize"
                        onClick={this.handleAuthorize}
                      />
                    )}
                    <input
                      className="btn form-control btn-reject"
                      type="button"
                      id="reject"
                      value="Reject"
                      onClick={this.handleReject}
                    />
                  </>
                ) : null}
                {this.props.user.id == this.props.request.requestor &&
                this.props.request.status != 3 ? (
                  <span
                    id="cancel-request"
                    className="btn-reject btn "
                    onClick={() => {
                      this.requestConfirmation("reject");
                    }}
                  >
                    DISCARD
                  </span>
                ) : null}
                <span
                  id="cancel-user"
                  className="btn text-color-accent"
                  onClick={this.handleCancel}
                >
                  CLOSE
                </span>
              </span>
            </div>
          </form>
        </div>
        {this.state.needsConfirmation ? (
          <CustomDialog
            title={"Confirm Request Discard"}
            onCancel={this.handleCancel}
            msg={"Are you sure you want to discard this request?"}
            onAction={this.state.action}
            buttonText={"DISCARD"}
          />
        ) : null}
      </>
    );
  }
}
export default MaintenanceRequestApproval;
